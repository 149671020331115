import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from "react";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import EmailListBox from "../../../../components/Emailbox/EmailListBox";
import Nav from "../../../../components/nav/Nav";
import moment from "moment";
import SearchWorkItemDetails from "../../pages/searchworkitem/SearchWorkItemDetails";
import pdfViewer from "../../../../assets/images/placeholder/pdf_viewer.jpg";
import ViewImageIcon from "../../../../assets/images/icons/view-file-borderless.png";
import ViewEmailIcon from "../../../../assets/images/icons/view-email.png";
import WarningIcon from "../../../../assets/images/icons/warning.png";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  DownloadExcel,
  ArrayWithoutExtraColumn
} from "../../Comman/ExcelFileDownload";
import DashboardContext from "../../../../contexts/DashboardContext";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap-daterangepicker/daterangepicker.css";
import WorkActivityScreenDetailsPage from "../WorkActivityScreen/WorkActivityScreenDetailsPage";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import DeleteIcon from "@mui/icons-material/Delete";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Iframe from "react-iframe";
import Tooltip from "react-bootstrap/Tooltip";
import LoadingSpinner from "../../../../LoadingSpinner";
import "react-table-filter/lib/styles.css";
// With material-table, customSort was getting reset if state is updated so installed updated version of material-table which has been renamed to @material-table/core
// Use MaterialTableCore as a alias. Both @material-table/core and material-table are in use. Later, all tables can be changed to @material-table/core
import MaterialTableCore, {
  MTableToolbar as MTableToolbarCore,
} from "@material-table/core";
import MaterialTable, { MTableToolbar } from "material-table";
import { FcImageFile } from "react-icons/fc";
import tableIcons from "../../../../MaterialTableIcons";
import { unstable_composeClasses } from "@mui/material";
import { Rowing } from "@mui/icons-material";
import FormContext from "../../../../contexts/FormContext";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { Link, Navigate } from "react-router-dom";
import { Toast } from "bootstrap";
import { TbListDetails } from "react-icons/tb";
import ReactTooltip from "react-tooltip";
import xtype from "xtypejs";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { DisplayTimeZone, UploaddateLocalTime } from "../../Comman/Timezone";
import Draggable, { DraggableCore } from 'react-draggable';
import { Resizable, ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtack, faTimes, faCloudDownload } from '@fortawesome/free-solid-svg-icons';
import ReactDOM from 'react-dom';
import { FcSearch } from "react-icons/fc";

//import { v4 as uuidv4 } from "uuid";
import ViewDocumentIcon from "src/assets/images/icons/ViewDocument.png";
// import Tooltip from "@material-ui/core/Tooltip";
import { width } from "@material-ui/system"; 
import EmailCompose from "src/components/Emailbox/EmailCompose";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField
} from "@material-ui/core";
import { EmailListPlaceholder } from "src/bootsrap-placeholder";
import ActivityScreenTrackingDetails from "./ActivityScreenTrackingDetails";

const Process = () => {
  const { addToast } = useToasts();
  const location = useLocation();
  let navigate = useNavigate();
  let tableRef = React.useRef();
  const { REACT_APP_EDIT_WORKITEMDETAILS } = process.env;
  const { REACT_APP_GET_WORKITEMDETAILS } = process.env;
  const { REACT_APP_GET_EMPLOYEESFORSUBPROCESSACTIVITY } = process.env;
  const { REACT_APP_GET_GETSTATUSREASONSBYSUBPROCESSID } = process.env;
  const { REACT_APP_SUBMITANDCONTINUEWORKITEM } = process.env;
  const { REACT_APP_SUBMITANDADDNEWBLANKWORKITEM } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_Image_URL } = process.env;
  const { REACT_APP_DELETEWORKITEMATTACHMENT } = process.env;
  const { REACT_APP_UPLOADPATHNAME } = process.env;
  const {
    REACT_APP_GET_WORKITEMEMAILDETAILS,
    REACT_APP_GET_EmailDetailsByMessageID,
    React_APP_ReplyEmailConversation,
  } = process.env;
  //Added by Pradip Rai
  const { REACT_APP_Image_URL_HUBBELL } = process.env;
  const { REACT_APP_HUBBELL_CLIENTID } = process.env;
  const { REACT_APP_C520_PROGRESS_CLIENTID } = process.env;
  const { REACT_APP_Image_URL_GEC } = process.env;
  const { REACT_APP_GEC_CLIENTID } = process.env;
  //Ended by Pradip Rai
  const { REACT_APP_NAME } = process.env;
  const { REACT_APP_HOST } = process.env;
  const { REACT_APP_SUBMITWORKITEM } = process.env;
  const { REACT_APP_SAVEWORKITEM } = process.env;
  const { REACT_APP_CREATEWORKITEMATTACHMENT } = process.env;
  const { REACT_APP_GETUPLOADFILEDETAILS } = process.env;
  const { REACT_APP_GETWORKITEMATTACHMENTS } = process.env;
  const { REACT_APP_DELETELOCKBYWORKITEMID } = process.env;
  const { REACT_APP_GETWORKITEMTYPES } = process.env;
  const { REACT_APP_ONBASEFILEUPLOAD } = process.env;
  const { REACT_APP_FETCHONBASEDOCUMENTTYPE } = process.env;
  const { REACT_APP_FETCHONBASEFILEUPLOAD } = process.env;
  const { REACT_APP_FETCHONBASEFILEAPPEND } = process.env;
  const { REACT_APP_FETCHBREAKTIMERONLOAD } = process.env;
  const { REACT_APP_SAVETIMEONTICK } = process.env;
  const { REACT_APP_DELETEBLANKWORKITEMBYWORKITEMID } = process.env;
  const { REACT_APP_DOWNLOADWORKITEMATTACHMENT } = process.env;
  const { REACT_APP_UILOGEXTENSION } = process.env;
  const { REACT_APP_GETNEXTITEMFORSKIP } = process.env;
  const { REACT_APP_GET_ACTIVITYSEARCHWORKITEMDETAILS } = process.env;
  const { REACT_APP_GET_SEARCHWORKITEMDETAILS } = process.env;
  const { REACT_APP_GETWORKFLOWITEMFIELDS } = process.env;
  const { REACT_APP_GETWORKFLOWSTATES } = process.env;
  const { REACT_APP_GETWORKFLOWSTATUS } = process.env;
  const { REACT_APP_HIGSubProcessId } = process.env;
  const theme = createTheme({
    palette: {
      primary: {
        main: "#008000"
      },
      secondary: {
        main: "#FB4E0B"
      }
    }
  });
  //Added by Pradip for business rule
  const { REACT_APP_GETWORKITEMFIELDSRULES } = process.env;
  const [actScreenBussinessRules, setactScreenBussinessRules] = useState([]);
  let newBussinessFieldValues = [];
  //const [businessFields, setBusinessFields] = useState([]);
  const [businessFieldValues, setBusinessFieldValues] = useState([{}]);
  const [oldElements, setoldElements] = useState([]);
  //Ended by Pradip for business rule
  const [isEmailIntegration, setisEmailIntegration] = useState(false);
  const [isEmailReplied, setisEmailReplied] = useState(false);
  const [openComposeEmail, setopenComposeEmail] = useState(false); 


  //added by sachin
  const [emailPreviewActive, setemailPreviewActive] = useState(false);

 //added by Sachin
 const [previewedEmail, setpreviewedEmail] = useState([]);
  const [EmailLoding, setEmailLoding] = useState(false);
  const [emailMessageMapField, setemailMessageMapField] = useState("");
  const [emailGroup, setemailGroup] = useState("");
  const [EmailGroupFolder1, setEmailGroupFolder1] = useState("");
  const [EmailGroupFolder2, setEmailGroupFolder2] = useState("");
  const [search, setsearch] = useState(false);
  const [isLoadingWorkitemdetails, setisLoadingWorkitemdetails] = useState(false);
  const [workItemMultivalueDetailModel, setworkItemMultivalueDetailModel] = useState([]);

  const [currentPaging, setcurrentPaging] = useState(1);
  const [rowsperPage, setrowsperPage] = useState(5);
  const [imageUrl, setimageUrl] = useState("");
  const imageUrlTemplateRef = useRef("");
  const currentPage = window.location.href;
  let interval = null;
  const asignmentdetailsColumns = [
    {
      title: "Description",
      field: "description"
    },
    { title: "Assigned By", field: "assignedBy" },
    {
      title: "Assignment Date",
      field: "assignmentDate",
      customSort: (a, b) =>
        Date.parse(a.assignmentDate) - Date.parse(b.assignmentDate),
    },
  ];
  const checklistdetailsColumns = [
    {
      title: "ChecklistDesc",
      field: "ChecklistDesc"
    },
    { title: "Comments", field: "Comments" },
    { title: "Name", field: "Name" },
    { title: "UpdatedOn", field: "UpdatedOn" },
    { title: "ChecklistStatus", field: "ChecklistStatus" },
    { title: "WorkflowStateName", field: "WorkflowStateName" }
  ];
  const emailmessagedata = {
    "@odata.context":
      "https://graph.microsoft.com/v1.0/$metadata#users('Aman.Sachdeva%40exlservice.com')/mailFolders('AAMkADdiYTk2ZDUyLTY1ZjAtNDMxZS1hYWI4LThiZGY5MjJkMGY2OAAuAAAAAABLIbOY8g_eT6XmOaDB19moAQBiBJn72wuRSJkN65fbH1VkAAAJBB_HAAA%3D')/messages(attachments())",
    value: [
      {
        "@odata.etag": 'W/"CQAAABYAAABiBJn72wuRSJkN65fbH1VkAAG+GolE"',
        id: "AAMkADdiYTk2ZDUyLTY1ZjAtNDMxZS1hYWI4LThiZGY5MjJkMGY2OABGAAAAAABLIbOY8g_eT6XmOaDB19moBwBiBJn72wuRSJkN65fbH1VkAAAJBB_HAABiBJn72wuRSJkN65fbH1VkAAAJBGW7AAA=",
        createdDateTime: "2023-11-17T14:31:04Z",
        lastModifiedDateTime: "2023-11-17T14:57:59Z",
        changeKey: "CQAAABYAAABiBJn72wuRSJkN65fbH1VkAAG+GolE",
        categories: [],
        receivedDateTime: "2023-11-17T14:31:04Z",
        sentDateTime: "2023-11-17T14:31:00Z",
        hasAttachments: false,
        internetMessageId:
          "<SJ2PR08MB8382EB42FB2916A9F7A5FA1FBAB7A@SJ2PR08MB8382.namprd08.prod.outlook.com>",
        subject: "testing-0365 email",
        bodyPreview: "Hi\r\n\r\nTesting email\u2026\r\n\r\nRegards,\r\nSantosh",
        importance: "normal",
        parentFolderId:
          "AAMkADdiYTk2ZDUyLTY1ZjAtNDMxZS1hYWI4LThiZGY5MjJkMGY2OAAuAAAAAABLIbOY8g_eT6XmOaDB19moAQBiBJn72wuRSJkN65fbH1VkAAAJBB_HAAA=",
        conversationId:
          "AAQkADdiYTk2ZDUyLTY1ZjAtNDMxZS1hYWI4LThiZGY5MjJkMGY2OAAQAMXmkMUQ_UT_jLjHVNbLFew=",
        conversationIndex: "AdoZYqYYxeaQxRD5RP6MuMdU1ssV7A==",
        isDeliveryReceiptRequested: false,
        isReadReceiptRequested: false,
        isRead: true,
        isDraft: false,
        webLink:
          "https://outlook.office365.com/owa/?ItemID=AAMkADdiYTk2ZDUyLTY1ZjAtNDMxZS1hYWI4LThiZGY5MjJkMGY2OABGAAAAAABLIbOY8g%2BeT6XmOaDB19moBwBiBJn72wuRSJkN65fbH1VkAAAJBB%2BHAABiBJn72wuRSJkN65fbH1VkAAAJBGW7AAA%3D&exvsurl=1&viewmodel=ReadMessageItem",
        inferenceClassification: "focused",
        body: {
          contentType: "html",
          content:
            '<html><head>\r\n<meta http-equiv="Content-Type" content="text/html; charset=utf-8"><meta name="Generator" content="Microsoft Word 15 (filtered medium)"><style>\r\n<!--\r\n@font-face\r\n\t{font-family:"Cambria Math"}\r\n@font-face\r\n\t{font-family:Calibri}\r\np.MsoNormal, li.MsoNormal, div.MsoNormal\r\n\t{margin:0in;\r\n\tfont-size:11.0pt;\r\n\tfont-family:"Calibri",sans-serif}\r\nspan.EmailStyle17\r\n\t{font-family:"Calibri",sans-serif;\r\n\tcolor:windowtext}\r\n.MsoChpDefault\r\n\t{font-family:"Calibri",sans-serif}\r\n@page WordSection1\r\n\t{margin:1.0in 1.0in 1.0in 1.0in}\r\ndiv.WordSection1\r\n\t{}\r\n-->\r\n</style></head><body lang="EN-US" link="#0563C1" vlink="#954F72" style="word-wrap:break-word"><div class="WordSection1"><p class="MsoNormal">Hi</p><p class="MsoNormal">&nbsp;</p><p class="MsoNormal">Testing email\u2026</p><p class="MsoNormal">&nbsp;</p><p class="MsoNormal">Regards,</p><p class="MsoNormal">Santosh</p></div></body></html>',
        },
        sender: {
          emailAddress: {
            name: "Santosh Kumar (LAM)",
            address: "Santosh.Kumar1@exlservice.com",
          },
        },
        from: {
          emailAddress: {
            name: "Santosh Kumar (LAM)",
            address: "Santosh.Kumar1@exlservice.com",
          },
        },
        toRecipients: [
          {
            emailAddress: {
              name: "Aman Sachdeva",
              address: "Aman.Sachdeva@exlservice.com",
            },
          },
        ],
        ccRecipients: [
          {
            emailAddress: {
              name: "Santosh Kumar (LAM)",
              address: "Santosh.Kumar1@exlservice.com",
            },
          },
        ],
        bccRecipients: [],
        replyTo: [],
        flag: { flagStatus: "notFlagged" },
        attachments: [],
      },
    ],
  };
  const [workitemHistory, setworkitemHistory] = useState([]);
  const [workItemFieldsDetails, setworkItemFieldsDetails] = useState([]);
  const [s3BktFolders, sets3BktFolders] = useState([]);
  const [isLoading, set_IsLoading] = useState(false);
  const [documentTypeValue, setdocumentTypeValue] = useState([]);
  const [isDivUploadVisible, setisDivUploadVisible] = useState(false);
  const [regGroup, setregGroup] = useState([]);
  const [typeConfigs, settypeConfigs] = useState([]);
  const [workItemStatus, setworkItemStatus] = useState([]);
  const [activityname, setactivityname] = useState("");
  const [selectedFile, setselectedFile] = useState();
  const [selectedFileForUpload, setselectedFileForUpload] = useState("");
  const [selectedFileNameForUpload, setselectedFileNameForUpload] = useState(
    ""
  );
  const [SubmitDisabled, setSubmitDisabled] = useState(false);
  const [SubmitAndContinueDisabled, setSubmitAndContinueDisabled] =
    useState(false);
  const [SubmitandhomeDisabled, setSubmitandhomeDisabled] = useState(false);
  const [SaveDisabled, setSaveDisabled] = useState(false);
  const [
    SubmitAndAddBlankItemDisabled,
    setSubmitAndAddBlankItemDisabled
  ] = useState(false);
  const [SkipDisabled, setSkipDisabled] = useState(false);
  const [divStatusVisible, setdivStatusVisible] = useState(false);
  const [CancelVisible, setCancelVisible] = useState(false);
  const [allowAdhocAddition, setallowAdhocAddition] = useState(false);
  const [btnBackToDashboardVisible, setbtnBackToDashboardVisible] = useState(
    false
  );
  const [btnSubmitVisible, setbtnSubmitVisible] = useState(false);
  const [btnSubmitAndHomeVisible, setbtnSubmitAndHomeVisible] = useState(false);
  const [btnCancelVisible, setbtnCancelVisible] = useState(false);
  const [
    btnSubmitAndAddBlankItemVisible,
    setbtnSubmitAndAddBlankItemVisible
  ] = useState(false);
  const [UploadFilesReadonly, setUploadFilesReadonly] = useState(false);

  const [Isresolve, setIsresolve] = useState(true);
  const [btnSkipVisible, setbtnSkipVisible] = useState(false);
  const [currentTime, setcurrentTime] = useState("");
  const [fieldClassName, setfieldClassName] = useState("col-4");
  //added by Santosh

  const [auditTrail, setbtnAuditTrailVisible] = useState(false);

  const [userNotes, setbtnUserNotesVisible] = useState(false);

  const [isChecked, setIsChecked] = useState(false);
  const [GridColLen, setGridColLen] = useState(0);
  const [emailcolumns, setemailcolumns] = useState([]);

  // ended by Santosh
  const [divClassName, setdivClassName] = useState("splitfull");
  const [iframeactive, setiframeactive] = useState(false);
  const [ImageToggleVisible, setImageToggleVisible] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [emailConversations, setEmailConversations] = useState([]);
  const [auditTrails, setAuditTrails] = useState([]);
  const [isAuditTrailAvailable, setIsAuditTrailAvailable] = useState(false);
  const [AuditTrailButtonEnable, setAuditTrailButtonEnable] = useState(false);
  const [buttonEndClass, setbuttonEndClass] = useState("col-2");
  const [staticfieldsWidth, setstaticfieldsWidth] = useState("width-full");
  const [staticfieldflex, setstaticfieldflex] = useState("");
  const [isstaticfieldsColumn, setisstaticfieldsColumn] = useState(true);
  const [checkComments, setcheckComments] = useState([]);
  const [JsonValues, setJsonValues] = useState([]);
  const [BRJsonValues, setBRJsonValues] = useState([]);
  const [CheckedValues, setCheckedValues] = useState([]);
  const [NeedRefresh, setNeedRefresh] = useState(false);
  const [uploadedFile, setuploadedFile] = useState("");
  const [statusChange, setstatusChange] = useState("");
  const [selectedActivityName, setselectedActivityName] = useState("");
  const [IsAssignedToRequired, setIsAssignedToRequired] = useState(false);
  const [workAllocationMode, setworkAllocationMode] = useState("false");
  const [isReasonshow, setisReasonshow] = useState(false);
  const [isDivWorkItemVisible, setisDivWorkItemVisible] = useState(false);
  const [SearchWorkitemColumns, setSearchWorkitemColumns] = useState([]);
  // const [screenFilterValue, setscreenFilterValue] = useState(false);
  const [
    employeesForSubprocessActivity,
    setemployeesForSubprocessActivity
  ] = useState([]);
  const [statusReasonForSubprocess, setstatusReasonForSubprocess] = useState(
    []
  );
  const [SelectedStatusName, setSelectedStatusName] = useState("");

  //const [lbOnBaseDocVisible,setlbOnBaseDocVisible]=useState(false);
  //const [lbOnBaseDocumentText,setlbOnBaseDocumentText]=useState("");
  //const [ibcopyonbaseVisible,setibcopyonbaseVisible]=useState(false);
  const [divOnbaseDocUploadVisible, setdivOnbaseDocUploadVisible] = useState(
    false
  );
  const [uconbaseDocIsUploadChecked, setuconbaseDocIsUploadChecked] = useState(
    false
  );
  const [divOnbaseDocAppendVisible, setdivOnbaseDocAppendVisible] = useState(
    false
  );
  const [ucOnbaseDocAppendDocID, setucOnbaseDocAppendDocID] = useState(0);
  const [OnbaseUploadDocURL, setOnbaseUploadDocURL] = useState("");
  //const [AllowAppendImage,setAllowAppendImage]=useState(false);
  const [OnBaseDocumentID, setOnBaseDocumentID] = useState(0);
  const [onBaseDocument, setonBaseDocument] = useState("");
  const [OnBaseUserID, setOnBaseUserID] = useState("");
  const [OnBasePassword, setOnBasePassword] = useState("");

  const [AttachmentDetails, setAttachmentDetails] = useState([]);
  const [isReason, setReason] = useState(false);
  const [isComment, setisComment] = useState(false);
  const [statusAssignedTo, setstatusAssignedTo] = useState("");
  const [statusReason, setstatusReason] = useState([]);
  const [statusComment, setstatusComment] = useState("");
  const [assignTo, setAssignTo] = useState(false);
  const [nextWorkItemId, setnextWorkItemId] = useState("");
  const [inputvaluesBody, setinputvaluesBody] = useState([]);
  const [workitem_Id, setworkitem_Id] = useState(0);
  const [hfActivityName, sethfActivityName] = useState("");
  const [ddlStatusId, setddlStatusId] = useState(0);
  const [hdnOverrideUniqueCheck, sethdnOverrideUniqueCheck] = useState("0");
  const [ddlWorkItemTypeValue, setddlWorkItemTypeValue] = useState("");
  const [uploadedFileName, setuploadedFileName] = useState("");
  const [UploadingFile, setUploadingFile] = useState("");
  const [IsUpload, setIsUpload] = useState(false);
  const [IsUploadOrAppend, setIsUploadOrAppend] = useState(false);
  const [IsUploading, setIsUploading] = useState(false);
  const [AttachmentUploading, setAttachmentUploading] = useState(false);
  const [IsAttachmentUpload, setIsAttachmentUpload] = useState(false);
  const [UploadDocumetId, setUploadDocumetId] = useState(0);
  const [timerStart, settimerStart] = useState("");
  const [btnShowBreakPanelDisabled, setbtnShowBreakPanelDisabled] = useState(
    false
  );
  const [btnShowBreakPanelColor, setbtnShowBreakPanelColor] = useState("Gray");
  const [ddlActivityEnabled, setddlActivityEnabled] = useState(false);
  const [objdataview, setobjdataview] = useState([]);
  const [ddlActivitySelectedValue, setddlActivitySelectedValue] = useState(0);
  const [txtBreakCommentsEnabled, settxtBreakCommentsEnabled] = useState(false);
  const [timerValue, settimerValue] = useState(0);
  const [hdnSystemBreakValue, sethdnSystemBreakValue] = useState("");
  const [imgActivityToolTip, setimgActivityToolTip] = useState("");
  const [tSaveTimerEnabled, settSaveTimerEnabled] = useState(false);
  const [BtnCanceled, setBtnCanceled] = useState(false);
  const [breakStartTime, setbreakStartTime] = useState("");
  const [runSaveTImer, setrunSaveTImer] = useState(false);
  //Added by Pradip Rai on 27/12/2022
  const [countDownTimeDisabled, setCountDownTimer] = useState(false);
  const [ActivityStandardTime, setActivityStandardTime] = useState("");
  const [barvalue, setbarvalue] = useState(0);
  const [timer, setTimer] = useState(0);
  const [ImageModeValue, setImageModeValue] = useState(false);
  const countRef = React.useRef(null);
  const SearchTextRef = useRef(null);
  const CuurentStateRef = useRef(null);
  const CuurentStatusRef = useRef(null);

  //End by Pradip Rai
  const [btnStartWorkTimerDisabled, setbtnStartWorkTimerDisabled] = useState(
    false
  );
  const [employeeTimeUtilization, setemployeeTimeUtilization] = useState([]);
  const [activityName, setactivityName] = useState(
    sessionStorage.getItem("activityName")
  );
  const [workflowStateID, setworkflowStateID] = useState(
    sessionStorage.getItem("workflowstateId")
  );
  const [AttchmentModal, setAttchmentModal] = useState(false);
  const [IFrameURL, setIFrameURL] = useState("");
  const [DocumentOpened, setDocumentOpened] = useState(false);
  const [DocumentImgURL, setDocumentImgURL] = useState(undefined);

  const [UpdateTimerToZero, setUpdateTimerToZero] = useState(false);
  const UpdateTimerToZeroRef = React.useRef(false);
  const ExtraTimeStartedRef = React.useRef(false);

  const [viewWorkitemModal, setviewWorkitemModal] = useState(false);
  const [workitemId, setworkitemId] = useState("");
  const [checkedValue, setcheckedValue] = useState("3");
  const [workitemDetails, setworkitemDetails] = useState([]);
  const [workItemFieldsModel, setworkItemFieldsModel] = useState([]);
  const [workItemDetailModel, setworkItemDetailModel] = useState([]);

    const modeRef = React.useRef(null);
    const actRef = React.useRef(null);
    /*****************/
    const [isOnbaseUpload, setisOnbaseUpload] = useState(false);

    /**********/
  const AutoImageModeOnRef = React.useRef(null);
  const ImageModeRef = React.useRef(null);
  const [floatingModal, setfloatingModal] = useState(false);
  const [isPinned, setisPinned] = useState(false);
  const [size, setsize] = useState({width: 500, height: 500});
  const [Popupsize, setPopupsize] = useState({width: 800, height: 340});
  //disabled={isPinned}


  const [tableData, settableData] = useState({});
  const [editTableData, setEditTableData] = useState([]);

  const listWorkItemHistoryColumns = [
    {
      title: "From State",
      field: "fromState"
    },
    { title: "Status Name", field: "statusName" },
    { title: "To State", field: "toState" },
    { title: "Status By", field: "statusedBy" },
    { title: "Status DateTime", field: "statusDateTime" },
    { title: "Status Comment", field: "statusComment" },
    { title: "Status Reason", field: "statusReasons" },
    { title: "Time Zone", field: "timeZone" }
  ];

  const UploadAttachmentColumns = [
    {
      title: "AttachmentId",
      field: "workItemAttachmentID",
      hidden: true
    },
    {
      title: "UploadPath",
      field: "uploadedFilePath",
      hidden: true
    },
    {
      title: "Download",
      render: row => {
        let AttachmentUrl =
          getAPIURL(REACT_APP_DOWNLOADWORKITEMATTACHMENT) +
          "?fileName=" +
          row["mainUploadedFileName"] +
          "&folderPath=" +
          row["uploadedFilePath"];
        return (
          <>
            <a href={AttachmentUrl}>
              <AttachmentIcon />
            </a>
          </>
        );
      }
    },
    {
      title: "Uploaded By",
      field: "uploadedBy"
    },
    {
      title: "Uploaded FileName",
      field: "uploadedFileName"
    },
    { title: "Uploaded On", field: "uploadedOn" }
  ];
  
  const listEmailDetailsColumns = [
    {
      title: "Subject",
      field: "subject",
      render: (row) => (
        <>
          {row["subject"]}
          {row["hasAttachments"].toString() === "true" ? (
            <b class="material-symbols-outlined">attach_file</b>
          ) : (
            <></>
          )}
          {row["importance"] === "high" ? <img src={WarningIcon}></img> : <></>}
          { }
        </>
      ),
    },
    {
      title: "From",

      render: (row) => <>{row["from"].emailAddress.address.toString()}</>,
    },
    {
      title: "To",

      render: (row) => (
        <>
          {row["toRecipients"]
            .map((item) => item.emailAddress.address)
            .join(", ")}
        </>
      ),
    },
    {
      title: "Received At",
      field: "receivedDateTime",
      render: (row) => (
        <>{moment(row["receivedDateTime"]).format("ddd MM/D/YYYY h:mm A")}</>
      ),
    },
    {
      title: "",
      render: (row) => (
        <>
          <a title="Expand" href={row["webLink"]} target="blank">
            <img src={ViewEmailIcon}></img>
          </a>
        </>
      ),
    },
  ];

  const updateEmailColumns = useCallback(
    (keys) => {
      let col = [];
      keys.forEach((item) => {


        if (item === "webLink") {
          col.push({
            title: "Web Link",
            field: item,
            // cellStyle: (e, rowData) => {
            //   return { backgroundColor: rowData.BGColor };
            // },
            render: (row) => (
              <>
                <a href={row[item]} target="blank">
                  Click to view/respond.
                </a>
              </>
            ),
            editable: false,
            //filtering: true,
          });
        } else if (item === "subject" || item === "bodyPreview") {
          col.push({
            title: item,
            field: item,
          });
        }
      });

      setemailcolumns(col);
    },
    [GridColLen]
  );
  
  let advanceColumns = [
    { title: "Search Field", field: "searchField" },
    { title: "Condition", field: "condition" },
    { title: "Search Text", field: "searchText" }
  ];
  const onModeClick = (e) => {
    modeRef.current.classList.toggle("show-hide");
    actRef.current.classList.toggle("active");
    accRef.current.classList.remove("show-hide1");
    activeRef.current.classList.remove("active");
  };
  const [dashboardfilterValue, setdashboardfilterValue] = useState("Desc");
  const [viewSearchModal, setviewSearchModal] = useState(false);
  const [WorkFlowWorkItemFields, setWorkFlowWorkItemFields] = useState([]);
  const [numericOperator, setnumericOperator] = useState(false);
  const [notEqual, SetnotEqual] = useState("<>");
  const [lessThan, SetlessThan] = useState("<");
  const [lessThanEqual, SetlessThanEqual] = useState("<=");
  const [greaterThen, SetgreaterThen] = useState(">");
  const [greaterThenEqual, SetgreaterThenEqual] = useState(">=");
  const [isDateField, setisDateField] = useState(false);
  const [advSearchDetails, setadvSearchDetails] = useState([]);
  const [advSearchMapToFieldDetails, setadvSearchMapToFieldDetails] = useState([]);
  const [advSearchField, setadvSearchField] = useState("");
  const [advSearchMapToField, setadvSearchMapToField] = useState("");
  const [advCondition, setadvCondition] = useState("");
  const [advSearchCondition, setadvSearchCondition] = useState("");
  const [advSearchText, setadvSearchText] = useState("");
  const [imageUrlTemplate, setimageUrlTemplate] = useState("");
  const [columns, setcolumns] = useState([]);
  const [SavedPrefrencesFields, setSavedPrefrencesFields] = useState([]);
  const [isDefaultValue, setisDefaultValue] = useState(false);
  const [WorKFlowStates, setWorkFlowStates] = useState([]);
  const [CurrentStateID_Client, setCurrentStateID_Client] = useState("");
  const [WorkFlowStatuses, setWorkFlowStatuses] = useState([]);
  const [uplodadedDateTimeFrom, setuplodadedDateTimeFrom] = useState("");
  const [uplodadedDateTimeTo, setuplodadedDateTimeTo] = useState("");
  const [activityDateTimeFrom, setactivityDateTimeFrom] = useState("");
  const [activityDateTimeTo, setactivityDateTimeTo] = useState("");
  const [checkArchive, setcheckArchive] = useState(false);
  const [CuurentStatus, setCuurentStatus] = useState("");
  const [CuurentState, setCuurentState] = useState("");
  const [IsCustomField, setIsCustomField] = useState("0");
  const [searchItemFilterFields, setsearchItemFilterFields] = useState([]);
  const [disableDate, setdisableDate] = useState(true);
  const [advSearchTextDate, setadvSearchTextDate] = useState("");
  const [isDateTimeFeild, setisDateTimeFeild] = useState(false);
  const [advsubmitandcontinue, setadvsubmitandcontinue] = useState("");
  
  const [gridFilterstate, setgridFilterstate] = useState("");
  const [sortFieldstate, setsortFieldstate] = useState("");
  const [sortOrderstate, setsortOrderstate] = useState("");

  const auditRef = React.useRef(null);
  const counterRef = React.useRef(0);
  const auditmodeRef = React.useRef(null);
  const onauditClick = e => {
    auditmodeRef.current.classList.toggle("show-hide");
    auditRef.current.classList.toggle("active");
    auditRef.current.classList.remove("show-hide");
    actRef.current.classList.remove("active");
    accRef.current.classList.remove("show-hide1");
    activeRef.current.classList.remove("active");
  };
  const accRef = React.useRef(null);
  const activeRef = React.useRef(null);
  const onToggleClick = e => {
    accRef.current.classList.toggle("show-hide1");
    activeRef.current.classList.toggle("active");
    modeRef.current.classList.remove("show-hide");
    actRef.current.classList.remove("active");
  };

  // MODAL ONCLICK TOGGLE FUNCTION
  const [breakModal, setShow] = useState(false);
  const audittrailReference = React.useRef(null);
  const breakClose = () => setShow(false);
  const AuditTrailFocus = () => {
    // console.log("focus it");
    audittrailReference.current.focus();
  };
  const breakShow = () => setShow(true);

  const [workitemModal, setShow1] = useState(false);
  const [emailDetailsModal, setEmailDetails] = useState(false);

  const workitemClose = () => setShow1(false);
  const workitemShow = () => setShow1(true);
  //Added by Sachin/jinnat
  const [btnassignVisible, SetbtnassignVisible] = useState(false);
  //
  const emailDetailsClose = () => setEmailDetails(false);
  const emailDetailsShow = () => {
    setEmailDetails(true);
    //console.log(emailmessagedata.value[0]);
  };
  //added by Santosh
  const checkHandler = () => {
    setIsChecked(!isChecked);
  };
  const showAttachmentDetails = () => {
    setAttchmentModal(true);
  };
  const AttachmentClose = () => {
    setAttchmentModal(false);
  };
  let processingDashboardParam = [
    {
      row: [],
      selectedSubprocess: "",
      nav: [],
      userRoles: [],
      defaultFilter: "",
      assignedWorkItemDetails: []
    }
  ];
  //if (sessionStorage.getItem("is_Authenticated") == "true") {
  processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  //const ImageModevaluetemp = processingDashboardParam.ImageModeOn;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const columnsdtls = [
    {
      title: "AttachmentId",
      field: "workItemAttachmentID",
      hidden: true
    },
    {
      title: "UploadPath",
      field: "uploadedFilePath",
      hidden: true
    },
    {
      title: "Download",
      render: row => {
        let AttachmentUrl =
          getAPIURL(REACT_APP_DOWNLOADWORKITEMATTACHMENT) +
          "?fileName=" +
          row["mainUploadedFileName"] +
          "&folderPath=" +
          row["uploadedFilePath"];
        return (
          <>
            <a href={AttachmentUrl}>
              <AttachmentIcon />
            </a>
          </>
        );
      }
    },
    {
      title: "Uploaded By",
      field: "uploadedBy"
    },
    {
      title: "Uploaded FileName",
      field: "uploadedFileName"
    },
    { title: "Uploaded On", field: "uploadedOn" }
  ];

  // useEffect(() => {
  //   openFloatingModal();
  // } , [currentPaging, rowsperPage]);

  const openFloatingModal = (e)=>{
    let workflowactivityName = sessionStorage.getItem("workflowactivityName");
    sessionStorage.setItem(
      "" + workflowactivityName + "_mapToConditiondtl",[]);
    sessionStorage.setItem(
      "" + workflowactivityName + "_conditiondtl",[]);
      
    setIsCustomField("0");
    setCuurentState("");
    setCuurentStatus("");
    setuplodadedDateTimeFrom("");
    setuplodadedDateTimeTo("");
    setactivityDateTimeFrom("");
    setactivityDateTimeTo("");
    setcheckArchive(false);

    setfloatingModal(true);
    // setisLoadingWorkitemdetails(true);
    if (e && e.preventDefault) {
      e.preventDefault();
    }
  }
  const getSearchedPopupData = query =>
    new Promise(resolve => {
      let inputalues = [];
      let workItemIDInputParam =
        nextWorkItemId == ""
          ? +processingDashboardParam.workItemID
          : nextWorkItemId;
      if (JsonValues.length >= 0) {
        JsonValues.forEach(element => {
          for (let i = 0; i < element.screenFields.length; i++) {
            if (element.screenFields[i].isSearchTick === true) {
              const input = {
                mapToTable: element.screenFields[i].mapToTable,
                mapToField: element.screenFields[i].mapToField,
                value: element.screenFields[i].value
              };
              inputalues.push(input);
            }
          }
        });
      } else {
        JsonValues.state.regGroup.forEach(element => {
          for (let i = 0; i < element.screenFields.length; i++) {
            if (element.screenFields[i].isSearchTick === true) {
              const input = {
                mapToTable: element.screenFields[i].mapToTable,
                mapToField: element.screenFields[i].mapToField,
                value: element.screenFields[i].value
              };
              inputalues.push(input);
            }
          }
        });
      }
      
      let currentStateID = 0;
      let currentState = "";  
      let currentStatus = "";
      let subproseeworkflowConfigid = "";
      if(CuurentStateRef.current != null)
      {
        if (CuurentStateRef.current.value !== "Select") {
          if (
            CuurentStateRef.current.value.split("/")[1] == "Complete" ||
            CuurentStateRef.current.value.split("/")[1] == "Exceptions" ||
            CuurentStateRef.current.value.split("/")[1] == "Exception"
          ) {
            currentStateID = CurrentStateID_Client;
          } else currentStateID = CuurentStateRef.current.value.split("/")[0];
            setCuurentState(CuurentStateRef.current.value.split("/")[0]);
          if (
            CuurentStateRef.current.value.split("/")[1] == "Exceptions" ||
            CuurentStateRef.current.value.split("/")[1] == "Complete" ||
            CuurentStateRef.current.value.split("/")[1] == "Client" ||
            CuurentStateRef.current.value.split("/")[1] == "Exception"
          ) {
            if (CuurentStateRef.current.value.split("/")[1] == "Exceptions")
              currentState = "Exception";
            else currentState = CuurentStateRef.current.value.split("/")[1];
          } else {
            currentState = "";
          }
        }
      }
      if(CuurentStatusRef.current!=null){
        if (CuurentStatusRef.current.value !== "Select") {
          currentStatus = CuurentStatusRef.current.value.split("/")[1];
          subproseeworkflowConfigid = CuurentStatusRef.current.value.split("/")[0];
          setCuurentStatus(CuurentStatusRef.current.value.split("/")[0]);
        }
      }
      let filterValue = "";
      setdashboardfilterValue("");
      if (query.filters.length > 0) {
        const filter = query.filters.map(filter => {
          // return filter.column.field + filter.operator + filter.value + " and ";
          return filter.column.field + " Like '%" + filter.value + "%' and ";
        });
        filterValue += filter.join("");
        setdashboardfilterValue(filterValue);
      }
      setgridFilterstate(query.filters.length > 0 ? filterValue : "");
      setsortFieldstate(query.orderBy != undefined ? query.orderBy.field : "");
      setsortOrderstate(query.orderDirection != ""? query.orderDirection : "");
      if(inputalues.length!=0)
      {
        let body = {
          ActivityID: sessionStorage.getItem("PageId"),
          EmployeeID: sessionStorage.getItem("PersonID"),
          WorkflowStateID: processingDashboardParam.workflowstateID,
          SubProcessID: processingDashboardParam.selectedSubprocess,
          workItemID: processingDashboardParam.isOND
            ? processingDashboardParam.workitemOND
            : workItemIDInputParam,
          inputValues: inputalues,
          UserId: sessionStorage.getItem("GUID"),
          offset: 0,
          limit: 0,
          gridFilter:
            query.filters.length > 0 ? filterValue : "",
          sortField:
            query.orderBy != undefined
              ? query.orderBy.field : "",
          sortOrder:
            query.orderDirection != ""
              ? query.orderDirection : "",
          dashboardPageCondition: advSearchMapToFieldDetails,
          currentState: currentState,
          currentStateID: currentStateID,
          currentStatus: currentStatus,
          subproseeworkflowConfigid: subproseeworkflowConfigid,
          uplodadedDateTimeFrom: uplodadedDateTimeFrom,
          uplodadedDateTimeTo: uplodadedDateTimeTo,
          activityDateTimeFrom: activityDateTimeFrom,
          activityDateTimeTo: activityDateTimeTo,
          doSearchArchive: checkArchive
        };
        // let lockdeleteUrl = getAPIURL(REACT_APP_GET_ACTIVITYSEARCHWORKITEMDETAILS);
        // let deletelockreq = new Request(`${lockdeleteUrl}`, {
        let url = getAPIURL(REACT_APP_GET_ACTIVITYSEARCHWORKITEMDETAILS) + "?";
        url += "per_page=" + query.pageSize;
        url += "&page=" + (query.page + 1);
        // console.log(url);
        let deletelockreq = new Request(`${url}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body)
        });
        fetch(deletelockreq)
          .then(res => res.json())
          .then(result => {
            setisLoadingWorkitemdetails(false);
            if (result.length != 0) {
              tableRef.current.dataManager.changePageSize(query.pageSize);

              resolve({
                data: result.data,
                page: query.page,
                totalCount:
                  result.data.length > 0
                    ? result.data[0].TotalCount
                    : 0
              });

              updateSearchWorkitemColumns(
                Object.keys(result.data[0])

              );

              //addToast("WorkItem Searched Successfully.", {
              //  appearance: "success",
              //  autoDismiss: true
              //});
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
            if (error.name == "TypeError" && error.message == "Failed to fetch") {
              BodydetailsinLog(
                error.toString() + " openFloatingModal  ",
                body
              );
            }
          });
      }
      else {
        setfloatingModal(false);
        setisLoadingWorkitemdetails(false)
        addToast("Please select field to search.", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  
  const documnetShow = row => {
    if (
      row.CWSOnBaseDocID != undefined &&
      row.CWSOnBaseDocID != "" &&
      row.CWSOnBaseDocID != 0
    ) {
      //setviewDocumentModal(true);
      // console.log(row.CWSOnBaseDocID);
      // setimageUrl(
      //   //imageUrlTemplate.replace("@onBaseDocumentID", row.CWSOnBaseDocID)
      //   window.open(
      //     imageUrlTemplate.replace("@onBaseDocumentID", row.CWSOnBaseDocID),
      //     "_blank",
      //     "fullscreen=yes"
      //   )
      // );

      setimageUrl(
        //imageUrlTemplate.replace("@onBaseDocumentID", row.CWSOnBaseDocID)
        window.open(
          imageUrlTemplateRef.current.replace("@onBaseDocumentID", row.CWSOnBaseDocID),
          "_blank",
          "fullscreen=yes"
        )
      );
    } 
  };
  const handleRowDeleteAttachment = (oldData) => {
    if (window.confirm("Are you sure want to delete this record?")) {
      let body = {
        AttachmentID: oldData.workItemAttachmentID,
        mainUploadedFileName: oldData.mainUploadedFileName,
        filePath: oldData.uploadedFilePath,
        personId: sessionStorage.getItem("PersonID")
      };
      let attachmentDeleteUrl = getAPIURL(REACT_APP_DELETEWORKITEMATTACHMENT);
      let req = new Request(`${attachmentDeleteUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            getWorkItemAttachments(workitemId);
            addToast("Delete Sucessful!", {
              appearance: "success",
              autoDismiss: true
            });
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Error occured!", {
              appearance: "error",
              autoDismiss: true
            });
          }
        })
        .catch(error => {
          console.log("ERROR: ", error);
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            let Bodydetails = "";
            for (let i = 0; i < Object.keys(body).length; i++) {
              Bodydetails =
                Bodydetails +
                ", " +
                Object.keys(body)[i] +
                ": " +
                Object.values(body)[i];
            }
          }
        });

    }
  };
  const handleAdvSearchRowDelete = (oldData, resolve) => {
    
    const dataDelete = [...advSearchDetails];
    const mapDataDelete = [...advSearchMapToFieldDetails];
    const index = oldData.tableData.id;
    dataDelete.splice(index, 1);
    mapDataDelete.splice(index, 1);
    setadvSearchDetails([...dataDelete]);
    setadvSearchMapToFieldDetails([...mapDataDelete]);
    resolve();

    let workflowactivityName = sessionStorage.getItem("workflowactivityName");
    sessionStorage.setItem(
      "" + workflowactivityName + "_mapToConditiondtl",
      JSON.stringify(mapDataDelete)
    );
    sessionStorage.setItem(
      "" + workflowactivityName + "_conditiondtl",
      JSON.stringify(dataDelete)
    );
  };
  const getPopupDataForExportExcel = (e)=>{
    // setfloatingModal(true);
    // setisLoadingWorkitemdetails(true);
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    let inputalues = [];
    let workItemIDInputParam =
      nextWorkItemId == ""
        ? +processingDashboardParam.workItemID
        : nextWorkItemId;
    if (JsonValues.length >= 0) {
      JsonValues.forEach(element => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (element.screenFields[i].isSearchTick === true) {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          }
        }
      });
    } else {
      JsonValues.state.regGroup.forEach(element => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (element.screenFields[i].isSearchTick === true) {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          }
        }
      });
    }
    let currentStateID = 0;
    let currentState = "";  
    let currentStatus = "";
    let subproseeworkflowConfigid = "";
    if(CuurentStateRef.current != null)
    {
      if (CuurentStateRef.current.value !== "Select") {
        if (
          CuurentStateRef.current.value.split("/")[1] == "Complete" ||
          CuurentStateRef.current.value.split("/")[1] == "Exceptions" ||
          CuurentStateRef.current.value.split("/")[1] == "Exception"
        ) {
          currentStateID = CurrentStateID_Client;
        } else currentStateID = CuurentStateRef.current.value.split("/")[0];
          setCuurentState(CuurentStateRef.current.value.split("/")[0]);
        if (
          CuurentStateRef.current.value.split("/")[1] == "Exceptions" ||
          CuurentStateRef.current.value.split("/")[1] == "Complete" ||
          CuurentStateRef.current.value.split("/")[1] == "Client" ||
          CuurentStateRef.current.value.split("/")[1] == "Exception"
        ) {
          if (CuurentStateRef.current.value.split("/")[1] == "Exceptions")
            currentState = "Exception";
          else currentState = CuurentStateRef.current.value.split("/")[1];
        } else {
          currentState = "";
        }
      }
    }
    if(CuurentStatusRef.current!=null){
      if (CuurentStatusRef.current.value !== "Select") {
        currentStatus = CuurentStatusRef.current.value.split("/")[1];
        subproseeworkflowConfigid = CuurentStatusRef.current.value.split("/")[0];
        setCuurentStatus(CuurentStatusRef.current.value.split("/")[0]);
      }
    }
    if (inputalues.length != 0) {
      let body = {
        ActivityID: sessionStorage.getItem("PageId"),
        EmployeeID: sessionStorage.getItem("PersonID"),
        WorkflowStateID: processingDashboardParam.workflowstateID,
        SubProcessID: processingDashboardParam.selectedSubprocess,
        workItemID: processingDashboardParam.isOND
          ? processingDashboardParam.workitemOND
          : workItemIDInputParam,
        inputValues: inputalues,
        UserId: sessionStorage.getItem("GUID"),
        offset: 0,
        limit: 0,
        gridFilter:
          gridFilterstate,
        sortField:
          sortFieldstate,
        sortOrder:
          sortOrderstate,
        dashboardPageCondition: advSearchMapToFieldDetails,
        currentState: currentState,
        currentStateID: currentStateID,
        currentStatus: currentStatus,
        subproseeworkflowConfigid: subproseeworkflowConfigid,
        uplodadedDateTimeFrom: uplodadedDateTimeFrom,
        uplodadedDateTimeTo: uplodadedDateTimeTo,
        activityDateTimeFrom: activityDateTimeFrom,
        activityDateTimeTo: activityDateTimeTo,
        doSearchArchive: checkArchive
      };
      let lockdeleteUrl = getAPIURL(REACT_APP_GET_ACTIVITYSEARCHWORKITEMDETAILS);
      let deletelockreq = new Request(`${lockdeleteUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body)
      });
      fetch(deletelockreq)
        .then(res => res.json())
        .then(result => {
          // setisLoadingWorkitemdetails(false);
          if (result.length != 0) {
            const data1 = result.data;
            var count = data1[0].TotalCount;
            data1.map(obj => {
              for (var i = 0; i < count; i++) {
                delete data1[i].TotalCount;
              }
            });
            DownloadExcel(data1, "SearchedData");

            addToast("WorkItem Exported Successfully.", {
              appearance: "success",
              autoDismiss: true
            });
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            BodydetailsinLog(
              error.toString() + " getPopupDataForExportExcel  ",
              body
            );
          }
        });
    }
    else {
      setfloatingModal(false);

      addToast("Please select field to search.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }
  const ResizeModal = (e, { size }) => {
    e.preventDefault();
    setsize(size);
  }
  const togglePin = () => {
    setisPinned(!isPinned);
  }
  const closeFloatingModal = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setfloatingModal(!floatingModal)

  }


  const handlePageChange = (x) => {
    setcurrentPaging(prevpage => {
      const newPage = x === 'next' ? prevpage + 1 : prevpage - 1;
      return newPage > 0 ? newPage : 1;
    })
  }

  const rowsPerPageChange = (e) => {
    setrowsperPage(parseInt(e.target.value, 10));
    setcurrentPaging(1);
  }


  const getAPIURL = api => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  let disabledStyle = {
    display: "none"
  };
  //Added by Pradip Rai on 27/12/2022
  const handleStart = () => {
    countRef.current = setInterval(() => {
      setTimer(timer => timer + 1);
    }, 1000);
  };
  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);

    return `${getMinutes} : ${getSeconds}`;
  };

  const [expiryTime, setExpiryTime] = useState("22 dec 2022 23:00:00");

  const [countdownTime, setCountdownTime] = useState({
    countdownDays: "",
    countdownHours: "",
    countdownMinutes: "",
    countdownSeconds: ""
  });
  let timeInterval;

  const countdownTimer = () => {
    //console.log("dfdf " + ActivityStandardTime);
    let s = new Date();
    s.setMinutes(s.getMinutes() + ActivityStandardTime);
    var countdownDateTime = s.getTime();

    timeInterval = setInterval(() => {
      if (UpdateTimerToZeroRef.current == true) {
        clearInterval(timeInterval);
        timeInterval = null;
        UpdateTimerToZeroRef.current = false;
        if (ExtraTimeStartedRef.current == true) {
          ExtraTimeStartedRef.current = false;
          setTimer(0);
          countdownTimer();
        }
        return;
      }
      var currentTime = new Date().getTime();
      var remainingDayTime = countdownDateTime - currentTime;
      var totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
      var totalHours = Math.floor(
        (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var totalMinutes = Math.floor(
        (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      var totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);
      var runningCountdownTime = {
        countdownDays: totalDays,
        countdownHours: totalHours,
        countdownMinutes: totalMinutes,
        countdownSeconds: totalSeconds,
      };

      setCountdownTime(runningCountdownTime);
      //console.log(runningCountdownTime);
      if (remainingDayTime <= 0) {
        setTimer(0);
        clearInterval(timeInterval);
        ExtraTimeStartedRef.current = true;
        setExpiryTime(false);
        handleStart();
      }

    }, 1000);
    //clearInterval(timeInterval);
  };
  const viewWorkitemShow = item => {
    //Added By Durgansh
    getWorkItemAttachments(item.WorkItemID);
    setviewWorkitemModal(true);
    setworkitemId(item.WorkItemID);
    getSelectedWorkItem(item.WorkItemID);
  };
  const getWorkItemAttachments = (workItemIDInputParam) => {
    let body = {
      workItemID: workItemIDInputParam
    };
    let loginUrl = getAPIURL(REACT_APP_GETWORKITEMATTACHMENTS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          if (result.data.workItemAttachments != null) {
            result.data.workItemAttachments.map(item => {
              let index = item.uploadedFileName.indexOf("_");
              item.uploadedFileName = item.uploadedFileName.substring(++index);
            });
            setAttachmentDetails(result.data.workItemAttachments);
          } else {
            setAttachmentDetails([]);
          }
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          navigate("/");
        } else {
          addToast("Error occured!", {
            appearance: "error",
            autoDismiss: true
          });
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };
  const getSelectedWorkItem = workItemId => {
    let body = {
      workItemID: workItemId,
      //personid: sessionStorage.getItem("PersonID"), //#1
      subprocessid: processingDashboardParam.selectedSubprocess,
      selectedvalue: checkedValue
    };
    let loginUrl = getAPIURL(REACT_APP_GET_SEARCHWORKITEMDETAILS);
    //let loginUrl = getAPIURL(REACT_APP_GET_WORKITEMDETAILS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          //DisplayTimeZone(result.data.asignmentdetails);
          setworkitemDetails(result.data.asignmentdetails);
          //DisplayTimeZone(result.data.listWorkItemHistory);
          setworkitemHistory(result.data.listWorkItemHistory);
          setworkItemDetailModel(result.data.workItemDetails);
          setworkItemFieldsModel(result.data.workItemFieldsDetails);
          setregGroup(result.data.repGroup);

          const dataTablestructure = {};
          result.data.workItemMultiValueFieldsDetails.forEach((entry) => {
            const {groupName, mapToField, fieldValues, rowNo} = entry;

            if (!dataTablestructure[groupName]) {
              dataTablestructure[groupName] = [];
            }

            while (dataTablestructure[groupName].length < rowNo) {
              dataTablestructure[groupName].push({});
            }
            dataTablestructure[groupName][rowNo-1][mapToField] = fieldValues;
          })
          setworkItemMultivalueDetailModel(dataTablestructure);

        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  //Ended by Pradip Rai on 27/12/2022
  const formatAMPM = date => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    setcurrentTime(strTime);
  };
  const viewWorkitemClose = () => {
    setworkitemHistory([]);
    setworkitemDetails([]);
    setworkItemFieldsModel([]);
    setworkItemDetailModel([]);
    setworkItemMultivalueDetailModel([]);
    setviewWorkitemModal(false);
    setworkitemId("");
  };
  // const GetAttachment = (row) => {
  //
  //   let AttachmentUrl = getAPIURL(REACT_APP_DOWNLOADWORKITEMATTACHMENT)+"?fileName="+row.mainUploadedFileName;
  //   return(
  //     <>
  //     <a href={AttachmentUrl}>Download File From Folder</a>
  //     </>
  //   )
  //   // let AttachmentUrl = getAPIURL(REACT_APP_DOWNLOADWORKITEMATTACHMENT);
  //   // let req = new Request(`${AttachmentUrl}`, {
  //   //   method: "GET",
  //   //   headers: {
  //   //     "Content-Type": "multipart/form-data",
  //   //     UserName: sessionStorage.getItem("UserName"),
  //   //     fileName: row.mainUploadedFileName,
  //   //     Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
  //   //   },
  //   // });
  //   // fetch(req)
  //   // .then((res) => res.json())
  //   // .then((result) => {
  //   //
  //   //   result.blob().then(blob => {
  //   //     let url = window.URL.createObjectURL(blob);
  //   //     let a = document.createElement('a');
  //   //     a.href = url;
  //   //     a.download = 'employees.json';
  //   //     a.click();
  //   //   })
  //   // })
  //   // .catch((error) => {
  //   //   ;
  //   // });
  // };

  // const changeScreenFilterValue = () => {
  //   setscreenFilterValue(!screenFilterValue);
  // };
  useEffect(() => {
    getAdvanceSearchField();
    getWorkFlowstates();
    getWorkFlowstatus();
    
    let workflowactivityName = sessionStorage.getItem("workflowactivityName");
    sessionStorage.setItem(
      "" + workflowactivityName + "_mapToConditiondtl",[]);
    sessionStorage.setItem(
      "" + workflowactivityName + "_conditiondtl",[]);
      
    if (isEmailIntegration == true && workItemFieldsDetails.length != 0) {
      let GetEmailDetailsAPIURL = getAPIURL(REACT_APP_GET_WORKITEMEMAILDETAILS);
      const conversationId = workItemFieldsDetails.filter((itemfield) => {
        return (
          itemfield.mapToField.trim().toLowerCase() ===
          emailMessageMapField.trim().toLowerCase()
        );
      })[0].value;

      setEmailLoding(true);
      let GetEmailReqbody = {
        workItemID: processingDashboardParam.workItemID.toString(),
        userId: sessionStorage.getItem("GUID"),
        subprocessid: processingDashboardParam.selectedSubprocess,
        emailIntegration: isEmailIntegration,
        emailGroup: emailGroup,
        EmailGroupFolder1: EmailGroupFolder1,
        EmailGroupFolder2: EmailGroupFolder2,
        ConversationId: conversationId,
      };
      let getemaildetailsreq = new Request(`${GetEmailDetailsAPIURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(GetEmailReqbody),
      });
      fetch(getemaildetailsreq)
        .then((res) => res.json())
        .then((result) => {
          setEmailLoding(false);
          if (result.status === 200) {
            // console.log("emails", result.data);
            if (result.data != undefined && result.data != null) {
              setEmailConversations(result.data);
            }

            // set_IsLoading(false);
          } else if (result.status === 401) {
            //;
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            //localStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          ;
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            BodydetailsinLog(
              error.toString() + " useEffect getemaildetailsreq  ",
              GetEmailReqbody
            );
            //navigate("/ErrorPage");
            //window.location.href = "/Home";
          }
          //navigate("/");
          //}
        });
    }
  }, [isEmailIntegration && workItemFieldsDetails.length != 0, isEmailReplied]);

  const ReplyEmailMessage = (messageId, emailData) => {
    
    let replyEmailURL = getAPIURL(React_APP_ReplyEmailConversation);
    var username = sessionStorage.getItem("UserName");
    setEmailLoding(true);
    let GetEmailReqbody = {
      // workItemID: processingDashboardParam.workItemID.toString(),
      // userId: sessionStorage.getItem("GUID"),
      subprocessid: processingDashboardParam.selectedSubprocess,
      // emailIntegration: isEmailIntegration,
      emailGroup: emailGroup,
      To: emailData.to,
      Cc: emailData.cc,
      Body:
        emailData.body +
        '<p style="font-size: 0.9em; color: #777777; font-style: italic;">Note: By ' +
        username +
        " via CWS</p>",
      MessageId: messageId,
    };
    let getemailReplyreq = new Request(`${replyEmailURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(GetEmailReqbody),
    });
    fetch(getemailReplyreq)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          // console.log("emails", result.data);
          setopenComposeEmail(false);
          setisEmailReplied(true);
          addToast("Email Conversation replied!", {
            appearance: "success",
            autoDismiss: true,
          });
          // set_IsLoading(false);
        } else if (result.status === 401) {
          //console.log("ERROR: ", error);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
 
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(
            error.toString() + " useEffect getemaildetailsreq  ",
            GetEmailReqbody
          );
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
        //navigate("/");
        //}
      });
  }; 
  const BodydetailsinLog = (error, body) => {
    let Bodydetails = "";
    for (let i = 0; i < Object.keys(body).length; i++) {
      if (
        Object.values(body)[i] != null &&
        typeof Object.values(body)[i] == "object"
      ) {
        Bodydetails = Bodydetails + ", \n" + Object.keys(body)[i] + ": {";
        for (let j = 0; j < Object.keys(Object.values(body)[i]).length; j++) {
          if (j != 0) {
            Bodydetails = Bodydetails + ",";
          }
          if (
            Object.values(Object.values(body)[i])[j] != null &&
            typeof Object.values(Object.values(body)[i])[j] == "object"
          ) {
            Bodydetails =
              Bodydetails +
              " " +
              Object.keys(Object.values(body)[i])[j] +
              ": {";
            for (
              let a = 0;
              a < Object.keys(Object.values(Object.values(body)[i])[j]).length;
              a++
            ) {
              if (a != 0) {
                Bodydetails = Bodydetails + ",";
              }
              Bodydetails =
                Bodydetails +
                " " +
                Object.keys(Object.values(Object.values(body)[i])[j])[a] +
                ": " +
                Object.values(Object.values(Object.values(body)[i])[j])[a];
            }
            Bodydetails = Bodydetails + " } ";
          } else {
            Bodydetails =
              Bodydetails +
              " " +
              Object.keys(Object.values(body)[i])[j] +
              ": " +
              Object.values(Object.values(body)[i])[j];
          }
        }
        Bodydetails = Bodydetails + " } ";
      } else {
        Bodydetails =
          Bodydetails +
          ", \n" +
          Object.keys(body)[i] +
          ": " +
          Object.values(body)[i];
      }
    }
    CorsLoggingError(error.toString() + " " + Bodydetails.toString());
  };
  const handleSendEmail = (e, emailData) => {
    if (e != null) {
      e.preventDefault();
    }
    // console.log("Sending email:", emailData);
    // console.log("previewedEmail:", previewedEmail);
    var messageId = previewedEmail.id;
    ReplyEmailMessage(messageId, emailData);
  };
  const handleCloseSendEmail = (e) => {
    if (e != null) {
      e.preventDefault();
    }
    setopenComposeEmail(false);
  };  

   //Added by Sachin for Email Details
 const trucateBody = (bodyPreview, size) => {
  if (bodyPreview.length > size) {
    return bodyPreview.substring(0, size - 3) + "...";
  } else {
    return bodyPreview;
  }
};
const getemailbyMessageID = (id) => {
  try {
    let body = {
      mode: "",
      userId: sessionStorage.getItem("GUID"),
      applicationName: REACT_APP_NAME,
      subProcessID: processingDashboardParam.selectedSubprocess,
      messageId: id,
      emailGroup: "",
    };

    let screenAPIURL = getAPIURL(REACT_APP_GET_EmailDetailsByMessageID);
    let req = new Request(`${screenAPIURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization:
          "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });

    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          //Added by Pradip Rai
          setemailPreviewActive(true);
          //Ended by Pradip Rai
          // console.log(result);

          //End by Pradip Rai on 27/12/2022
        }
        if (result.status === 500) {
          addToast(result.message, {
            appearance: "info",
            autoDismiss: true,
          });
        } else if (result.status === 401) {
          //console.log("ERROR: ", error);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
        set_IsLoading(false);
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(
            error.toString() + " getDetailsOfNextWorkId req ",
            body
          );
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
        //navigate("/");
        //}
        set_IsLoading(false);
      });
  } catch (ex) {
    addToast("Error occured!", {
      appearance: "error",
      autoDismiss: true,
    });
  }
};
//end    

  const CorsLoggingError = error => {
    let logUrl = getAPIURL(REACT_APP_UILOGEXTENSION);
    let body = {
      personID: +sessionStorage.getItem("PersonID"),
      lanID: sessionStorage.getItem("UserName"),
      message: "Cors Error: " + error,
      pageName: currentPage.split("/")[currentPage.split("/").length - 1],
      logType: true
    };
    let req = new Request(`${logUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
        } else {
          // addToast("Something went wrong", {
          //   appearance: "error",
          //   autoDismiss: true,
          // });
        }
      })
      .catch(error => {
        ;
        // addToast("Something went wrong", {
        //   appearance: "error",
        //   autoDismiss: true
        // });
        //navigate("/Home");
      });
  };

  //sachin
const handleItemClick = (e, index, email) => {
  // console.log(email);
  e.preventDefault();
  setpreviewedEmail(email);
  EmailShow(true);
};
const replyEmail = (e, webLink) => {
  e.preventDefault();
  setopenComposeEmail(true);
  // window.open(webLink, "", "width=600, height=600");
};
const EmailShow = (show) => {

  if (show == true) {
    setfieldClassName("col-4");
    setdivClassName("splitLeft");
    setemailPreviewActive(true);

    setstaticfieldflex("");
    setAuditTrailButtonEnable(true);
    setbuttonEndClass("col-4");
    setisstaticfieldsColumn(false);
  } else {
    setfieldClassName("col-3");
    setdivClassName("splitfull");
    setemailPreviewActive(false);
    setstaticfieldflex("d-flex");
    setAuditTrailButtonEnable(false);
    setbuttonEndClass("col-3");
    setisstaticfieldsColumn(true);
  }
};
const hidePreviwer = (e) => {
  e.preventDefault();
  setopenComposeEmail(false);
  EmailShow(false);
}; 

  const workItemTypeChange = e => {
    setddlWorkItemTypeValue(e.target.value.split("|")[0]);
  };
  //Added by Pradip for bussiness rule
  const FetchGetSaveWorkitemFieldDetails = (
    subProcessID,
    activityID,
    mapToFieldID,
    regGroup
  ) => {
    let body = {
      subProcessID: subProcessID,
      activityID: activityID,
      mapToFieldID: mapToFieldID,
    };
    let getWorkitemFieldUrl = getAPIURL(REACT_APP_GETWORKITEMFIELDSRULES);
    let req = new Request(`${getWorkitemFieldUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {

          setactScreenBussinessRules(result.data);
          WorkitemFieldOnLoadChange(result.data, regGroup);
        }
      })
      .catch((error) => {
        ;
      });
  };
  //Ended by Pradip for bussiness rule
  const uploadFileOnServerForAppend = () => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", selectedFileForUpload);
    let loginUrl = getAPIURL(REACT_APP_GETUPLOADFILEDETAILS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        //"Content-Type": "multipart/form-data",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: formData
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.path !== "") {
          setuploadedFileName(result.path);
          onbaseFileAppend(result.path);
        } else {
          addToast("Something went wrong", {
            appearance: "error",
            autoDismiss: true
          });
        }
      })
      .catch(error => {
        ;
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          CorsLoggingError(error.toString() + " uploadFileOnServerForAppend");
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
        //navigate("/");
      });
  };
  const handleRowDelete = (oldData) => {
    if (window.confirm("Are you sure want to delete this record?")) {
      let body = {
        AttachmentID: oldData.workItemAttachmentID,
        mainUploadedFileName: oldData.mainUploadedFileName,
        filePath: oldData.uploadedFilePath,
        personId: sessionStorage.getItem("PersonID")
      };
      let attachmentDeleteUrl = getAPIURL(REACT_APP_DELETEWORKITEMATTACHMENT);
      let req = new Request(`${attachmentDeleteUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            getWorkItemAttackments(+processingDashboardParam.workItemID);
            addToast("Delete Sucessful!", {
              appearance: "success",
              autoDismiss: true
            });
            setIsresolve(true);
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
            setIsresolve(true);
          } else {
            addToast("Error occured!", {
              appearance: "error",
              autoDismiss: true
            });
            setIsresolve(true);
          }
        })
        .catch(error => {
          ;
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            let Bodydetails = "";
            for (let i = 0; i < Object.keys(body).length; i++) {
              Bodydetails =
                Bodydetails +
                ", " +
                Object.keys(body)[i] +
                ": " +
                Object.values(body)[i];
            }
            CorsLoggingError(
              error.toString() + " handleRowDelete " + Bodydetails.toString()
            );
            //navigate("/ErrorPage");
            //window.location.href = "/Home";
          }
          //setIsresolve(true);
          setIsresolve(true);
        });
    }
  };
  const uploadFileOnServerForUpload = () => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", selectedFileForUpload);
    let loginUrl = getAPIURL(REACT_APP_GETUPLOADFILEDETAILS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        //"Content-Type": "multipart/form-data",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: formData
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.path !== "") {

          setuploadedFileName(result.path);
          onbaseFileUpload(result.path);

          //setdivOnbaseDocAppendVisible(true);
          //setdivOnbaseDocAppendVisible(true);
          //setisstaticfieldsColumn(false);
        } else {
          addToast("Something went wrong", {
            appearance: "error",
            autoDismiss: true
          });
        }
      })
      .catch(error => {
        ;
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          CorsLoggingError(error.toString() + " uploadFileOnServerForUpload");
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
        // navigate("/");
      });
  };
  const onbaseFileAppend = FileName => {
    try {
      let body = {
        // methodName: "fileappend",
        // workItemId: 0,
        subprocessId: processingDashboardParam.selectedSubprocess,
        fileName: FileName.split(REACT_APP_UPLOADPATHNAME)[1],
        //documentType: "",
        documentId: ucOnbaseDocAppendDocID + ""
      };
      let onBaseAppendUrl = getAPIURL(REACT_APP_FETCHONBASEFILEAPPEND);
      //let loginUrl = REACT_APP_FETCHONBASEFILEAPPEND;
      let req = new Request(`${onBaseAppendUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            //refreshPage();
            //setNeedRefresh(true);

            window.location.reload();
            addToast("File Append Sucessful!", {
              appearance: "success",
              autoDismiss: true
            });
          } else if (result.status === 401) {
            //;
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Error occured!", {
              appearance: "error",
              autoDismiss: true
            });
            //set_IsLoading(false);
          }
          setIsUploading(true);
          setIsUploadOrAppend(false);
        })
        .catch(error => {
          //if (result.status === 401) {
          ;
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            BodydetailsinLog(error.toString() + " onbaseFileAppend ", body);
            //navigate("/ErrorPage");
            //window.location.href = "/Home";
          }
        });
    } catch (ex) {
      // console.log("test");
      setIsUploading(true);
      setIsUploadOrAppend(false);

      addToast("Error occured!", {
        appearance: "error",
        autoDismiss: true
      });
    }
  };
  const onbaseFileUpload = FileName => {
    let workItemIDInputParam =
      nextWorkItemId == ""
        ? +processingDashboardParam.workItemID
        : nextWorkItemId;
    let body = {
      methodName: "fileupload",
      workItemId: workItemIDInputParam,
      subprocessId: processingDashboardParam.selectedSubprocess,
      fileName: FileName.split(REACT_APP_UPLOADPATHNAME)[1],
      documentType: UploadDocumetId.toString(),
    };
    let onBaseUploadUrl = getAPIURL(REACT_APP_FETCHONBASEFILEUPLOAD);
    //let loginUrl = REACT_APP_FETCHONBASEFILEUPLOAD;
    let req = new Request(`${onBaseUploadUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          //ImageToggleVisible(true);
          //refreshPage()
          setNeedRefresh(true);
          if (result.data.documentId > 1) {
            addToast("Upload Sucessful!", {
              appearance: "success",
              autoDismiss: true,
            });
            setdivOnbaseDocUploadVisible(false);
            //Added by Pradip Rai

            if (
              OnBaseDocumentID == null ||
              OnBaseDocumentID == 0 ||
              OnBaseDocumentID == undefined
            ) {
              setImageToggleVisible(false);
              ImageShow(false);
            } else {
              if (processingDashboardParam.AutoImageMode === "true") {
                setImageToggleVisible(true);
                ImageShow(true);
                setImageModeValue(true);
              } else {
                setImageToggleVisible(true);
                ImageShow(false);
                setImageModeValue(false);
              }
            }
            //Ended by Pradip Rai
          } else {
            addToast("Error Occured during upload", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        } else if (result.status === 401) {
          //;
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          addToast("Error occured!", {
            appearance: "error",
            autoDismiss: true
          });
          //set_IsLoading(false);
        }
        setIsUploading(true);
        setIsUploadOrAppend(false);
      })
      .catch(error => {
        //if (result.status === 401) {
        ;
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " onbaseFileUpload ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };
  // const getS3BucketFolders = async () => {
  //   const params = {
  //     Bucket: "uat-appcode",
  //     Delimiter: "/",
  //   };

  //   try {
  //     const response = await s3.listObjectsV2(params).promise();
  //     const commonPrefixes = response.CommonPrefixes.map((prefix) =>
  //       prefix.Prefix.replace("/", "")
  //     );
  //     if (commonPrefixes.includes(sessionStorage.getItem("ProcessId"))) {
  //       UploadFileOnS3Bucket();
  //     }
  //     //sets3BktFolders(commonPrefixes);
  //     console.log("Folders:", commonPrefixes);
  //   } catch (error) {
  //     console.error("Error retrieving folders:", error);
  //   }
  // };
  // const CreateFolderForWorkitem = async () => {
  //   let workItemIDInputParam =
  //     nextWorkItemId == ""
  //       ? +processingDashboardParam.workItemID
  //       : nextWorkItemId;
  //   const today = new Date();
  //   const params = {
  //     Bucket: "uat-appcode",
  //     Key:
  //       sessionStorage.getItem("ProcessId") + "/" + today.toISOString() + "/",
  //     Body: "", // Empty body to create a zero-byte object
  //     //ACL: "private", // Set the appropriate ACL permissions for the folder
  //   };

  //   try {
  //     await s3.putObject(params).promise();
  //     console.log("Folder created successfully");
  //   } catch (error) {
  //     console.error("Error creating folder:", error);
  //   }
  // };
  // const uploadFile = async (file) => {
  //
  //   const guid = uuidv4();
  //   const filePath =
  //     sessionStorage.getItem("ProcessId") + "/" + today.toISOString();
  //   const fileName = guid + "_" + file.name;
  //   const uploadFileKey = filePath + "/" + fileName;
  //   const today = new Date();
  //   let workItemIDInputParam =
  //     nextWorkItemId == ""
  //       ? +processingDashboardParam.workItemID
  //       : nextWorkItemId;
  //   const params = {
  //     Bucket: "uat-appcode",
  //     Key: uploadFileKey,
  //     Body: file,
  //     //ACL: 'public-read', // Optional: Set the appropriate ACL permissions
  //   };
  //   try {
  //     await s3.upload(params).promise();
  //     console.log("File uploaded successfully");
  //     createWorkitemAttachment(filePath, fileName);
  //     //getFilesFromFolder(workItemIDInputParam);
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //   }
  // };
  // const getFilesFromFolder = async (folderName) => {
  //   const params = {
  //     Bucket: "uat-appcode",
  //     Prefix: sessionStorage.getItem("ProcessId") + "/" + folderName,
  //   };

  //   try {
  //     const response = await s3.listObjectsV2(params).promise();
  //     const files = response.Contents.map((file) => file.Key);
  //     setAttachmentDetails(files);
  //     console.log("Files:", files);
  //   } catch (error) {
  //     console.error("Error retrieving files:", error);
  //   }
  // };
  // const UploadFileOnS3Bucket = () => {
  //   CreateFolderForWorkitem();
  //   uploadFile(UploadingFile);
  // };
  const uploadFileOnServer = () => {
    setAttachmentUploading(true);
    setIsAttachmentUpload(true); //pradip
    //getS3BucketFolders();
    const formData = new FormData();
    formData.append("file", UploadingFile);
    //formData.append("SubprocessId",sessionStorage.getItem("ProcessId"))
    let loginUrl = getAPIURL(REACT_APP_GETUPLOADFILEDETAILS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        //"Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        SubProcessID: sessionStorage.getItem("ProcessId"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: formData
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          setuploadedFileName(result.data.s3bucketfolderPath);
          createWorkitemAttachment(
            result.data.s3bucketfolderPath,
            result.data.filekeyName
          );
        } else {
          addToast("Something went wrong", {
            appearance: "error",
            autoDismiss: true
          });
        }
        setAttachmentUploading(false);
        setIsAttachmentUpload(false); //pradip
      })
      .catch((error) => {
        setAttachmentUploading(false);
        setIsAttachmentUpload(false); //pradip
        ;
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          CorsLoggingError(error.toString() + " uploadFileOnServer");
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };
  const createWorkitemAttachment = (filePath, fileName) => {
    let workItemIDInputParam =
      nextWorkItemId == ""
        ? +processingDashboardParam.workItemID
        : nextWorkItemId;
    let body = {
      workItemID: +workItemIDInputParam,
      UploadedFilePath: filePath,
      uploadedFileName: fileName,
      employeeId: +sessionStorage.getItem("PersonID")
    };
    let loginUrl = getAPIURL(REACT_APP_CREATEWORKITEMATTACHMENT);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          getWorkItemAttackments(workItemIDInputParam);
          addToast("Upload Sucessful!", {
            appearance: "success",
            autoDismiss: true
          });
        } else if (result.status === 401) {
          //;
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          addToast("Error occured!", {
            appearance: "error",
            autoDismiss: true
          });
          //set_IsLoading(false);
        }
      })
      .catch(error => {
        //if (result.status === 401) {
        ;
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(
            error.toString() + " createWorkitemAttachment ",
            body
          );
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };
  const deletelock = () => {
    let body = {
      workItemID: workitem_Id
    };
    let lockdeleteUrl = getAPIURL(REACT_APP_DELETELOCKBYWORKITEMID);
    let deletelockreq = new Request(`${lockdeleteUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body)
    });
    fetch(deletelockreq)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          // console.log("lockdelted");
        } else if (result.status === 401) {
          //;
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        //if (result.status === 401) {
        ;
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " deletelock ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
        //navigate("/");
        // }
      });
  };
  const getWorkItemAttackments = (workItemIDInputParam) => {
    let body = {
      workItemID: workItemIDInputParam
    };
    let loginUrl = getAPIURL(REACT_APP_GETWORKITEMATTACHMENTS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setIsUpload(false);
          setIsUploading(false);
          if (result.data.workItemAttachments != null) {
            result.data.workItemAttachments.map(item => {
              let index = item.uploadedFileName.indexOf("_");
              item.uploadedFileName = item.uploadedFileName.substring(++index);
              //item.uploadedOn = item.uploadedOn.split("T")[0];
            });
            setAttachmentDetails(result.data.workItemAttachments);
          } else {
            setAttachmentDetails([]);
          }
          setuploadedFile("");
        } else if (result.status === 401) {
          //;
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          addToast("Error occured!", {
            appearance: "error",
            autoDismiss: true
          });
          //set_IsLoading(false);
        }
      })
      .catch(error => {
        //if (result.status === 401) {
        ;
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " getWorkItemAttackments ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
        //navigate("/");
        //}
      });
  };
  const readUploadFileForUpload = e => {
    const extension = e.target.files[0].name.split(".").pop();
    if (
      extension.toLowerCase() === "tiff" ||
      extension.toLowerCase() === "tif" ||
      extension.toLowerCase() === "pdf"
    ) {
      setselectedFileForUpload(e.target.files[0]);
      setselectedFileNameForUpload(e.target.value);
      setIsUploadOrAppend(true);
    } else {
      addToast("File format is not supported", {
        appearance: "error",
        autoDismiss: true
      });
    }
  };
  const readUploadFile = e => {
    //
    const extension = e.target.files[0].name.split(".").pop();
    const type = e.target.files[0].type;
    setselectedFile(e.target.files[0]);
    if (
      extension.toLowerCase() === "jpg" ||
      extension.toLowerCase() === "jpeg" ||
      extension.toLowerCase() === "bmp" ||
      extension.toLowerCase() === "png" ||
      extension.toLowerCase() === "gif" ||
      extension.toLowerCase() === "tiff" ||
      extension.toLowerCase() === "tif" ||
      extension.toLowerCase() === "txt" ||
      extension.toLowerCase() === "doc" ||
      extension.toLowerCase() === "xls" ||
      extension.toLowerCase() === "pdf" ||
      extension.toLowerCase() === "ppt" ||
      extension.toLowerCase() === "xlsx" ||
      extension.toLowerCase() === "docx" ||
      extension.toLowerCase() === "pptx" ||
      extension.toLowerCase() === "msg"
    ) {
      if (
        type.includes("text") ||
        type.includes("msword") ||
        type.includes("application/octet-stream") ||
        type.includes("excel") ||
        type.includes("image") ||
        type.includes("powerpoint") ||
        type.includes("pdf") ||
        type.includes("openxmlformats-officedocument") ||
        extension.toLowerCase() === "msg"
      ) {
        if (e.target.files[0].size <= 10240000) {
          setUploadingFile(e.target.files[0]);
          setuploadedFile(e.target.value);
          setIsAttachmentUpload(true);
          //uploadFileOnServer(e.target.files[0]);
        } else {
          addToast("The file size exceeded the maximum limit of 10 MB.", {
            appearance: "failure",
            autoDismiss: true
          });
        }
      } else {
        addToast("Invalid Format or the file is open.", {
          appearance: "error",
          autoDismiss: true
        });
      }
    } else {
      alert("Upload Failed! File Type is not supported!");
      // addToast("File Type is not supported", {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
    //setuploadedFile(e.target.value);
  };

  function buildInputMultiValues(tableData) {

    let inputMultiValues = [];
    const subProcessID = sessionStorage.getItem("ProcessId");
    const workItemID = processingDashboardParam.workItemID;

    let rownumberTracker = {};
    for (let groupName in tableData) {
      if (tableData.hasOwnProperty(groupName)) {
        let group = tableData[groupName];

        for (let row of group) {
          let rowNo = row.tableData.id + 1;
          for (let key in row) {
            if (row.hasOwnProperty(key) && key !== 'tableData') {
              let value = row[key];
              inputMultiValues.push({ subProcessID, workItemID, groupName, mapToField: key, fieldValues: value, rowNo: rowNo })
            }
          }
        }
      }
    }
    return inputMultiValues;
  }

  const selectedSubprocess = processingDashboardParam.selectedSubprocess;
  const nav = processingDashboardParam.nav;
  const showAdvanceSearch = () => {
    setviewSearchModal(true);
    setisDefaultValue(true);
    setisDateField(false);
    
    let workflowactivityName = sessionStorage.getItem("workflowactivityName");
    let condition = sessionStorage.getItem(
      "" + workflowactivityName + "_mapToConditiondtl"
    );
    let mapToCondition = sessionStorage.getItem(
      "" + workflowactivityName + "_conditiondtl"
    );
    // if (condition != null) {
    //   setadvSearchMapToFieldDetails(JSON.parse(condition));
    // } else {
    //   setadvSearchMapToFieldDetails([]);
    // }
    if (mapToCondition != null) {
      setadvSearchDetails(JSON.parse(mapToCondition));
    } else {
      setadvSearchDetails([]);
    }

  };
  const viewSearchCloseAndRest = () => {
    setviewSearchModal(false);
    setadvSearchMapToFieldDetails([]);
    setadvSearchField("");
    setadvSearchMapToField("");
    setadvCondition("");
    //setadvSearchText("");
    setadvSearchCondition("");
    setadvSearchDetails([]);
  };
  const getAdvanceField = (e) => {    
    setnumericOperator(false);
    setisDateField(false);
    setadvSearchText("");
    setisDateTimeFeild(false);
    setadvCondition("");
    setisDefaultValue(true);    

    let datetimeValue = e.target.value.split("/")[0].includes("DateTime");
    let intValue = e.target.value.split("/")[0].includes("Int");
    let DecimalValue = e.target.value.split("/")[0].includes("Decimal");
    let DoubleValue = e.target.value.split("/")[0].includes("Double");
    let SLADueDateValue = e.target.value.split("/")[0].includes("SLADueDate");
    let ScanDateValue = e.target.value.split("/")[0].includes("ScanDate");
    let UploadDateValue = e.target.value.split("/")[0].includes("UploadDate");
    if (intValue > 0 || DecimalValue > 0 || DoubleValue > 0) {
      setnumericOperator(true);
      //setadvCondition("=");
      setisDateTimeFeild(false);
    } else {
      //setadvCondition("Equals");
    }
    if (
      datetimeValue > 0 ||
      SLADueDateValue > 0 ||
      ScanDateValue > 0 ||
      UploadDateValue > 0
    ) {
      setisDateField(true);
      setisDateTimeFeild(true);
      setdisableDate(true);
    }

    // setadvSearchField(e.target.value.split("/")[1]);
    // setadvSearchMapToField(e.target.value.split("/")[0]);
    
    setadvSearchField(e.target.value.split("/")[1].split("$")[0]);
    setadvSearchMapToField(e.target.value.split("/")[0]);
    //setadvSearchMapToField(e.target.value.split("/")[1].split("$")[1].split("~")[0]);
    setadvsubmitandcontinue(
      e.target.value
        .split("/")[1]
        .split("$")[1]
        .split("~")[0]
    );
  };
  const getAdvanceCondition = e => {
    if (
      e.target.value == "Equals" ||
      e.target.value == "Before" ||
      e.target.value == "After"
    ) {
      setdisableDate(false);
    } else {
      setdisableDate(true);
    }
    setisDefaultValue(false);
    setadvCondition(e.target.value);
  };
  const addAdvanceSearchConditions = () => {
    if (
      advSearchField == "Select" ||
      advSearchField == "" ||
      advSearchField == undefined ||
      advCondition == "Select" ||
      advCondition == "" ||
      advCondition == undefined ||
      (isDateField === true && (advCondition == "Equals" || advCondition == "After" || advCondition == "Before") &&  advSearchTextDate == "")
      || (isDateField === false &&  (advSearchText == ""|| advSearchText == undefined ))
    ) {
      alert("Please select category title,operator & keyword."); //addedbyishika
      return false;
    }
    
    let searchText = "";
    setdisableDate(true);
    if (isDateTimeFeild) {
      setadvSearchText(document.getElementById("txtSearchDate").value);
      searchText = document.getElementById("txtSearchDate").value;
    } else if (numericOperator) {
      if (!isNaN(document.getElementById("txtSearchNumber").value)) {
        setadvSearchText(document.getElementById("txtSearchNumber").value);
        searchText = document.getElementById("txtSearchNumber").value;
      } else if (
        document.getElementById("txtSearchNumber").value.includes(",")
      ) {
        if (
          !isNaN(
            document.getElementById("txtSearchNumber").value.replace(/,/g, "")
          )
        ) {
          setadvSearchText(document.getElementById("txtSearchNumber").value);
          searchText = document.getElementById("txtSearchNumber").value;
        } else {
          alert("Please select proper keyword.");
          return false;
        }
      } else {
        alert("Please select proper keyword.");
        return false;
      }
    } else {
      setadvSearchText(document.getElementById("txtSearchText").value);
      searchText = document.getElementById("txtSearchText").value.trim();
    }
    searchText = advSearchText === "" ? advSearchTextDate : advSearchText;
    //For Table Bind
    let maxId = advSearchDetails.reduce((max, item)=> 
      (item.id > max? item.id: max),0); 
    let condition = {
      id: maxId+1,
      searchField: advSearchField, //document.getElementById('ddlCategoryTitle').value,//
      condition: advCondition,
      searchText: searchText, //advSearchText
      searchField2: advsubmitandcontinue,
    };

    //For JSON DATA to share with API
    let mapToCondition = {
      id: maxId+1,
      searchField: advSearchMapToField, //document.getElementById('ddlCategoryTitle').value,
      condition: advCondition,
      searchText: searchText, //advSearchText
      searchField2: advsubmitandcontinue
    };
    setadvSearchDetails(advSearchDetails => [...advSearchDetails, condition]);
  
    setadvSearchMapToFieldDetails(advSearchMapToFieldDetails => [
      ...advSearchMapToFieldDetails,
      mapToCondition
    ]);

    let workflowactivityName = sessionStorage.getItem("workflowactivityName");
    sessionStorage.setItem(
      "" + workflowactivityName + "_mapToConditiondtl",
      JSON.stringify(advSearchMapToFieldDetails)
    );
    sessionStorage.setItem(
      "" + workflowactivityName + "_conditiondtl",
      JSON.stringify(advSearchDetails)
    );

    setadvSearchField("");
    setisDefaultValue(true);
    setadvSearchText("");
  };
  const searchWorkitemSubmit = e => {
    let workflowactivityName = sessionStorage.getItem("workflowactivityName");
    sessionStorage.setItem(
      "" + workflowactivityName + "_mapToConditiondtl",
      JSON.stringify(advSearchMapToFieldDetails)
    );
    sessionStorage.setItem(
      "" + workflowactivityName + "_conditiondtl",
      JSON.stringify(advSearchDetails)
    );

    setviewSearchModal(false);
    
    tableRef.current.onQueryChange();
  };
  const ModeOnChange = e => {
    let IsCustomField = "";
    if (e.target.checked === false) {
      setIsCustomField("0");
      IsCustomField = "0";
      let FieldValues = searchItemFilterFields.filter(
        e => e.workItemFieldID !== "0"
      );
      setWorkFlowWorkItemFields(FieldValues);
    } else {
      setIsCustomField("1");
      IsCustomField = "1";
      setWorkFlowWorkItemFields(searchItemFilterFields);
    }
  };
  const getAdvanceSearchField = () => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      userId: sessionStorage.getItem("GUID"),
      personID: sessionStorage.getItem("PersonID")
    };
    let loginUrl = getAPIURL(REACT_APP_GETWORKFLOWITEMFIELDS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setsearchItemFilterFields(result.data._listWorkItemFields);
          
          let FieldValues = result.data._listWorkItemFields.filter(
            e => e.workItemFieldID !== "0"
          );
          setWorkFlowWorkItemFields(FieldValues);

          setSavedPrefrencesFields(result.data._listGetSavedPrefrenceWIFields);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const getAdvanceText = e => {
    setadvSearchTextDate("");
    setadvSearchText(e.target.value);
  };
  const getAdvanceDateText = e => {
    setadvSearchText("");
    setadvSearchTextDate(e.target.value);
  };
  const getWorkFlowstates = () => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      userId: sessionStorage.getItem("GUID")      
    };
    let loginUrl = getAPIURL(REACT_APP_GETWORKFLOWSTATES);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setWorkFlowStates(result.data._listAllStates);
          for (let i = 0; i < result.data._listAllStates.length; i++) {
            if (result.data._listAllStates[i].nextState == "Client") {
              // console.log(result.data._listAllStates[i].workflowstateid);
              setCurrentStateID_Client(
                result.data._listAllStates[i].workflowstateid
              );
            }
          }
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const getWorkFlowstatus = () => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      userId: sessionStorage.getItem("GUID")
    };
    let loginUrl = getAPIURL(REACT_APP_GETWORKFLOWSTATUS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setWorkFlowStatuses(result.data._listStatus);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const handleApplyUpload = (event, picker) => {
    setuplodadedDateTimeFrom(picker.startDate.format("MM/DD/YYYY HH:mm"));
    setuplodadedDateTimeTo(picker.endDate.format("MM/DD/YYYY HH:mm"));
  };
  const handleApplyActivity = (event, picker) => {
    setactivityDateTimeFrom(picker.startDate.format("MM/DD/YYYY HH:mm"));
    setactivityDateTimeTo(picker.endDate.format("MM/DD/YYYY HH:mm"));
  };
  const getArchive = e => {
    setcheckArchive(!checkArchive);
  };
  const handelSubmit = (e) => {
    const inputMultiValues = buildInputMultiValues(tableData);
    setSubmitDisabled(true);
    set_IsLoading(true);
    if (e != null) {
      e.preventDefault();
    }
    let inputalues = [];
    setIsChecked(false); //added by Santosh
    let isSubmit = true;

    if (JsonValues.length >= 0) {
      JsonValues.forEach(element => {

        for (let i = 0; i < element.screenFields.length; i++) {
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            (element.screenFields[i].dataType === "System.Decimal" ||
              element.screenFields[i].dataType === "System.Double" ||
              element.screenFields[i].dataType === "System.Float")
          ) {
            element.screenFields[i].value = "0.0";
          }
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.Int32"
          ) {
            element.screenFields[i].value = "0";
          }
          if (
            element.screenFields[i].required &&
            (element.screenFields[i].value === "" || element.screenFields[i].value === undefined || element.screenFields[i].value === null) 
            &&
            element.screenFields[0].isMultiValue === false
          ) {
            addToast(element.screenFields[i].labelText + " is required!", {
              appearance: "error",
              autoDismiss: true
            });
            setSubmitandhomeDisabled(false);
            setSubmitAndContinueDisabled(false); //addes by ishika
            setSubmitAndAddBlankItemDisabled(false); //added by ishika
            setSubmitDisabled(false); //added by ishika
            isSubmit = false;
          } else if (
            element.screenFields[i].mapToField.includes("UploadDate")
          ) {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          } else {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          }
        }
        if (element.screenFields[0].isMultiValue === true) {
          const requiredFieldsCount = element.screenFields.filter(x => x.required).length;
          const groupName = element.groupName;
          if (requiredFieldsCount > 0 && (!tableData[groupName] || tableData[groupName].length === 0)) {

            addToast("Add atleast one row in " + element.groupName, {
              appearance: "error",
              autoDismiss: true
            });

            isSubmit = false;
          }
        }
      });



    } else {
      JsonValues.state.regGroup.forEach(element => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            (element.screenFields[i].dataType === "System.Decimal" ||
              element.screenFields[i].dataType === "System.Double" ||
              element.screenFields[i].dataType === "System.Float")
          ) {
            element.screenFields[i].value = "0.0";
          }
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.Int32"
          ) {
            element.screenFields[i].value = "0";
          }
          if (
            element.screenFields[i].required &&
            (element.screenFields[i].value === "" || element.screenFields[i].value === undefined || element.screenFields[i].value === null) 
            &&
            element.screenFields[0].isMultiValue === false
          ) {
            addToast(element.screenFields[i].labelText + " is required!", {
              appearance: "error",
              autoDismiss: true
            });
            setSubmitandhomeDisabled(false);
            setSubmitAndContinueDisabled(false); //addes by ishika
            setSubmitAndAddBlankItemDisabled(false); //added by ishika
            setSubmitDisabled(false); //added by ishika
            isSubmit = false;
          } else if (
            element.screenFields[i].mapToField.includes("UploadDate")
          ) {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          } else {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          }
        }
        if (element.screenFields[0].isMultiValue === true) {
          const requiredFieldsCount = element.screenFields.filter(x => x.required).length;
          const groupName = element.groupName;
          if (requiredFieldsCount > 0 && (!tableData[groupName] || tableData[groupName].length === 0)) {

            addToast("Add atleast one row in " + element.groupName, {
              appearance: "error",
              autoDismiss: true
            });
            isSubmit = false;
          }
        }
      });
    }
    if (statusChange == "") {
      addToast("Status is required!", {
        appearance: "error",
        autoDismiss: true
      });
      setSubmitandhomeDisabled(false);
      setSubmitAndContinueDisabled(false); //addes by ishika
      setSubmitAndAddBlankItemDisabled(false); //added by ishika
      setSubmitDisabled(false); //added by ishika
      isSubmit = false;
    }
    if (ddlWorkItemTypeValue == "Select" || ddlWorkItemTypeValue == "0") {
      addToast("Workitem Type is required!", {
        appearance: "error",
        autoDismiss: true
      });
      setSubmitandhomeDisabled(false);
      setSubmitAndContinueDisabled(false); //addes by ishika
      setSubmitAndAddBlankItemDisabled(false); //added by ishika
      setSubmitDisabled(false); //added by ishika
      isSubmit = false;
    }
    if (
      isReasonshow &&
      statusReasonForSubprocess.length > 0 &&
      statusReason == ""
    ) {
      addToast("Status reason is mandatory for this workflow.", {
        appearance: "error",
        autoDismiss: true
      });
      setSubmitandhomeDisabled(false);
      setSubmitAndContinueDisabled(false); //addes by ishika
      setSubmitAndAddBlankItemDisabled(false); //added by ishika
      setSubmitDisabled(false); //added by ishika
      isSubmit = false;
    }

    if (
      workAllocationMode === "Manual" &&
      statusAssignedTo === "" &&
      employeesForSubprocessActivity.length > 0
    ) {
      addToast("Please select a relevant value from the Assigned To field.", {
        appearance: "error",
        autoDismiss: true
      });
      setSubmitandhomeDisabled(false);
      setSubmitAndContinueDisabled(false); //addes by ishika
      setSubmitAndAddBlankItemDisabled(false); //added by ishika
      setSubmitDisabled(false); //added by ishika
      isSubmit = false;
    }
    if (isComment && statusComment == "") {
      addToast("Status Comment is mandatory for this workflow.", {
        appearance: "error",
        autoDismiss: true
      });
      setSubmitandhomeDisabled(false);
      setSubmitAndContinueDisabled(false); //addes by ishika
      setSubmitAndAddBlankItemDisabled(false); //added by ishika
      setSubmitDisabled(false); //added by ishika
      isSubmit = false;
    }
    // if(statusChange!=""&&statusAssignedTo=="")
    // {
    //   addToast("Assign To is required!", {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });
    //   isSubmit = false;
    // }
    if (isSubmit) {
      let workItemIDInputParam =
        nextWorkItemId == ""
          ? +processingDashboardParam.workItemID
          : nextWorkItemId;


      let body = {
        currentActivityName: hfActivityName,
        workItemType: ddlWorkItemTypeValue,
        //OND Changes Start//
        workflowStateID: processingDashboardParam.isOND
          ? processingDashboardParam.workflowstateIDOND
          : processingDashboardParam.workflowstateID,
        subProcessID: processingDashboardParam.isOND
          ? processingDashboardParam.subprocessIdOND
          : processingDashboardParam.selectedSubprocess,
        //OND Changes End//
        empversionID: sessionStorage.getItem("EmployeeVersionID"),
        intNextStateID: 0,
        //OND Changes Start//
        workItemID: processingDashboardParam.isOND
          ? processingDashboardParam.workitemOND
          : workItemIDInputParam,
        //OND Changes End//
        statusID: +statusChange,
        personID: +sessionStorage.getItem("PersonID"),
        employeeid: +sessionStorage.getItem("PersonID"),
        statusComment: statusComment,
        Internal: isChecked,
        statusReasons: statusReason,
        newErrors: [],
        inputValues: inputalues,
        inputMultiValues: inputMultiValues,
        dashboardFilter: "",
        getNextItemWithDashboardFilter: false,
        xmlSkippedItems: [0],
        assignedEmployeeVersionID: statusAssignedTo,
        assignedActivity: selectedActivityName,
        isSuperUser: true,
        displayCriteria:
          sessionStorage.getItem("WorkitemMode") == null
            ? 0
            : +sessionStorage.getItem("WorkitemMode"),
        fromDate: "2022-07-28T07:24:59",
        toDate: "2022-07-28T07:24:59.606Z",
        selection: "",
        overrideUniqueCheck: sessionStorage.getItem("OverrideUniqueCheck"),
        isDivWorkItemVisible: isDivWorkItemVisible,
        hiddenFieldValWORKITEMTYPE: "WORKITEMTYPEID"
      };
      let loginUrl = getAPIURL(REACT_APP_SUBMITWORKITEM);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {

          if (result.status === 200) {
            backToDashBoard();
            //CloseImageinTab();
            set_IsLoading(false);
          } else if (result.status === 401) {
            //;
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else if (
            result.message != null &&
            result.message.includes("trError Duplicate")
          ) {
            set_IsLoading(false);
            alert("Duplicate WorkItem is not allowed.");
            window.location.reload(true);
          } else if (
            result.message != null &&
            (result.message.includes("trDuplicate workitem") ||
              result.message.includes("workitem already"))
          ) {

            const confirmBox = window.confirm(
              "Workitem already exists.Do you want to proceed?"
            );

            if (confirmBox === true) {

              sessionStorage.setItem("OverrideUniqueCheck", "True");
              //sethdnOverrideUniqueCheck("True");
              handelSubmit(e);
              //sessionStorage.clear();
            }
            set_IsLoading(false);
          } else {
            set_IsLoading(false);
            let errorMessage = result.message;
            addToast(errorMessage, {
              appearance: "info",
              autoDismiss: true
            });
          }
          set_IsLoading(false);
        })
        .catch(error => {
          // if (result.status === 401) {
          ;
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            BodydetailsinLog(error.toString() + " handelSubmit ", body);
            //navigate("/ErrorPage");
            //window.location.href = "/Home";
          }
          //navigate("/");
          //}
          set_IsLoading(false);
        });
    } else {
      set_IsLoading(false);
    }
    set_IsLoading(false);
  };
  const handelSubmitAndHome = (e) => {

    const inputMultiValues = buildInputMultiValues(tableData);
    setSubmitandhomeDisabled(true); //addedbyishika
    set_IsLoading(true);
    if (e != null) {
      e.preventDefault();
    }
    let inputalues = [];
    let isSubmit = true;
    CloseImageinTab();
    if (JsonValues.length >= 0) {
      JsonValues.forEach(element => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            (element.screenFields[i].dataType === "System.Decimal" ||
              element.screenFields[i].dataType === "System.Double" ||
              element.screenFields[i].dataType === "System.Float")
          ) {
            element.screenFields[i].value = "0.0";
          }
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.Int32"
          ) {
            element.screenFields[i].value = "0";
          }
          if (
            element.screenFields[i].required &&
            (element.screenFields[i].value === "" || element.screenFields[i].value === undefined || element.screenFields[i].value === null) 
            &&
            element.screenFields[0].isMultiValue === false
          ) {
            addToast(element.screenFields[i].labelText + " is required!", {
              appearance: "error",
              autoDismiss: true
            });
            setSubmitandhomeDisabled(false);
            setSubmitAndContinueDisabled(false); //addes by ishika
            setSubmitAndAddBlankItemDisabled(false); //added by ishika
            setSubmitDisabled(false); //added by ishika
            isSubmit = false;
          } else if (
            element.screenFields[i].mapToField.includes("UploadDate")
          ) {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          } else {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          }
        }
        if (element.screenFields[0].isMultiValue === true) {
          const requiredFieldsCount = element.screenFields.filter(x => x.required).length;
          const groupName = element.groupName;
          if (requiredFieldsCount > 0 && (!tableData[groupName] || tableData[groupName].length === 0)) {

            addToast("Add atleast one row in " + element.groupName, {
              appearance: "error",
              autoDismiss: true
            });
            isSubmit = false;
          }
        }
      });
    } else {
      JsonValues.state.regGroup.forEach(element => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            (element.screenFields[i].dataType === "System.Decimal" ||
              element.screenFields[i].dataType === "System.Double" ||
              element.screenFields[i].dataType === "System.Float")
          ) {
            element.screenFields[i].value = "0.0";
          }
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.Int32"
          ) {
            element.screenFields[i].value = "0";
          }
          if (
            element.screenFields[i].required &&
            (element.screenFields[i].value === "" || element.screenFields[i].value === undefined || element.screenFields[i].value === null) 
            &&
            element.screenFields[0].isMultiValue === false
          ) {
            addToast(element.screenFields[i].labelText + " is required!", {
              appearance: "error",
              autoDismiss: true
            });
            setSubmitandhomeDisabled(false);
            setSubmitAndContinueDisabled(false); //addes by ishika
            setSubmitAndAddBlankItemDisabled(false); //added by ishika
            setSubmitDisabled(false); //added by ishika
            isSubmit = false;
          } else if (
            element.screenFields[i].mapToField.includes("UploadDate")
          ) {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          } else {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          }
        }
        if (element.screenFields[0].isMultiValue === true) {
          const requiredFieldsCount = element.screenFields.filter(x => x.required).length;
          const groupName = element.groupName;
          if (requiredFieldsCount > 0 && (!tableData[groupName] || tableData[groupName].length === 0)) {

            addToast("Add atleast one row in " + element.groupName, {
              appearance: "error",
              autoDismiss: true
            });
            isSubmit = false;
          }
        }
      });
    }
    if (statusChange == "") {
      addToast("Status is required!", {
        appearance: "error",
        autoDismiss: true
      });

      setSubmitandhomeDisabled(false);
      setSubmitAndContinueDisabled(false); //addes by ishika
      setSubmitAndAddBlankItemDisabled(false); //added by ishika
      setSubmitDisabled(false); //added by ishika
      isSubmit = false;
    }
    if (ddlWorkItemTypeValue == "Select" || ddlWorkItemTypeValue == "0") {
      addToast("Workitem Type is required!", {
        appearance: "error",
        autoDismiss: true
      });
      setSubmitandhomeDisabled(false);
      setSubmitAndContinueDisabled(false); //addes by ishika
      setSubmitAndAddBlankItemDisabled(false); //added by ishika
      setSubmitDisabled(false); //added by ishika
      isSubmit = false;
    }
    if (
      isReasonshow &&
      statusReasonForSubprocess.length > 0 &&
      statusReason == ""
    ) {
      addToast("Status reason is mandatory for this workflow.", {
        appearance: "error",
        autoDismiss: true
      });
      setSubmitandhomeDisabled(false);
      setSubmitAndContinueDisabled(false); //addes by ishika
      setSubmitAndAddBlankItemDisabled(false); //added by ishika
      setSubmitDisabled(false); //added by ishika
      isSubmit = false;
    }

    if (
      workAllocationMode === "Manual" &&
      statusAssignedTo === "" &&
      employeesForSubprocessActivity.length > 0
    ) {
      addToast("Please select a relevant value from the Assigned To field.", {
        appearance: "error",
        autoDismiss: true
      });
      setSubmitandhomeDisabled(false);
      setSubmitAndContinueDisabled(false); //addes by ishika
      setSubmitAndAddBlankItemDisabled(false); //added by ishika
      setSubmitDisabled(false); //added by ishika
      isSubmit = false;
    }
    if (isComment && statusComment == "") {
      addToast("Status Comment is mandatory for this workflow.", {
        appearance: "error",
        autoDismiss: true
      });
      setSubmitandhomeDisabled(false);
      setSubmitAndContinueDisabled(false); //addes by ishika
      setSubmitAndAddBlankItemDisabled(false); //added by ishika
      setSubmitDisabled(false); //added by ishika
      isSubmit = false;
    }
    // if(statusChange!=""&&statusAssignedTo=="")
    // {
    //   addToast("Assign To is required!", {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });
    //   isSubmit = false;
    // }
    if (isSubmit) {
      let workItemIDInputParam =
        nextWorkItemId == ""
          ? +processingDashboardParam.workItemID
          : nextWorkItemId;
      let body = {
        currentActivityName: hfActivityName,
        workItemType: ddlWorkItemTypeValue,
        workflowStateID: processingDashboardParam.workflowstateID,
        subProcessID: processingDashboardParam.selectedSubprocess,
        empversionID: sessionStorage.getItem("EmployeeVersionID"),
        intNextStateID: 0,
        workItemID: workItemIDInputParam,
        statusID: +statusChange,
        personID: +sessionStorage.getItem("PersonID"),
        employeeid: +sessionStorage.getItem("PersonID"),
        statusComment: statusComment,
        Internal: isChecked,
        statusReasons: statusReason,
        newErrors: [],
        inputValues: inputalues,
        inputMultiValues: inputMultiValues,
        dashboardFilter: "",
        getNextItemWithDashboardFilter: false,
        xmlSkippedItems: [0],
        assignedEmployeeVersionID: statusAssignedTo,
        assignedActivity: selectedActivityName,
        isSuperUser: true,
        displayCriteria:
          sessionStorage.getItem("WorkitemMode") == null
            ? 0
            : +sessionStorage.getItem("WorkitemMode"),
        fromDate: "2022-07-28T07:24:59",
        toDate: "2022-07-28T07:24:59.606Z",
        selection: "",
        overrideUniqueCheck: sessionStorage.getItem("OverrideUniqueCheck"),
        isDivWorkItemVisible: isDivWorkItemVisible,
        hiddenFieldValWORKITEMTYPE: "WORKITEMTYPEID"
      };
      let loginUrl = getAPIURL(REACT_APP_SUBMITWORKITEM);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {

          if (result.status === 200) {
            navigate("/Home");
            set_IsLoading(false);
          } else if (result.status === 401) {
            //;
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else if (
            result.message != null &&
            result.message.includes("trError Duplicate")
          ) {
            set_IsLoading(false);
            alert("Duplicate WorkItem is not allowed.");
            window.location.reload(true);
          } else if (
            result.message != null &&
            (result.message.includes("trDuplicate workitem") ||
              result.message.includes("workitem already"))
          ) {

            const confirmBox = window.confirm(
              "Workitem already exists.Do you want to proceed?"
            );

            if (confirmBox === true) {

              sessionStorage.setItem("OverrideUniqueCheck", "True");
              //sethdnOverrideUniqueCheck("True");
              handelSubmitAndHome(e);
              //sessionStorage.clear();
            }
            set_IsLoading(false);
          } else {
            set_IsLoading(false);
            let errorMessage = result.message;
            addToast(errorMessage, {
              appearance: "info",
              autoDismiss: true
            });
          }
          set_IsLoading(false);
        })
        .catch(error => {
          // if (result.status === 401) {
          ;
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            BodydetailsinLog(error.toString() + " handelSubmitAndHome ", body);
            //navigate("/ErrorPage");
            //window.location.href = "/Home";
          }
          //navigate("/");
          //}
          set_IsLoading(false);
        });
    } else {
      set_IsLoading(false);
    }
    set_IsLoading(false);
  };
  const handelSave = e => {

    const inputMultiValues = buildInputMultiValues(tableData);
    set_IsLoading(true);
    e.preventDefault();
    let inputalues = [];
    let isSubmit = true;
    let x = xtype("55.00");
    CloseImageinTab();
    if (JsonValues.length >= 0) {
      JsonValues.forEach(element => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            (element.screenFields[i].dataType === "System.Decimal" ||
              element.screenFields[i].dataType === "System.Double" ||
              element.screenFields[i].dataType === "System.Float")
          ) {
            element.screenFields[i].value = "0.0";
          }
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.Int32"
          ) {
            element.screenFields[i].value = "0";
          }
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.DateTime"
          ) {
            element.screenFields[i].value = null;
          }
          // if (
          //   element.screenFields[i].required &&
          //   element.screenFields[i].value === "" &&
          //   element.screenFields[0].isMultiValue === false
          // ) {
          //   addToast(element.screenFields[i].labelText + " is required!", {
          //     appearance: "error",
          //     autoDismiss: true
          //   });
          //   setSubmitandhomeDisabled(false);
          //   setSubmitAndContinueDisabled(false); //addes by ishika
          //   setSubmitAndAddBlankItemDisabled(false); //added by ishika
          //   setSubmitDisabled(false); //added by ishika
          //   isSubmit = false;
          // } else 
          if (
            element.screenFields[i].mapToField.includes("UploadDate")
          ) {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          } else {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          }
        }
        // if (element.screenFields[0].isMultiValue === true) {
        //   const requiredFieldsCount = element.screenFields.filter(x => x.required).length;
        //   const groupName = element.groupName;
        //   if (requiredFieldsCount > 0 && (!tableData[groupName] || tableData[groupName].length === 0)) {

        //     addToast("Add atleast one row in " + element.groupName, {
        //       appearance: "error",
        //       autoDismiss: true
        //     });
        //     isSubmit= false;
        // }
        // }
      });
    } else {
      JsonValues.state.regGroup.forEach(element => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            (element.screenFields[i].dataType === "System.Decimal" ||
              element.screenFields[i].dataType === "System.Double" ||
              element.screenFields[i].dataType === "System.Float")
          ) {
            element.screenFields[i].value = "0.0";
          }
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.Int32"
          ) {
            element.screenFields[i].value = "0";
          }
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.DateTime"
          ) {
            element.screenFields[i].value = null;
          }
          // if (
          //   element.screenFields[i].required &&
          //   element.screenFields[i].value === "" &&
          //   element.screenFields[0].isMultiValue === false
          // ) {
          //   addToast(element.screenFields[i].labelText + " is required!", {
          //     appearance: "error",
          //     autoDismiss: true
          //   });
          //   setSubmitandhomeDisabled(false);
          //   setSubmitAndContinueDisabled(false); //addes by ishika
          //   setSubmitAndAddBlankItemDisabled(false); //added by ishika
          //   setSubmitDisabled(false); //added by ishika
          //   isSubmit = false;
          // } else 
          if (
            element.screenFields[i].mapToField.includes("UploadDate")
          ) {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          } else {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          }
        }
        // if (element.screenFields[0].isMultiValue === true) {
        //   const requiredFieldsCount = element.screenFields.filter(x => x.required).length;
        //   const groupName = element.groupName;
        //   if (requiredFieldsCount > 0 && (!tableData[groupName] || tableData[groupName].length === 0)) {

        //     addToast("Add atleast one row in " + element.groupName, {
        //       appearance: "error",
        //       autoDismiss: true
        //     });
        //     isSubmit= false;
        // }
        // }
      });
    }
    // if (statusChange == "") {
    //   addToast("Status is required!", {
    //     appearance: "error",
    //     autoDismiss: true,
    //   });
    //   isSubmit = false;
    // }

    // if (ddlWorkItemTypeValue == "Select" || ddlWorkItemTypeValue == "0") {
    //   addToast("Workitem Type is required!", {
    //     appearance: "error",
    //     autoDismiss: true
    //   });
    //   setSubmitandhomeDisabled(false);
    //   setSubmitAndContinueDisabled(false); //addes by ishika
    //   setSubmitAndAddBlankItemDisabled(false); //added by ishika
    //   setSubmitDisabled(false); //added by ishika
    //   isSubmit = false;
    // }
    // if (
    //   isReasonshow &&
    //   statusReasonForSubprocess.length > 0 &&
    //   statusReason == ""
    // ) {
    //   addToast("Status reason is mandatory for this workflow.", {
    //     appearance: "error",
    //     autoDismiss: true
    //   });
    //   setSubmitandhomeDisabled(false);
    //   setSubmitAndContinueDisabled(false); //addes by ishika
    //   setSubmitAndAddBlankItemDisabled(false); //added by ishika
    //   setSubmitDisabled(false); //added by ishika
    //   isSubmit = false;
    // }

    // if (
    //   workAllocationMode === "Manual" &&
    //   statusAssignedTo === "" &&
    //   employeesForSubprocessActivity.length > 0
    // ) {
    //   addToast("Please select a relevant value from the Assigned To field.", {
    //     appearance: "error",
    //     autoDismiss: true
    //   });
    //   setSubmitandhomeDisabled(false);
    //   setSubmitAndContinueDisabled(false); //addes by ishika
    //   setSubmitAndAddBlankItemDisabled(false); //added by ishika
    //   setSubmitDisabled(false); //added by ishika
    //   isSubmit = false;
    // }
    // if (isComment && statusComment == "") {
    //   addToast("Status Comment is mandatory for this workflow.", {
    //     appearance: "error",
    //     autoDismiss: true
    //   });
    //   setSubmitandhomeDisabled(false);
    //   setSubmitAndContinueDisabled(false); //addes by ishika
    //   setSubmitAndAddBlankItemDisabled(false); //added by ishika
    //   setSubmitDisabled(false); //added by ishika
    //   isSubmit = false;
    // }
    if (isSubmit) {
      let workItemIDInputParam =
        nextWorkItemId == ""
          ? +processingDashboardParam.workItemID
          : nextWorkItemId;
      let body = {
        /***********OND Changes start***************/
        activityID: processingDashboardParam.isOND
          ? processingDashboardParam.activityIdOND
          : +processingDashboardParam.activityId,
        workItemID: processingDashboardParam.isOND
          ? processingDashboardParam.workitemOND
          : +workItemIDInputParam,
        /***********OND Changes end********/
        personID: +sessionStorage.getItem("PersonID"),
        isddlWorkItemTypeVisible: true,
        workItemType: ddlWorkItemTypeValue,
        hdnMapToField: "",
        hdnMapToTable: "",
        /***********OND Changes start***************/
        workflowStateID: processingDashboardParam.isOND
          ? processingDashboardParam.workflowstateIDOND
          : +processingDashboardParam.nav[0].workflowStateID,
        /***********OND Changes end***************/
        isdivWorkItemTypeVisible: isDivWorkItemVisible,
        ischeckReadonly: true,
        inputList: inputalues,
        inputMultiValues: inputMultiValues,
      };
      let loginUrl = getAPIURL(REACT_APP_SAVEWORKITEM);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            set_IsLoading(false);
            //backToDashBoard()
            addToast("WorkItem Data Saved Successfully", {
              appearance: "success",
              autoDismiss: true
            });
          } else if (result.status === 401) {
            // ;
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Error occured!", {
              appearance: "error",
              autoDismiss: true
            });
            set_IsLoading(false);
          }
          set_IsLoading(false);
        })
        .catch(error => {
          //if (result.status === 401) {
          ;
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            BodydetailsinLog(error.toString() + " handelSave ", body);
            //navigate("/ErrorPage");
            //window.location.href = "/Home";
          }
          //navigate("/");
          //}
          set_IsLoading(false);
        });
    } else {
      set_IsLoading(false);
    }
    set_IsLoading(false);
  };
  const handelSubmitAndContinue = (e) => {

    const inputMultiValues = buildInputMultiValues(tableData);
    setSubmitAndContinueDisabled(true);
    set_IsLoading(true);
    if (e != null) {
      e.preventDefault();
    }
    let inputalues = [];
    let isSubmit = true;
    setstatusComment("");
    CloseImageinTab();
    try {
    if (JsonValues.length >= 0) {
      JsonValues.forEach(element => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            (element.screenFields[i].dataType === "System.Decimal" ||
              element.screenFields[i].dataType === "System.Double" ||
              element.screenFields[i].dataType === "System.Float")
          ) {
            element.screenFields[i].value = "0.0";
          }
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.Int32"
          ) {
            element.screenFields[i].value = "0";
          }
          if (
            element.screenFields[i].required &&
            (element.screenFields[i].value === "" || element.screenFields[i].value === undefined || element.screenFields[i].value === null) 
            &&
            element.screenFields[0].isMultiValue === false
          ) {
            addToast(element.screenFields[i].labelText + " is required!", {
              appearance: "error",
              autoDismiss: true
            });

              setSubmitandhomeDisabled(false);
              setSubmitAndContinueDisabled(false); //addes by ishika
              setSubmitAndAddBlankItemDisabled(false); //added by ishika
              setSubmitDisabled(false); //added by ishika
              isSubmit = false;
            } else if (
              element.screenFields[i].mapToField.includes("UploadDate")
            ) {
              const input = {
                mapToTable: element.screenFields[i].mapToTable,
                mapToField: element.screenFields[i].mapToField,
                value: element.screenFields[i].value
              };
              inputalues.push(input);
            } else {
              const input = {
                mapToTable: element.screenFields[i].mapToTable,
                mapToField: element.screenFields[i].mapToField,
                value: element.screenFields[i].value
              };
              inputalues.push(input);
            }
          }
          if (element.screenFields[0].isMultiValue === true) {
            const requiredFieldsCount = element.screenFields.filter(x => x.required).length;
            const groupName = element.groupName;
            if (requiredFieldsCount > 0 && (!tableData[groupName] || tableData[groupName].length === 0)) {

              addToast("Add atleast one row in " + element.groupName, {
                appearance: "error",
                autoDismiss: true
              });
              isSubmit = false;
            }
          }
        });
      } else {

        JsonValues.state.regGroup.forEach(element => {
          for (let i = 0; i < element.screenFields.length; i++) {
            if (!element.screenFields[i].required &&
              element.screenFields[i].value === "" &&
              (element.screenFields[i].dataType === "System.Decimal" ||
                element.screenFields[i].dataType === "System.Double" ||
                element.screenFields[i].dataType === "System.Float")
            ) {
              element.screenFields[i].value = "0.0";
            }
            if (!element.screenFields[i].required &&
              element.screenFields[i].value === "" &&
              element.screenFields[i].dataType === "System.Int32"
            ) {
              element.screenFields[i].value = "0";
            }
            if (
              element.screenFields[i].required &&
              (element.screenFields[i].value === "" || element.screenFields[i].value === undefined || element.screenFields[i].value === null) 
              &&
                element.screenFields[0].isMultiValue === false
            ) {
              addToast(element.screenFields[i].labelText + " is required!", {
                appearance: "error",
                autoDismiss: true
              });
              setSubmitandhomeDisabled(false);
              setSubmitAndContinueDisabled(false); //addes by ishika
              setSubmitAndAddBlankItemDisabled(false); //added by ishika
              setSubmitDisabled(false); //added by ishika
              isSubmit = false;
            } else if (
              element.screenFields[i].mapToField.includes("UploadDate")
            ) {
              const input = {
                mapToTable: element.screenFields[i].mapToTable,
                mapToField: element.screenFields[i].mapToField,
                value: element.screenFields[i].value
              };
              inputalues.push(input);
            } else {
              const input = {
                mapToTable: element.screenFields[i].mapToTable,
                mapToField: element.screenFields[i].mapToField,
                value: element.screenFields[i].value
              };
              inputalues.push(input);
            }
          }
          if (element.screenFields[0].isMultiValue === true) {
            const requiredFieldsCount = element.screenFields.filter(x => x.required).length;
            const groupName = element.groupName;
            if (requiredFieldsCount > 0 && (!tableData[groupName] || tableData[groupName].length === 0)) {

              addToast("Add atleast one row in " + element.groupName, {
                appearance: "error",
                autoDismiss: true
              });
              isSubmit = false;
            }
          }
        });
      }

      if (statusChange == "") {
        addToast("Status is required!", {
          appearance: "error",
          autoDismiss: true
        });
        setSubmitandhomeDisabled(false);
        setSubmitAndContinueDisabled(false); //addes by ishika
        setSubmitAndAddBlankItemDisabled(false); //added by ishika
        setSubmitDisabled(false); //added by ishika
        isSubmit = false;
      }

      if (ddlWorkItemTypeValue == "Select" || ddlWorkItemTypeValue == "0") {
        addToast("Workitem Type is required!", {
          appearance: "error",
          autoDismiss: true
        });
        setSubmitandhomeDisabled(false);
        setSubmitAndContinueDisabled(false); //addes by ishika
        setSubmitAndAddBlankItemDisabled(false); //added by ishika
        setSubmitDisabled(false); //added by ishika
        isSubmit = false;
      }

      if (
        isReasonshow &&
        statusReasonForSubprocess.length > 0 &&
        statusReason == ""
      ) {
        addToast("Status reason is mandatory for this workflow.", {
          appearance: "error",
          autoDismiss: true
        });
        setSubmitandhomeDisabled(false);
        setSubmitAndContinueDisabled(false); //addes by ishika
        setSubmitAndAddBlankItemDisabled(false); //added by ishika
        setSubmitDisabled(false); //added by ishika
        isSubmit = false;
      }

      if (
        workAllocationMode === "Manual" &&
        statusAssignedTo === "" &&
        employeesForSubprocessActivity.length > 0
      ) {
        addToast("Please select a relevant value from the Assigned To field.", {
          appearance: "error",
          autoDismiss: true
        });
        setSubmitandhomeDisabled(false);
        setSubmitAndContinueDisabled(false); //addes by ishika
        setSubmitAndAddBlankItemDisabled(false); //added by ishika
        setSubmitDisabled(false); //added by ishika
        isSubmit = false;
      }
      if (isComment && statusComment == "") {
        addToast("Status Comment is mandatory for this workflow.", {
          appearance: "error",
          autoDismiss: true
        });
        setSubmitandhomeDisabled(false);
        setSubmitAndContinueDisabled(false); //addes by ishika
        setSubmitAndAddBlankItemDisabled(false); //added by ishika
        setSubmitDisabled(false); //added by ishika
        isSubmit = false;
      }

      if (isSubmit) {
        let workItemIDInputParam =
          nextWorkItemId == ""
            ? +processingDashboardParam.workItemID
            : nextWorkItemId;

        // let dashFilter = sessionStorage.getItem("filtercriteria");
        // if (
        //   sessionStorage.getItem("filtercriteria") == "" ||
        //   sessionStorage.getItem("filtercriteria") == null
        // )
        //   dashFilter = "";
        // else dashFilter = " and " + sessionStorage.getItem("filtercriteria");

        let dashFilter2 = sessionStorage.getItem("filtercriteria2");
        if (
          sessionStorage.getItem("filtercriteria2") == "" ||
          sessionStorage.getItem("filtercriteria2") == null
        )
          dashFilter2 = "";
        else dashFilter2 = " and " + sessionStorage.getItem("filtercriteria2");

        let sortfieldlist = [];
        if (sessionStorage.getItem("OrderByMaptoFeildName") != null) {
          const input = {
            sortfield: sessionStorage.getItem("OrderByMaptoFeildName")
          };
          sortfieldlist.push(input);
        }
        // let SkippedWorkItemID =[];
        // if(sessionStorage.getItem("SkippedIDs")!= null){
        //   const input =
        //     sessionStorage.getItem("SkippedIDs")
        //   ;
        //   SkippedWorkItemID.push(input);
        // }
        let objSkippedIDs = [];

        if (
          SelectedStatusName.toString()
            .toLowerCase()
            .includes("work in progress")
        ) {
          if (
            sessionStorage.getItem("SkippedIDs") == null ||
            sessionStorage.getItem("SkippedIDs") == ""
          ) {
            const input = {
              sortWorkItem: workItemIDInputParam
            };
            objSkippedIDs.push(input);
            sessionStorage.setItem("SkippedIDs", JSON.stringify(objSkippedIDs));
          } else {
            objSkippedIDs = JSON.parse(sessionStorage.getItem("SkippedIDs"));
            const input = {
              sortWorkItem: workItemIDInputParam
            };
            objSkippedIDs.push(input);
            sessionStorage.setItem("SkippedIDs", JSON.stringify(objSkippedIDs));
          }
        } else {
          if (
            sessionStorage.getItem("SkippedIDs") == null ||
            sessionStorage.getItem("SkippedIDs") == ""
          ) {
            sessionStorage.setItem("SkippedIDs", JSON.stringify(objSkippedIDs));
            objSkippedIDs = JSON.parse(sessionStorage.getItem("SkippedIDs"));
          } else {
            objSkippedIDs = JSON.parse(sessionStorage.getItem("SkippedIDs"));
            sessionStorage.setItem("SkippedIDs", JSON.stringify(objSkippedIDs));
          }
        }
        // let SkipWorkItemIDs =[];
        // objSkippedIDs.forEach((e)=>{SkipWorkItemIDs = e.sortWorkItem});
        for (let i = 0; i < objSkippedIDs.length; i++) {
          objSkippedIDs[i] = objSkippedIDs[i].sortWorkItem;
        }

        //let sortfieldlist=sortfield

        let body = {
          currentActivityName: hfActivityName,
          workItemType: ddlWorkItemTypeValue,
          workflowStateID: processingDashboardParam.workflowstateID,
          subProcessID: processingDashboardParam.selectedSubprocess,
          empversionID: sessionStorage.getItem("EmployeeVersionID"),
          intNextStateID: 0,
          workItemID: workItemIDInputParam,
          statusID: +statusChange,
          personID: +sessionStorage.getItem("PersonID"),
          employeeid: +sessionStorage.getItem("PersonID"),
          statusComment: statusComment,
          Internal: isChecked,
          statusReasons: statusReason,
          newErrors: [],
          inputValues: inputalues,
          inputMultiValues: inputMultiValues,
          //dashboardFilter: dashFilter,
          dashboardFilter: dashFilter2, //"",
          getNextItemWithDashboardFilter: true,
          //xmlSkippedItems: [sessionStorage.getItem("SkippedIDs")],
          //xmlSkippedItems: [0,1],
          //xmlSkippedItems: [JSON.parse(sessionStorage.getItem("SkippedIDs"))],
          //xmlSkippedItems:[JSON.parse(sessionStorage.getItem("SkippedIDs"))[0].sortWorkItem],
          xmlSkippedItems: objSkippedIDs,
          assignedEmployeeVersionID: statusAssignedTo,
          assignedActivity: selectedActivityName,
          isSuperUser: true,
          displayCriteria:
            sessionStorage.getItem("WorkitemMode") == null
              ? 0
              : +sessionStorage.getItem("WorkitemMode"),
          fromDate: "2022-07-28T07:24:59",
          toDate: "2022-07-28T07:24:59.606Z",
          selection: "",
          overrideUniqueCheck: sessionStorage.getItem("OverrideUniqueCheck"),
          isDivWorkItemVisible: isDivWorkItemVisible,
          hiddenFieldValWORKITEMTYPE: "WORKITEMTYPEID",
          sortfieldlist: sortfieldlist,
          SortingOrder:
            sessionStorage.getItem("SortingOrder") == null
              ? ""
              : sessionStorage.getItem("SortingOrder")
        };

        let loginUrl = getAPIURL(REACT_APP_SUBMITANDCONTINUEWORKITEM);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization:
              "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body)
        });
        fetch(req)
          .then(res => res.json())
          .then(result => {
            if (result.status === 200) {
              if (result.data != undefined) {
                if (result.data._reponsedataOnContinue[0] != undefined) {
                  setnextWorkItemId(
                    result.data._reponsedataOnContinue[0].workItemId
                  );
                  setworkitem_Id(
                    result.data._reponsedataOnContinue[0].workItemId
                  );
                  sessionStorage.setItem(
                    "workItemIDInputParam",
                    result.data._reponsedataOnContinue[0].workItemId
                  );

                  // addToast("Details saved Successfully", {
                  addToast(
                    "WorkitemId " +
                    workItemIDInputParam +
                    " saved successfully. This new workitem is picked from the list of workitems that are assigned to you.",
                    {
                      appearance: "success",
                      autoDismiss: true,
                    }
                  );
                  getDetailsOfNextWorkId(
                    result.data._reponsedataOnContinue[0].workItemId
                  );
                  setUpdateTimerToZero(true);
                  UpdateTimerToZeroRef.current = true;
                  countdownTimer();
                  var HIGSubProcessId = REACT_APP_HIGSubProcessId;
                  var HIGSubProcessArray = HIGSubProcessId.split(",");
                  if(HIGSubProcessArray.includes(sessionStorage.getItem("ProcessId")))
                  {
                    if(auditmodeRef.current != null && auditRef.current != null)
                      {
                        auditmodeRef.current.classList.toggle("show-hide");
                        auditRef.current.classList.toggle("active");
                        auditRef.current.classList.remove("show-hide");
                      } 
                  }
                } else {
                  backToDashBoard();
                }
              } else {
                addToast("Something went wrong!", {
                  appearance: "error",
                  autoDismiss: true
                });
                setJsonValues([]);
                setregGroup([]);
                setworkitem_Id(0);
              }
              set_IsLoading(false);
            } else if (result.status === 500) {
              // set_IsLoading(false);
              // let errorMessage = result.Message;
              // addToast(errorMessage, {
              //   appearance: "info",
              //   autoDismiss: true
              // });

              if (
                result.message != null &&
                result.message.includes("trError Duplicate")
              ) {
                set_IsLoading(false);
                alert("Duplicate WorkItem is not allowed.");
                window.location.reload(true);
              } else if (
                result.message != null &&
                (result.message.includes("trDuplicate workitem") ||
                  result.message.includes("workitem already"))
              ) {
                set_IsLoading(false);

                const confirmBox = window.confirm(
                  "Workitem already exists.Do you want to proceed?"
                );

                if (confirmBox === true) {

                  sessionStorage.setItem("OverrideUniqueCheck", "True");
                  //sethdnOverrideUniqueCheck("True");
                  handelSubmitAndContinue(e);
                  //sessionStorage.clear();
                }
              } else {
                set_IsLoading(false);
                let errorMessage = result.message;
                addToast(errorMessage, {
                  appearance: "info",
                  autoDismiss: true,
                });
                setJsonValues([]);
                setregGroup([]);
                setworkitem_Id(0);
              }
            } else if (result.status === 401) {
              //;
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            } else {
              addToast("Error occured!", {
                appearance: "error",
                autoDismiss: true
              });
              set_IsLoading(false);
              setJsonValues([]);
              setregGroup([]);
              setworkitem_Id(0);
            }
            set_IsLoading(false);
          })
          .catch(error => {
            //if (result.status === 401) {
            ;
            if (
              error.name == "TypeError" &&
              error.message == "Failed to fetch"
            ) {
              BodydetailsinLog(
                error.toString() + " handelSubmitAndContinue ",
                body
              );
              //navigate("/ErrorPage");
              //window.location.href = "/Home";
            }
            //navigate("/");
            // }
            set_IsLoading(false);
          });
      } else {
        set_IsLoading(false);
      }
      set_IsLoading(false);
    } catch (ex) {
      set_IsLoading(false);
      addToast("Error occured!", {
        appearance: "error",
        autoDismiss: true
      });
    }
    finally {
      setSubmitAndContinueDisabled(false);
    }
    set_IsLoading(false);
  };

  const handelSubmitAndAddBlankWorkitem = (e) => {

    const inputMultiValues = buildInputMultiValues(tableData);
    setSubmitAndAddBlankItemDisabled(true);
    set_IsLoading(true);

    if (e != null) {
      e.preventDefault();
    }
    let inputalues = [];
    let isSubmit = true;
    setstatusComment("");
    CloseImageinTab();
    if (JsonValues.length >= 0) {
      JsonValues.forEach(element => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            (element.screenFields[i].dataType === "System.Decimal" ||
              element.screenFields[i].dataType === "System.Double" ||
              element.screenFields[i].dataType === "System.Float")
          ) {
            element.screenFields[i].value = "0.0";
          }
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.Int32"
          ) {
            element.screenFields[i].value = "0";
          }
          if (
            element.screenFields[i].required &&
            (element.screenFields[i].value === "" || element.screenFields[i].value === undefined || element.screenFields[i].value === null) 
            &&
            element.screenFields[0].isMultiValue === false
          ) {
            addToast(element.screenFields[i].labelText + " is required!", {
              appearance: "error",
              autoDismiss: true
            });
            setSubmitandhomeDisabled(false);
            setSubmitAndContinueDisabled(false); //addes by ishika
            setSubmitAndAddBlankItemDisabled(false); //added by ishika
            setSubmitDisabled(false); //added by ishika
            isSubmit = false;
          } else if (
            element.screenFields[i].mapToField.includes("UploadDate")
          ) {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          } else {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          }
        }
        if (element.screenFields[0].isMultiValue === true) {
          const requiredFieldsCount = element.screenFields.filter(x => x.required).length;
          const groupName = element.groupName;
          if (requiredFieldsCount > 0 && (!tableData[groupName] || tableData[groupName].length === 0)) {

            addToast("Add atleast one row in " + element.groupName, {
              appearance: "error",
              autoDismiss: true
            });
            isSubmit = false;
          }
        }
      });
    } else {
      JsonValues.state.regGroup.forEach(element => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            (element.screenFields[i].dataType === "System.Decimal" ||
              element.screenFields[i].dataType === "System.Double" ||
              element.screenFields[i].dataType === "System.Float")
          ) {
            element.screenFields[i].value = "0.0";
          }
          if (!element.screenFields[i].required &&
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.Int32"
          ) {
            element.screenFields[i].value = "0";
          }
          if (
            element.screenFields[i].required &&
            (element.screenFields[i].value === "" || element.screenFields[i].value === undefined || element.screenFields[i].value === null) 
            &&
            element.screenFields[0].isMultiValue === false
          ) {
            addToast(element.screenFields[i].labelText + " is required!", {
              appearance: "error",
              autoDismiss: true
            });
            setSubmitandhomeDisabled(false);
            setSubmitAndContinueDisabled(false); //addes by ishika
            setSubmitAndAddBlankItemDisabled(false); //added by ishika
            setSubmitDisabled(false); //added by ishika
            isSubmit = false;
          } else if (
            element.screenFields[i].mapToField.includes("UploadDate")
          ) {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          } else {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value
            };
            inputalues.push(input);
          }
        }
        if (element.screenFields[0].isMultiValue === true) {
          const requiredFieldsCount = element.screenFields.filter(x => x.required).length;
          const groupName = element.groupName;
          if (requiredFieldsCount > 0 && (!tableData[groupName] || tableData[groupName].length === 0)) {

            addToast("Add atleast one row in " + element.groupName, {
              appearance: "error",
              autoDismiss: true
            });
            isSubmit = false;
          }
        }
      });
    }

    if (statusChange == "") {
      addToast("Status is required!", {
        appearance: "error",
        autoDismiss: true
      });

      setSubmitandhomeDisabled(false);
      setSubmitAndContinueDisabled(false); //addes by ishika
      setSubmitAndAddBlankItemDisabled(false); //added by ishika
      setSubmitDisabled(false); //added by ishika
      isSubmit = false;
    }
    if (
      isReasonshow &&
      statusReasonForSubprocess.length > 0 &&
      statusReason == ""
    ) {
      addToast("Status reason is mandatory for this workflow.", {
        appearance: "error",
        autoDismiss: true
      });
      setSubmitandhomeDisabled(false);
      setSubmitAndContinueDisabled(false); //addes by ishika
      setSubmitAndAddBlankItemDisabled(false); //added by ishika
      setSubmitDisabled(false); //added by ishika
      isSubmit = false;
    }

    if (
      workAllocationMode === "Manual" &&
      statusAssignedTo === "" &&
      employeesForSubprocessActivity.length > 0
    ) {
      addToast("Please select a relevant value from the Assigned To field.", {
        appearance: "error",
        autoDismiss: true
      });
      setSubmitandhomeDisabled(false);
      setSubmitAndContinueDisabled(false); //addes by ishika
      setSubmitAndAddBlankItemDisabled(false); //added by ishika
      setSubmitDisabled(false); //added by ishika
      isSubmit = false;
    }
    if (isComment && statusComment == "") {
      addToast("Status Comment is mandatory for this workflow.", {
        appearance: "error",
        autoDismiss: true
      });
      setSubmitandhomeDisabled(false);
      setSubmitAndContinueDisabled(false); //addes by ishika
      setSubmitAndAddBlankItemDisabled(false); //added by ishika
      setSubmitDisabled(false); //added by ishika
      isSubmit = false;
    }
    try {
      if (isSubmit) {
        let workItemIDInputParam =
          nextWorkItemId == ""
            ? +processingDashboardParam.workItemID
            : nextWorkItemId;

        // let dashFilter = sessionStorage.getItem("filtercriteria");
        // if (
        //   sessionStorage.getItem("filtercriteria") == "" ||
        //   sessionStorage.getItem("filtercriteria") == null
        // )
        //   dashFilter = "";
        // else dashFilter = " and " + sessionStorage.getItem("filtercriteria");

        let dashFilter2 = sessionStorage.getItem("filtercriteria2");
        if (
          sessionStorage.getItem("filtercriteria2") == "" ||
          sessionStorage.getItem("filtercriteria2") == null
        )
          dashFilter2 = "";
        else dashFilter2 = " and " + sessionStorage.getItem("filtercriteria2");

        let sortfieldlist = [];
        if (sessionStorage.getItem("OrderByMaptoFeildName") != null) {
          const input = {
            sortfield: sessionStorage.getItem("OrderByMaptoFeildName")
          };
          sortfieldlist.push(input);
        }
        //let sortfieldlist=sortfield

        let body = {
          currentActivityName: hfActivityName,
          workItemType: ddlWorkItemTypeValue,
          workflowStateID: sessionStorage.getItem("workflowstateID"),
          subProcessID: processingDashboardParam.selectedSubprocess,
          empversionID: sessionStorage.getItem("EmployeeVersionID"),
          intNextStateID: sessionStorage.getItem("workflowstateID"),
          workItemID: workItemIDInputParam,
          statusID: +statusChange,
          personID: +sessionStorage.getItem("PersonID"),
          employeeid: +sessionStorage.getItem("PersonID"),
          statusComment: statusComment,
          statusReasons: statusReason,
          newErrors: [],
          inputValues: inputalues,
          inputMultiValues: inputMultiValues,
          //dashboardFilter: dashFilter,
          dashboardFilter: dashFilter2, //"",
          getNextItemWithDashboardFilter: true,
          xmlSkippedItems: [0],
          assignedEmployeeVersionID: statusAssignedTo,
          assignedActivity: selectedActivityName,
          isSuperUser: true,
          displayCriteria:
            sessionStorage.getItem("WorkitemMode") == null
              ? 0
              : +sessionStorage.getItem("WorkitemMode"),
          fromDate: "2022-07-28T07:24:59",
          toDate: "2022-07-28T07:24:59.606Z",
          selection: "",
          overrideUniqueCheck: sessionStorage.getItem("OverrideUniqueCheck"),
          isDivWorkItemVisible: isDivWorkItemVisible,
          hiddenFieldValWORKITEMTYPE: "WORKITEMTYPEID",
          sortfieldlist: sortfieldlist,
          SortingOrder:
            sessionStorage.getItem("SortingOrder") == null
              ? ""
              : sessionStorage.getItem("SortingOrder")
        };

        let loginUrl = getAPIURL(REACT_APP_SUBMITANDADDNEWBLANKWORKITEM);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization:
              "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body)
        });
        fetch(req)
          .then(res => res.json())
          .then(result => {
            if (result.status === 200) {
              if (result.data != undefined) {
                let row = {
                  activityName: sessionStorage.getItem("workflowactivityName"),
                  workflowStateID: sessionStorage.getItem("workflowstateID")
                };
                let assignedWorkItemDetails = navData;
                const workflowstateID =
                  sessionStorage.getItem("workflowstateID");
                const activityId = sessionStorage.getItem("PageId");

                // getDetailsOfNextWorkId(
                //   result.data.newWorkItem
                // );
                const selectedSubprocess =
                  processingDashboardParam.selectedSubprocess;
                const nav = processingDashboardParam.nav;
                navigate("/ActivityScreen", {
                  state: {
                    row,
                    selectedSubprocess,
                    nav,
                    userRoles,
                    employeeVersionID: sessionStorage.getItem(
                      "EmployeeVersionID"
                    ),
                    workflowstateID,
                    activityId,
                    assignedWorkItemDetails: assignedWorkItemDetails,
                    workItemID: result.data.newWorkItem,
                    Iscanceled: 1
                  }
                });
                window.location.reload();
              } else {
                addToast("Something went wrong!", {
                  appearance: "error",
                  autoDismiss: true
                });
              }
              set_IsLoading(false);

            } else if (result.StatusCode === 500) {

              if (
                result.message != null &&
                result.message.includes("trError Duplicate")
              ) {
                set_IsLoading(false);
                alert("Duplicate WorkItem is not allowed.");
                window.location.reload(true);
              } else if (
                result.Message != null &&
                (result.Message.includes("trDuplicate workitem") ||
                  result.Message.includes("workitem already"))
              ) {
                set_IsLoading(false);
                // let errorMessage = result.Message;
                // addToast(errorMessage, {
                //   appearance: "info",
                //   autoDismiss: true
                // });

                const confirmBox = window.confirm(
                  "Workitem already exists.Do you want to proceed?"
                );

                if (confirmBox === true) {

                  sessionStorage.setItem("OverrideUniqueCheck", "True");
                  //sethdnOverrideUniqueCheck("True");
                  handelSubmitAndAddBlankWorkitem(e);
                  //sessionStorage.clear();
                }
              } else {
                set_IsLoading(false);
                let errorMessage = result.Message;
                addToast(errorMessage, {
                  appearance: "info",
                  autoDismiss: true,
                });
              }
            } else if (result.StatusCode === 401) {
              //;
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            } else {
              addToast("Error occured!", {
                appearance: "error",
                autoDismiss: true
              });

              set_IsLoading(false);
            }
          })
          .catch(error => {
            //if (result.status === 401) {
            ;
            if (
              error.name == "TypeError" &&
              error.message == "Failed to fetch"
            ) {
              let Bodydetails = "";
              for (let i = 0; i < Object.keys(body).length; i++) {
                Bodydetails =
                  Bodydetails +
                  ", " +
                  Object.keys(body)[i] +
                  ": " +
                  Object.values(body)[i];
              }
              CorsLoggingError(
                error.toString() +
                " handelSubmitAndContinue " +
                Bodydetails.toString()
              );
              //navigate("/ErrorPage");
              //window.location.href = "/Home";
            }
            //navigate("/");
            // }
          });
      } else {
        set_IsLoading(false);
      }
    } catch (ex) {
      set_IsLoading(false);
      addToast("Error occured!", {
        appearance: "error",
        autoDismiss: true
      });
    }
  };

  const getDetailsOfNextWorkId = id => {
    try {
      setUpdateTimerToZero(false);
      UpdateTimerToZeroRef.current = false;
      set_IsLoading(true);
      formatAMPM(new Date());
      let body = {
        mode: "",
        userId: sessionStorage.getItem("GUID"),
        applicationName: REACT_APP_NAME,
        subProcessID: processingDashboardParam.selectedSubprocess,
        workItemID: id,
        isSuperUser: true,
        workflowStateID: processingDashboardParam.workflowstateID,
        activityID: processingDashboardParam.activityId,
        screenFilter: "",
        screenFilterStatus: false,
        assigned:
          processingDashboardParam.row.Assigned == undefined
            ? ""
            : "" + processingDashboardParam.row.Assigned,
        differentBatch:
          processingDashboardParam.row.DifferentBatch == undefined
            ? ""
            : "" + processingDashboardParam.row.DifferentBatch,
        copyWorkItemType: "",
        ischkCopyWorkItemType: false,
        isAdhoc: true,
        adhocBlankWorkItemAddition: "",
        employeeVersionId: sessionStorage.getItem("EmployeeVersionID"),
        employeeId: sessionStorage.getItem("PersonID")
      };
      //Edited by Omkar on 16/02/2023
      let GetItemReqbody = {
        // workItemID: processingDashboardParam.workItemID,
        workItemID: id,
        subprocessid: processingDashboardParam.selectedSubprocess,
        personid: sessionStorage.getItem("PersonID")
      };
      let screenAPIURL = getAPIURL(REACT_APP_EDIT_WORKITEMDETAILS);
      let req = new Request(`${screenAPIURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body)
      });
      let GetItemDetailsAPIURL = getAPIURL(REACT_APP_GET_WORKITEMDETAILS);
      let getitemdetailsreq = new Request(`${GetItemDetailsAPIURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(GetItemReqbody)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            //Added by Pradip Rai

            if (
              result.data.onBaseDocumentID == null ||
              result.data.onBaseDocumentID == 0 ||
              result.data.onBaseDocumentID == undefined
            ) {
              setImageToggleVisible(false);
              ImageShow(false);
            } else {
              if (processingDashboardParam.AutoImageMode === "true") {
                setImageToggleVisible(true);
                ImageShow(true);
                setImageModeValue(true);
              } else {
                setImageToggleVisible(true);
                ImageShow(false);
                setImageModeValue(false);
              }
            }
            //Ended by Pradip Rai
            // console.log(result.data.repGroup);
            setstatusChange("");
            if (REACT_APP_HUBBELL_CLIENTID == sessionStorage.selectedLocation || REACT_APP_C520_PROGRESS_CLIENTID == sessionStorage.selectedLocation) {
              setIFrameURL(
                REACT_APP_Image_URL_HUBBELL.replace(
                  "@onBasePassword",
                  encodeURIComponent(result.data.onBasePassword)
                )
                  .replace(
                    "@onBaseUserID",
                    encodeURIComponent(result.data.onBaseUserID)
                  )
                  .replace("@onBaseDocumentID", result.data.onBaseDocumentID)
              );
            } else if (
              REACT_APP_GEC_CLIENTID == sessionStorage.selectedLocation
            ) {
              setIFrameURL(
                REACT_APP_Image_URL_GEC.replace(
                  "@onBasePassword",
                  encodeURIComponent(result.data.onBasePassword)
                )
                  .replace(
                    "@onBaseUserID",
                    encodeURIComponent(result.data.onBaseUserID)
                  )
                  .replace("@onBaseDocumentID", result.data.onBaseDocumentID)
              );
            } else {
              setIFrameURL(
                REACT_APP_Image_URL.replace(
                  "@onBasePassword",
                  encodeURIComponent(result.data.onBasePassword)
                )
                  .replace(
                    "@onBaseUserID",
                    encodeURIComponent(result.data.onBaseUserID)
                  )
                  .replace("@onBaseDocumentID", result.data.onBaseDocumentID)
              );
            }

            if (REACT_APP_HUBBELL_CLIENTID == sessionStorage.selectedLocation || REACT_APP_C520_PROGRESS_CLIENTID == sessionStorage.selectedLocation) {
              imageUrlTemplateRef.current = REACT_APP_Image_URL_HUBBELL.replace(
                "@onBasePassword",
                encodeURIComponent(result.data.onBasePassword)
              ).replace(
                "@onBaseUserID",
                encodeURIComponent(result.data.onBaseUserID)
              );
            } else if (
              REACT_APP_GEC_CLIENTID == sessionStorage.selectedLocation
            ) {
              imageUrlTemplateRef.current = REACT_APP_Image_URL_GEC.replace(
                "@onBasePassword",
                encodeURIComponent(result.data.onBasePassword)
              ).replace(
                "@onBaseUserID",
                encodeURIComponent(result.data.onBaseUserID)
              )
            } else {
              imageUrlTemplateRef.current = REACT_APP_Image_URL.replace(
                "@onBasePassword",
                encodeURIComponent(result.data.onBasePassword)
              ).replace(
                "@onBaseUserID",
                encodeURIComponent(result.data.onBaseUserID)
              )
            }

            setJsonValues(result.data.repGroup);

            setBRJsonValues(result.data.repGroup);
            sethfActivityName(result.data.hfActivityName);
            setddlStatusId(
              result.data.ddlStatusId == null ? 0 : +result.data.ddlStatusId
            );
            sethdnOverrideUniqueCheck(
              result.data.hdnOverrideUniqueCheck ? "1" : "0"
            );
            result.data.hdnOverrideUniqueCheck
              ? sessionStorage.setItem("OverrideUniqueCheck", "True")
              : sessionStorage.setItem("OverrideUniqueCheck", "false");
            setddlWorkItemTypeValue(
              result.data.ddlWorkItemTypeValue == null
                ? ""
                : result.data.ddlWorkItemTypeValue
            );
            //added by Santosh
            //
            // setbtnAuditTrailVisible(
            //   result.data.statusDetails[0].auditTrail
            // );
            // setbtnUserNotesVisible(
            //   result.data.statusDetails[0].userNotes
            // );
            setregGroup(result.data.repGroup.sort((a, b) => a.groupName.localeCompare(b.groupName, undefined, { numeric: true, sensitivity: 'base' })));
            setworkItemStatus(result.data.statusDetails);
            setactivityname(result.data.hfActivityName);
            setSubmitDisabled(result.data.isbtnSubmitDisabled);
            setSubmitAndContinueDisabled(
              result.data.isbtnSubmitAndContinueDisabled
            );
            setSaveDisabled(result.data.isbtnSaveDataDisabled);
            setSubmitAndAddBlankItemDisabled(
              result.data.isbtnSubmitAndAddBlankWorkItemDisabled
            );

            setSkipDisabled(result.data.isbtnSkipDisabled);
            setdivStatusVisible(result.data.isdivStatusVisible);
            setCancelVisible(result.data.isbtnCancelVisible);

            if (result.data.statusDetails.length > 0) {
              //added by Santosh
              if (result.data.statusDetails[0].auditTrail == true) {
                setbtnAuditTrailVisible(true);
              } else {
                setbtnAuditTrailVisible(false);
              }
              if (result.data.statusDetails[0].userNotes == true) {
                setbtnUserNotesVisible(false);
              } else {
                setbtnUserNotesVisible(true);
              }
            }
            else {
              setbtnAuditTrailVisible(false);
              setbtnUserNotesVisible(true);
            }
            // code  ended by Santosh
            setallowAdhocAddition(result.data.allowAdhocAddition);
            setbtnBackToDashboardVisible(result.data.btnBackToDashboardVisible);
            setbtnSubmitVisible(result.data.btnSubmitVisible);
            if (processingDashboardParam.row.isStartWork != undefined) {
              setbtnSubmitAndHomeVisible(true);
              setbtnSubmitVisible(false);
            } else {
              setbtnSubmitAndHomeVisible(false);
            }
            //setbtnSubmitAndHomeVisible(result.data.btnSubmitAndHomeVisible);
            setbtnCancelVisible(result.data.btnCancelVisible);
            setbtnSubmitAndAddBlankItemVisible(
              result.data.btnSubmitAndAddBlankWorkItemVisible
            );
            setUploadFilesReadonly(result.data.isUcUploadFilesReadonly);
            setbtnSkipVisible(result.data.btnSkipVisible);
            setcheckComments(result.data.wgChklistComments);
            //to do
            //setlbOnBaseDocVisible(result.data.lbOnBaseDocVisible)
            //setlbOnBaseDocumentText(result.data.lbOnBaseDocumentText)
            //setibcopyonbaseVisible(result.data.ibcopyonbaseVisible)
            setdivOnbaseDocUploadVisible(result.data.divOnbaseDocUploadVisible);
            setuconbaseDocIsUploadChecked(
              result.data.uconbaseDocIsUploadChecked
            );
            setdivOnbaseDocAppendVisible(result.data.divOnbaseDocAppendVisible);
            setucOnbaseDocAppendDocID(result.data.ucOnbaseDocAppendDocID);
            setOnbaseUploadDocURL(result.data.onbaseUploadDocURL);
            //setAllowAppendImage(result.data.allowAppendImage)
            setOnBaseDocumentID(result.data.onBaseDocumentID);
            setOnBaseUserID(result.data.onBaseUserID);
            setOnBasePassword(result.data.onBasePassword);
            set_IsLoading(false);
            //Added by Pradip Rai on 27/12/2022
            setCountDownTimer(
              result.data.hdnCountDownTimer == "1" ? true : false
            );
            setActivityStandardTime(
              parseInt(result.data.hdnActivityStandardTime)
            );
            //End by Pradip Rai on 27/12/2022
          }
          if (result.status === 500) {
            addToast(result.message, {
              appearance: "info",
              autoDismiss: true
            });
          } else if (result.status === 401) {
            //;
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
          set_IsLoading(false);
        })
        .catch(error => {
          //if (result.status === 401) {
          ;
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            BodydetailsinLog(
              error.toString() + " getDetailsOfNextWorkId req ",
              body
            );
            //navigate("/ErrorPage");
            //window.location.href = "/Home";
          }
          //navigate("/");
          //}
          set_IsLoading(false);
        });
      fetch(getitemdetailsreq)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            setAssignments(result.data.asignmentdetails);
            // setemailcolumns([]);
            //updateEmailColumns(Object.keys(emailmessagedata.value[0]));
            setGridColLen(result.data.asignmentdetails.length);
            setworkItemFieldsDetails(result.data.workItemFieldsDetails);
            setAuditTrails(result.data.listWorkItemHistory);
            //console.log(result.data.listWorkItemHistory);
            if (result.data.listWorkItemHistory.length > 0) {
              setIsAuditTrailAvailable(true);
            }

          } else if (result.status === 401) {
            //;
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Error occured!", {
              appearance: "error",
              autoDismiss: true
            });
          }
          set_IsLoading(false);
        })
        .catch(error => {
          //if (result.status === 401) {
          ;
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            BodydetailsinLog(
              error.toString() + " getDetailsOfNextWorkId getitemdetailsreq  ",
              body
            );
            //navigate("/ErrorPage");
            //window.location.href = "/Home";
          }
          //navigate("/");
          // }
          set_IsLoading(false);
        });
    } catch (ex) {
      set_IsLoading(false);
      addToast("Error occured!", {
        appearance: "error",
        autoDismiss: true
      });
    }
    set_IsLoading(false);
  };
  const BussinnessRuleValue = (
    sourceCondition,
    oldElements,
    sourceSelectedValue,
    sourceMapTofield
  ) => {
    let fieldChangesApply = false;
    newBussinessFieldValues = [];
    if (
      actScreenBussinessRules.find(
        (obj) => obj.sourceconditionalValue === sourceSelectedValue
      ) !== undefined
    ) {
      //
      let sourcefieldValues = actScreenBussinessRules.filter(
        (obj) =>
          obj.sourceconditionalValue === sourceCondition &&
          obj.sourceFieldName === sourceMapTofield
      );
      if (sourcefieldValues.length >= 1) {
        sourcefieldValues.map((obj) => {
          const newobj = {
            businessField: obj.targetFieldName,
            businessFieldValue: obj.targetValue,
            targetFieldReadonly: obj.targetFieldReadonly,
            targetFieldMandatory: obj.targetFieldMandatory,
            targetFieldColor: obj.targetFieldColor,
          };
          newBussinessFieldValues.push(newobj);
          // console.log(obj.targetFieldName);
          //
          getRecursionRules(
            obj.targetFieldName,
            obj.targetValue,
            obj.sourceFieldName
          );
        });
      } else {
      }
    } else {
      getEmptyRules(sourceMapTofield);
    }

    oldElements.state.regGroup.forEach((element) => {
      element.screenFields.forEach((data) => {
        if (sourceSelectedValue === sourceCondition) {
          //|| businessFieldValues.length>0)
          fieldChangesApply = true;
        } else {
          fieldChangesApply = false;
        }
        if (newBussinessFieldValues.length > 0 && fieldChangesApply) {
          //
          newBussinessFieldValues.map((element) => {
            if (
              data.mapToField === element.businessField &&
              fieldChangesApply
            ) {
              // if(data.value!=="" && element.businessFieldValue!=="")
              //   {
              data.value = element.businessFieldValue;
              //}

              data.isReadOnly =
                element.targetFieldReadonly?.toLowerCase?.() === "true";
              data.setColor = element.targetFieldColor;
              data.required =
                element.targetFieldMandatory?.toLowerCase?.() === "true";
            }
          });
        } else if (newBussinessFieldValues.length > 0) {
          newBussinessFieldValues.map((element) => {
            if (data.mapToField === element.businessField) {
              // if(data.value!=="" && element.businessFieldValue!=="")
              //   {
              data.value = element.businessFieldValue;
              //}
              data.isReadOnly =
                element.targetFieldReadonly?.toLowerCase?.() === "true";
              data.setColor = element.targetFieldColor;
              data.required =
                element.targetFieldMandatory?.toLowerCase?.() === "true";
            }
          });
        }
        // if (data.mapToField === targetId && fieldChangesApply === false) {
        //   //;
        //   data.value = "";
        //   data.isReadOnly = false;
        //   data.setColor = "";
        //   data.required = "";
        // }
      });
    });
    setoldElements(oldElements);
    setBusinessFieldValues(newBussinessFieldValues);
  };
  const getRecursionRules = (
    targetFieldName,
    Conditionalvalue,
    SourceFieldName
  ) => {
    // console.log("Enter into recursion method!!");
    const bussinesrules = actScreenBussinessRules.filter(
      (obj) => obj.sourceFieldName == targetFieldName
    );
    if (bussinesrules.length != 0) {
      bussinesrules.map((obj) => {
        if (obj.sourceconditionalValue === Conditionalvalue) {
          const newobj = {
            businessField: obj.targetFieldName,
            businessFieldValue: obj.targetValue,
            targetFieldReadonly: obj.targetFieldReadonly,
            targetFieldMandatory: obj.targetFieldMandatory,
            targetFieldColor: obj.targetFieldColor,
          };
          newBussinessFieldValues.push(newobj);
          //

          // console.log(obj.targetFieldName);
          getRecursionRules(
            obj.targetFieldName,
            obj.targetValue,
            obj.sourceFieldName
          );
        }
      });
    }
  };
  const getEmptyRules = (sourceMapTofield) => {
    let targetFieldNames = actScreenBussinessRules.filter(
      (obj) => obj.sourceFieldName === sourceMapTofield
    );
    if (targetFieldNames.length != 0) {
      targetFieldNames.map((obj) => {
        const newobj = {
          businessField: obj.targetFieldName,
          businessFieldValue: "",
          targetFieldReadonly: false,
          targetFieldMandatory: false,
          targetFieldColor: "",
        };
        newBussinessFieldValues.push(newobj);
        getEmptyRules(obj.targetFieldName);
      });
    }
  };
  const handelChange = (id, event, oldElements) => {
    //Added by Pradip for bussiness rule
    //;
    let sourceSelectedValue = event.target.value;
    actScreenBussinessRules.map((result) => {
      //;
      let sourceId = result.sourceFieldName;
      let targetId = result.targetFieldName;
      let targetValue = result.targetValue;
      //let sourceconditionalOperator = result.sourceconditionalOperator;
      let sourceCondition = result.sourceconditionalValue;
      let readOnly = result.targetFieldReadonly?.toLowerCase?.() === "true"; //result.targetFieldReadonly;
      let mandatoryFields =
        result.targetFieldMandatory?.toLowerCase?.() === "true"; //result.targetFieldReadonly;
      let fieldColor = result.targetFieldColor;
      let fieldChangesApply = false;
      // console.log("bussiness rule");
      //;
      if (id === sourceId) {
        BussinnessRuleValue(
          sourceCondition,
          oldElements,
          sourceSelectedValue,
          id
        );
      }
    });
    //
    //Ended by Pradip for bussiness rule
    const newElements = { ...oldElements };
    ;
    newElements.state.regGroup.forEach(element => {
      for (let i = 0; i < element.screenFields.length; i++) {
        // alert(element.screenFields[i].mapToField+" "+id);
        // alert(element.screenFields[i].isSearch+" "+element.screenFields[i].isSearchTick +" "+ event.target.value);
        if (element.screenFields[i].mapToField === id) {
          if (event.target.selectedOptions == undefined) {
            const value =
              event.target.value !== "Select" ? event.target.value : "";
            element.screenFields[i].value = value;
          } else {
            if (event.target.selectedOptions.length <= 1) {
              const value =
                event.target.value !== "Select" ? event.target.value : "";
              element.screenFields[i].value = value;
            } else {
              /* ;
            if (event.target.selectedOptions.length >= 5) {
              window.confirm(
                "Multiple select value cannot exceed 100 characters!"
              );
            } else { */
              let selectedValues = Array.from(
                event.target.selectedOptions,
                option => option.value
              );
              let value = selectedValues.join(",");
              if (value.length > element.screenFields[i].maxLen) {
                window.confirm(
                  element.screenFields[i].labelText +
                  " " +
                  "value cannot exceed" +
                  " " +
                  element.screenFields[i].maxLen +
                  " " +
                  "characters!"
                );
                value = "";
              }
              element.screenFields[i].value = value;
            }
          }
        }
        if ("chk" + element.screenFields[i].mapToField === id) {
          if (event.target.selectedOptions == undefined) {
            let value =
              element.screenFields[i].isSearchTick === false ? true : false;
            // alert(value);
            element.screenFields[i].isSearchTick = value;
          }
        }
      }
    });
    setJsonValues(newElements);
    //setBRJsonValues(newElements);
  };




  const backToDashBoard = () => {

    CloseImageinTab();
    deletelock();
    const row = processingDashboardParam.row;
    const selectedSubprocess = processingDashboardParam.selectedSubprocess;
    const nav = processingDashboardParam.nav;
    const userRoles = processingDashboardParam.userRoles;
    const defaultFilter = processingDashboardParam.defaultFilter;
    const assignedWorkItemDetails =
      processingDashboardParam.assignedWorkItemDetails;
    let activityName = "";
    if (typeof row.activityName === "undefined") {
      activityName = processingDashboardParam.currentAcitvity;
    } else {
      activityName = row.activityName;
    }
    if (processingDashboardParam.isOND) {
      navigate("/OneandDoneDashboard", {
        state: {
          row,
          selectedSubprocess,
          nav,
          userRoles,
          defaultFilter,
          assignedWorkItemDetails: navData,
          selectedWorkItemDetails: navData.find(
            (e) => e.activityName === activityName
          ),
          currentAcitvity: activityName,
        },
      });
    } else {
      navigate("/WorkActivityDashboard", {
        state: {
          row,
          selectedSubprocess,
          nav,
          userRoles,
          defaultFilter,
          assignedWorkItemDetails: navData,
          selectedWorkItemDetails: navData.find(
            (e) => e.activityName === activityName
          ),
          currentAcitvity: activityName,
        },
      });
    }
  };

  const SkipWorkItem = () => {
    CloseImageinTab();
    let workItemIDInputParam =
      nextWorkItemId == ""
        ? +processingDashboardParam.workItemID
        : nextWorkItemId;

    let dashFilter2 = sessionStorage.getItem("filtercriteria2");
    if (
      sessionStorage.getItem("filtercriteria2") == "" ||
      sessionStorage.getItem("filtercriteria2") == null
    )
      dashFilter2 = "";
    else dashFilter2 = " and " + sessionStorage.getItem("filtercriteria2");

    let sortfieldlist = [];
    if (
      sessionStorage.getItem("OrderByMaptoFeildName") != null &&
      sessionStorage.getItem("OrderByMaptoFeildName") != ""
    ) {
      const input = {
        sortfield: sessionStorage.getItem("OrderByMaptoFeildName")
      };
      sortfieldlist.push(input);

    }
    let objSkippedIDs = [];
    if (
      sessionStorage.getItem("SkippedIDs") == null ||
      sessionStorage.getItem("SkippedIDs") == ""
    ) {
      const input = {
        sortWorkItem: workItemIDInputParam
      };
      objSkippedIDs.push(input);
      sessionStorage.setItem("SkippedIDs", JSON.stringify(objSkippedIDs));
    } else {
      objSkippedIDs = JSON.parse(sessionStorage.getItem("SkippedIDs"));
      const input = {
        sortWorkItem: workItemIDInputParam
      };
      objSkippedIDs.push(input);
      sessionStorage.setItem("SkippedIDs", JSON.stringify(objSkippedIDs));
    }

    let date = new Date().toJSON();

    let body = {
      ActivityID: sessionStorage.getItem("PageId"),
      EmployeeID: sessionStorage.getItem("PersonID"),
      WorkflowStateID: processingDashboardParam.workflowstateID,
      SubProcessID: processingDashboardParam.selectedSubprocess,
      SkippedIDs: JSON.parse(sessionStorage.getItem("SkippedIDs")),
      WorkItemID: workItemIDInputParam,
      DashboardFilter: dashFilter2,
      SortFieldList: sortfieldlist,
      IsSuperUser: true,
      displayCriteria:
        sessionStorage.getItem("WorkitemMode") == null
          ? 0
          : +sessionStorage.getItem("WorkitemMode"),
      fromDate: date, //"2022-07-28T07:24:59",
      toDate: date, //"2022-07-28T07:24:59.606Z",
      Selection: "",
      SortingOrder:
        sessionStorage.getItem("SortingOrder") == null
          ? ""
          : sessionStorage.getItem("SortingOrder"),
    };
    let lockdeleteUrl = getAPIURL(REACT_APP_GETNEXTITEMFORSKIP);
    let deletelockreq = new Request(`${lockdeleteUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body)
    });
    fetch(deletelockreq)
      .then(res => res.json())
      .then(result => {
        if (result.length != 0) {
          // console.log("Skipped");
          addToast("WorkItem Skipped Successfully.", {
            appearance: "success",
            autoDismiss: true
          });

          setnextWorkItemId(result[0].workItemID);
          setworkitem_Id(result[0].workItemID);
          sessionStorage.setItem("workItemIDInputParam", result[0].workItemID);

          getDetailsOfNextWorkId(result[0].workItemID);
          setUpdateTimerToZero(true);
          UpdateTimerToZeroRef.current = true;
          countdownTimer();
          var HIGSubProcessId = REACT_APP_HIGSubProcessId;
          var HIGSubProcessArray = HIGSubProcessId.split(",");
          if(HIGSubProcessArray.includes(sessionStorage.getItem("ProcessId")))
          {
            if(auditmodeRef.current != null && auditRef.current != null)
              {
                  auditmodeRef.current.classList.toggle("show-hide");
                  auditRef.current.classList.toggle("active");
                  auditRef.current.classList.remove("show-hide");
              } 
          }
        } else {
          addToast(
            "Either there are no further workitems on dashboard or none matching your search criterion.",
            {
              appearance: "error",
              autoDismiss: true
            }
          );
          //sessionStorage.setItem("SkippedIDs", "");

          const row = processingDashboardParam.row;
          const selectedSubprocess =
            processingDashboardParam.selectedSubprocess;
          const nav = processingDashboardParam.nav;
          const userRoles = processingDashboardParam.userRoles;
          const defaultFilter = processingDashboardParam.defaultFilter;
          const assignedWorkItemDetails =
            processingDashboardParam.assignedWorkItemDetails;
          let activityName = "";
          if (typeof row.activityName === "undefined") {
            activityName = processingDashboardParam.currentAcitvity;
          } else {
            activityName = row.activityName;
          }
          navigate("/WorkActivityDashboard", {
            state: {
              row,
              selectedSubprocess,
              nav,
              userRoles,
              defaultFilter,
              assignedWorkItemDetails: navData,
              selectedWorkItemDetails: navData.find(
                e => e.activityName === activityName
              ),
              currentAcitvity: activityName
            }
          });
        }
      })
      .catch(error => {
        ;
        setJsonValues([]);
        setregGroup([]);
        setworkitem_Id(0);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " SkipWorkItem  ", body);
        }
      });
  };

  const OpenImageinTab = (e) => {

    ImageShow(false);
    setImageModeValue(
      ImageModeValue === true ? !ImageModeValue : ImageModeValue
    );
    setDocumentOpened(true);
    setDocumentImgURL(window.open(IFrameURL, "_blank", "fullscreen=yes"));

  };

  const CloseImageinTab = (e) => {
    if (DocumentOpened == true && DocumentImgURL) {
      if (DocumentImgURL.window != null) {
        DocumentImgURL.window.close();
      }
      setDocumentOpened(false);

      setImageModeValue(!ImageModeValue);
    }
  };

  const ImageModeOnChange = (e) => {

    ImageShow(e.target.checked);
    setImageModeValue(!ImageModeValue);
  };
  // const ImageModeChange = (e) =>{
  //   
  //   ImageShow(e.target.value)
  // }
  const ImageShow = (show) => {

    if (show == true) {
      setfieldClassName("col-4");
      setdivClassName("splitLeft");
      setiframeactive(true);
      setstaticfieldflex("");
      setAuditTrailButtonEnable(true);
      setbuttonEndClass("col-4");
      setisstaticfieldsColumn(false);
    } else {
      setfieldClassName("col-3");
      setdivClassName("splitfull");
      setiframeactive(false);
      setstaticfieldflex("d-flex");
      setAuditTrailButtonEnable(false);
      setbuttonEndClass("col-3");
      setisstaticfieldsColumn(true);
    }
  };
  const getWorkItemTypes = subprocessId => {
    let body = {
      subprocessId: subprocessId
    };
    let onBaseUrl = getAPIURL(REACT_APP_FETCHONBASEDOCUMENTTYPE);
    // let loginUrl = REACT_APP_FETCH_ONBASE_FILE_UPLOAD;
    let req = new Request(`${onBaseUrl}`, {
      method: "POST",
      // mode: "cors",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        // 'Access-Control-Allow-Origin':'*'
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          //
          if (result.data.documentTypeValue != null) {
            setdocumentTypeValue(result.data.documentTypeValue);
          } else {
            setdocumentTypeValue([]);
          }
        } else if (result.status === 401) {
          //;
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          setdocumentTypeValue([]);
        }
      })
      .catch(error => {
        //if (result.status === 401) {
        ;
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " getWorkItemTypes  ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
        //navigate("/");
        //}
      });
  };

  const FetchBreakTimerOnLoad = () => {
    let today = new Date();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();
    let currentdate =
      today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    let strTime =
      currentdate + " " + hours + ":" + minutes + ":" + seconds + " " + ampm;
    let workItemIDInputParam =
      nextWorkItemId == ""
        ? +processingDashboardParam.workItemID
        : nextWorkItemId;
    let activityId = sessionStorage.getItem("PageId");
    sessionStorage.setItem("PageId", activityId);
    sessionStorage.setItem("workItemIDInputParam", workItemIDInputParam);
    let body = {
      timerStart: strTime,
      subProcessID:
        sessionStorage.getItem("ProcessId") == null
          ? ""
          : sessionStorage.getItem("ProcessId"),
      activityID: sessionStorage.getItem("PageId"), //for home page
      workItemID: workItemIDInputParam,
      employeeTimeUtilization:
        sessionStorage.getItem("empTimeUtilization") == "null" ||
          sessionStorage.getItem("empTimeUtilization") == "undefined"
          ? []
          : JSON.parse(sessionStorage.getItem("empTimeUtilization")),
      selectedEntitiesDetail:
        sessionStorage.getItem("selectedEntitiesDetail") == "null" ||
          sessionStorage.getItem("selectedEntitiesDetail") == "undefined"
          ? {}
          : JSON.parse(sessionStorage.getItem("selectedEntitiesDetail")),
      breakStartTime: "",
      txtBreakComments: "",
      timerValue: 1,
      personID: sessionStorage.getItem("PersonID"),
      tSaveTimerInterval: 60000
    };
    let FetchBreakTimerOnLoadUrl = getAPIURL(REACT_APP_FETCHBREAKTIMERONLOAD);
    let req = new Request(`${FetchBreakTimerOnLoadUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          // settimerStart(result.timerStart);
          // setbtnShowBreakPanelDisabled(result.btnShowBreakPanelDisabled);
          // setbtnShowBreakPanelColor(result.btnShowBreakPanelColor);
          // setddlActivityEnabled(result.ddlActivityEnabled);
          // setobjdataview(result.objdataview);
          if (result.data[0].tSaveTimerEnabled) {
            //  setrunSaveTImer(true);
          }
          sessionStorage.setItem(
            "objdataview",
            JSON.stringify(result.data[0].objdataview)
          );
          sessionStorage.setItem(
            "FetchBreakTimerOnLoadData",
            JSON.stringify(result.data[0])
          );
          sessionStorage.setItem(
            "empTimeUtilization",
            JSON.stringify(result.data[0].employeeTimeUtilization)
          );
          // setddlActivitySelectedValue(result.ddlActivitySelectedValue);
          // settxtBreakCommentsEnabled(result.txtBreakCommentsEnabled);
          // settimerValue(result.timerValue);
          // sethdnSystemBreakValue(result.hdnSystemBreakValue);
          // setimgActivityToolTip(result.imgActivityToolTip);
          // settSaveTimerEnabled(result.tSaveTimerEnabled);
          // setbreakStartTime(result.breakStartTime);
          // setbtnStartWorkTimerDisabled(result.btnStartWorkTimerDisabled);
          //setemployeeTimeUtilization(result.employeeTimeUtilization);
        }
      })
      .catch(error => {
        //if (result.status === 401) {
        ;
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " FetchBreakTimerOnLoad  ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
        //navigate("/");
        //}
      });
  };

  const CancelWorkItemID = e => {
    e.preventDefault();
    let body = {
      WorkItemId: processingDashboardParam.workItemID
    };
    let loginUrl = getAPIURL(REACT_APP_DELETEBLANKWORKITEMBYWORKITEMID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          backToDashBoard();
        }
      })
      .catch(error => {
        ;
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " CancelWorkItemID  ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
        //navigate("/");
      });
  };
  //Added by Pradip on 27/12/2022
  useEffect(() => {
    if (countDownTimeDisabled) {
      setTimer(0);
      countdownTimer();
    }
  }, [ActivityStandardTime]);

  useEffect(()=>{
    var HIGSubProcessId = REACT_APP_HIGSubProcessId;
    var HIGSubProcessArray = HIGSubProcessId.split(",");
    if(HIGSubProcessArray.includes(sessionStorage.getItem("ProcessId")))
        {
          if(auditmodeRef.current != null && auditRef.current != null)
            {
              auditmodeRef.current.classList.toggle("show-hide");
              auditRef.current.classList.toggle("active");
              auditRef.current.classList.remove("show-hide");
            } 
        }
  },[])
  //End by Pradip Rai
  useEffect(() => {
    //setImageModeValue(AutoImageModeOnRef.current);
    const ImageModeFromConfig = AutoImageModeOnRef.current;
    sessionStorage.setItem("SkippedIDs", "");
    //ImageShow(AutoImageModeOnRef.current);
    //getS3BucketFolders()
    setTimer(0); //Added by Pradip Rai
    if (sessionStorage.getItem("ALLOWCLIENTTIMECAPTURING") == "1") {
      FetchBreakTimerOnLoad();
    }
    setworkitem_Id(processingDashboardParam.workItemID);
    setselectedFileNameForUpload("");
    set_IsLoading(true);
    formatAMPM(new Date());
    getWorkItemAttackments(+processingDashboardParam.workItemID);
    //getFilesFromFolder(+processingDashboardParam.workItemID);
    getWorkItemTypes(processingDashboardParam.selectedSubprocess);

    let body = {
      mode: "",
      userId: sessionStorage.getItem("GUID"),
      applicationName: REACT_APP_NAME,
      /*******OND Changes*******/
      subProcessID: processingDashboardParam.isOND
        ? processingDashboardParam.subprocessIdOND
        : processingDashboardParam.selectedSubprocess,
      /*******OND Changes*******/
      workItemID: processingDashboardParam.isOND
        ? processingDashboardParam.workitemOND
        : processingDashboardParam.workItemID,
      isSuperUser: true,
      //OND changes
      workflowStateID: processingDashboardParam.isOND
        ? processingDashboardParam.workflowstateIDOND
        : processingDashboardParam.workflowstateID,
      activityID: processingDashboardParam.isOND
        ? processingDashboardParam.activityIdOND
        : processingDashboardParam.activityId,
      /*******OND Changes*******/
      screenFilter: "",
      screenFilterStatus: false,
      assigned:
        processingDashboardParam.row.Assigned == undefined
          ? ""
          : "" + processingDashboardParam.row.Assigned,
      differentBatch:
        processingDashboardParam.row.DifferentBatch == undefined
          ? ""
          : "" + processingDashboardParam.row.DifferentBatch,
      copyWorkItemType: "",
      isAdhoc: true,
      adhocBlankWorkItemAddition: "",
      employeeVersionId: sessionStorage.getItem("EmployeeVersionID"),
      employeeId: sessionStorage.getItem("PersonID")
    };
    let GetItemReqbody = {
      /*******OND Changes*******/
      workItemID: processingDashboardParam.isOND
        ? processingDashboardParam.workitemOND
        : processingDashboardParam.workItemID,
      /*******OND Changes*******/
      subProcessID: processingDashboardParam.isOND
        ? processingDashboardParam.subprocessIdOND
        : processingDashboardParam.selectedSubprocess,
      personid: sessionStorage.getItem("PersonID"),
    };

    let screenAPIURL = getAPIURL(REACT_APP_EDIT_WORKITEMDETAILS);
    let req = new Request(`${screenAPIURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body)
    });
    let GetItemDetailsAPIURL = getAPIURL(REACT_APP_GET_WORKITEMDETAILS);
    let getitemdetailsreq = new Request(`${GetItemDetailsAPIURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(GetItemReqbody)
    });


    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setEditTableData(result.data.getMultiValues);
          //Added by Sachin/Jinnat  START
          if (result.data.statusDetails.length > 0) {
            SetbtnassignVisible(
              result.data.statusDetails[0].allowAssignmentfornextactivity
            );
          }
          //END
          if (
            result.data.onBaseDocumentID != null ||
            result.data.onBaseDocumentID != 0 ||
            result.data.onBaseDocumentID != ""
          ) {
            setonBaseDocument("(" + result.data.onBaseDocumentID + ")");
          }
          let staticfieldsCount = 0;

          if (REACT_APP_HUBBELL_CLIENTID == sessionStorage.selectedLocation || REACT_APP_C520_PROGRESS_CLIENTID == sessionStorage.selectedLocation) {
            setIFrameURL(
              REACT_APP_Image_URL_HUBBELL.replace(
                "@onBasePassword",
                encodeURIComponent(result.data.onBasePassword)
              )
                .replace(
                  "@onBaseUserID",
                  encodeURIComponent(result.data.onBaseUserID)
                )
                .replace("@onBaseDocumentID", result.data.onBaseDocumentID)
            );
          } else if (
            REACT_APP_GEC_CLIENTID == sessionStorage.selectedLocation
          ) {
            setIFrameURL(
              REACT_APP_Image_URL_GEC.replace(
                "@onBasePassword",
                encodeURIComponent(result.data.onBasePassword)
              )
                .replace(
                  "@onBaseUserID",
                  encodeURIComponent(result.data.onBaseUserID)
                )
                .replace("@onBaseDocumentID", result.data.onBaseDocumentID)
            );
          } else {
            setIFrameURL(
              REACT_APP_Image_URL.replace(
                "@onBasePassword",
                encodeURIComponent(result.data.onBasePassword)
              )
                .replace(
                  "@onBaseUserID",
                  encodeURIComponent(result.data.onBaseUserID)
                )
                .replace("@onBaseDocumentID", result.data.onBaseDocumentID)
            );
          }

          if (REACT_APP_HUBBELL_CLIENTID == sessionStorage.selectedLocation || REACT_APP_C520_PROGRESS_CLIENTID == sessionStorage.selectedLocation) {
            imageUrlTemplateRef.current = REACT_APP_Image_URL_HUBBELL.replace(
              "@onBasePassword",
              encodeURIComponent(result.data.onBasePassword)
            ).replace(
              "@onBaseUserID",
              encodeURIComponent(result.data.onBaseUserID)
            );
          } else if (
            REACT_APP_GEC_CLIENTID == sessionStorage.selectedLocation
          ) {
            imageUrlTemplateRef.current = REACT_APP_Image_URL_GEC.replace(
              "@onBasePassword",
              encodeURIComponent(result.data.onBasePassword)
            ).replace(
              "@onBaseUserID",
              encodeURIComponent(result.data.onBaseUserID)
            )
          } else {
            imageUrlTemplateRef.current = REACT_APP_Image_URL.replace(
              "@onBasePassword",
              encodeURIComponent(result.data.onBasePassword)
            ).replace(
              "@onBaseUserID",
              encodeURIComponent(result.data.onBaseUserID)
            )
          }

          if (
            processingDashboardParam.row !== undefined &&
            result.data.onBaseDocumentID !== undefined &&
            result.data.onBaseDocumentID !== 0
          ) {

            //Added by Pradip Rai
            if (
              result.data.onBaseDocumentID == null ||
              result.data.onBaseDocumentID == 0 ||
              result.data.onBaseDocumentID == undefined
            ) {
              setImageToggleVisible(false);
              ImageShow(false);
            } else {
              if (processingDashboardParam.AutoImageMode === "true") {
                setImageToggleVisible(true);
                ImageShow(true);
                setImageModeValue(true);
              } else {
                setImageToggleVisible(true);
                ImageShow(false);
                setImageModeValue(false);
              }
            }
            //Ended by Pradip Rai
          } else {
            // setfieldClassName("col-4");
            // setdivClassName("splitfull");
            // setiframeactive(false);
            setImageToggleVisible(false);
            ImageShow(false);
          }
          if (result.data.statusDetails.length > 0) {
            if (result.data.statusDetails[0].auditTrail == true) {
              setbtnAuditTrailVisible(true);
            } else {
              setbtnAuditTrailVisible(false);
            }
            if (result.data.statusDetails[0].userNotes == true) {
              setbtnUserNotesVisible(false);
            } else {
              setbtnUserNotesVisible(true);
            }
          }
          else {
            setbtnAuditTrailVisible(false);
            setbtnUserNotesVisible(true);
          }
          setisDivUploadVisible(result.data.isDivUploadVisible);
          if (result.data.isDivUploadVisible) {
            staticfieldsCount = staticfieldsCount + 1;
          }
          setJsonValues(result.data.repGroup);

          if (result.data.repGroup.length > 0) {
            for (let i = 0; i < result.data.repGroup.length; i++) {
              for (let j = 0; j < result.data.repGroup[i].screenFields.length; j++) {
                if (result.data.repGroup[i].screenFields[j].isSearch == true) {
                  setsearch(true);
                }
              }
            }
          }

          setBRJsonValues(result.data.repGroup);
          setisDivWorkItemVisible(result.data.divWorkItemTypeVisible);
          settypeConfigs(result.data.typeConfigs);
          if (result.data.typeConfigs) {
            staticfieldsCount = staticfieldsCount + 1;
          }

          setregGroup(result.data.repGroup.sort((a, b) => a.groupName.localeCompare(b.groupName, undefined, { numeric: true, sensitivity: 'base' })));

          sethfActivityName(result.data.hfActivityName);
          setddlStatusId(
            result.data.ddlStatusId == null ? 0 : +result.data.ddlStatusId
          );
          sethdnOverrideUniqueCheck(
            result.data.hdnOverrideUniqueCheck ? "1" : "0"
          );
          result.data.hdnOverrideUniqueCheck
            ? sessionStorage.setItem("OverrideUniqueCheck", "True")
            : sessionStorage.setItem("OverrideUniqueCheck", "false");
          setddlWorkItemTypeValue(
            result.data.ddlWorkItemTypeValue == null
              ? ""
              : result.data.ddlWorkItemTypeValue
          );
          setworkItemStatus(result.data.statusDetails);
          setactivityname(result.data.hfActivityName);
          setSubmitDisabled(result.data.isbtnSubmitDisabled);
          setSubmitAndContinueDisabled(
            result.data.isbtnSubmitAndContinueDisabled
          );
          setSaveDisabled(result.data.isbtnSaveDataDisabled);
          setSubmitAndAddBlankItemDisabled(
            result.data.isbtnSubmitAndAddBlankWorkItemDisabled
          );
          setSkipDisabled(result.data.isbtnSkipDisabled);
          setdivStatusVisible(result.data.isdivStatusVisible);
          setCancelVisible(result.data.isbtnCancelVisible);
          setallowAdhocAddition(result.data.allowAdhocAddition);
          setbtnBackToDashboardVisible(result.data.btnBackToDashboardVisible);
          setbtnSubmitVisible(result.data.btnSubmitVisible);
          if (processingDashboardParam.row.isStartWork != undefined) {
            setbtnSubmitAndHomeVisible(true);
            setbtnSubmitVisible(false);
          } else {
            setbtnSubmitAndHomeVisible(false);
          }

          //setbtnSubmitAndHomeVisible(result.data.btnSubmitAndHomeVisible);
          setbtnCancelVisible(result.data.btnCancelVisible);
          setbtnSubmitAndAddBlankItemVisible(
            result.data.btnSubmitAndAddBlankWorkItemVisible
          );
          setUploadFilesReadonly(result.data.isUcUploadFilesReadonly);
          setbtnSkipVisible(result.data.btnSkipVisible);
          // console.log("checklistcomments", result.data.wgChklistComments);

          setcheckComments(result.data.wgChklistComments);
          setdivOnbaseDocUploadVisible(result.data.divOnbaseDocUploadVisible);
          if (result.data.divOnbaseDocUploadVisible) {
            staticfieldsCount = staticfieldsCount + 1;
          }
          setuconbaseDocIsUploadChecked(result.data.uconbaseDocIsUploadChecked);
          setdivOnbaseDocAppendVisible(result.data.divOnbaseDocAppendVisible);
          if (result.data.divOnbaseDocAppendVisible) {
            staticfieldsCount = staticfieldsCount + 1;
          }
          setucOnbaseDocAppendDocID(result.data.ucOnbaseDocAppendDocID);
          setOnbaseUploadDocURL(result.data.onbaseUploadDocURL);
          //setAllowAppendImage(result.data.allowAppendImage)
          setOnBaseDocumentID(result.data.onBaseDocumentID);
          setOnBaseUserID(result.data.onBaseUserID);
          setOnBasePassword(result.data.onBasePassword);


          setemailMessageMapField(result.data.emailMessageMapField);
          setemailGroup(result.data.emailGroup);
          setEmailGroupFolder1(result.data.emailGroupFolder1);
          setEmailGroupFolder2(result.data.emailGroupFolder2);
          setisEmailIntegration(result.data.isEmailIntegration);
          set_IsLoading(false);
          //Added by Pradip Rai on 27/12/2022
          setCountDownTimer(
            result.data.hdnCountDownTimer == "1" ? true : false
          );
          setActivityStandardTime(
            parseInt(result.data.hdnActivityStandardTime)
          );
          //Added by Pradip for Bussiness rules
          FetchGetSaveWorkitemFieldDetails(
            processingDashboardParam.selectedSubprocess,
            sessionStorage.getItem("PageId"),
            "0",
            result.data.repGroup
          );

          //End by Pradip for Bussiness rules
          switch (staticfieldsCount) {
            case 0:
              break;
            case 1:
              setstaticfieldsWidth("width-45percent");
              break;
            case 2:
              setstaticfieldsWidth("width-half");
              break;
            case 3:
              setstaticfieldsWidth("width-33percent");
              break;
            default:
              setstaticfieldsWidth("width-45percent");
              break;
          }
          //End by Pradip Rai on 27/12/2022
        } else if (result.status === 401) {
          if (
            result.message ===
            " WorkItemLocked 'This workitem is locked by another user.'"
          ) {
            addToast(
              "WorkItemLocked 'This workitem is locked by another user!",
              {
                appearance: "error",
                autoDismiss: true
              }
            );
            backToDashBoard();
          } else {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
          //;
        } else {
          addToast("WorkItemLocked 'This workitem is locked by another user!", {
            appearance: "error",
            autoDismiss: true
          });
          //setisComment(false);
          set_IsLoading(false);
          backToDashBoard();
        }
      })
      .catch(error => {
        //if (result.status === 401) {
        ;
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " useEffect req  ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
        //navigate("/");
        //}
        // navigate("/")
      });
    // .catch((error) => {
    //   if (error.response) {
    //   }
    //   //  alert("Something went wrong!");
    // });

    fetch(getitemdetailsreq)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          DisplayTimeZone(result.data.asignmentdetails);
          // console.log("assigment", result.data.asignmentdetails);
          setAssignments(result.data.asignmentdetails);
          // setemailcolumns([]);
          // updateEmailColumns(Object.keys(emailmessagedata.value[0]));

          setGridColLen(result.data.asignmentdetails.length);
          var HIGSubProcessId = REACT_APP_HIGSubProcessId;
          var HIGSubProcessArray = HIGSubProcessId.split(",");

          DisplayTimeZone(result.data.listWorkItemHistory);
          setAuditTrails(result.data.listWorkItemHistory);
          //console.log(result.data.listWorkItemHistory);
          if (result.data.listWorkItemHistory.length > 0) {
            setIsAuditTrailAvailable(true);
          }
          setworkItemFieldsDetails(result.data.workItemFieldsDetails);
          if(HIGSubProcessArray.includes(sessionStorage.getItem("ProcessId")))
          {
            if(auditmodeRef.current != null && auditRef.current != null )
              {
                  auditmodeRef.current.classList.toggle("show-hide");
                  auditRef.current.classList.toggle("active");
                  auditRef.current.classList.remove("show-hide");
              }
            
          }
          
          // set_IsLoading(false);
        } else if (result.status === 401) {
          //;
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        //if (result.status === 401) {
        ;
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(
            error.toString() + " useEffect getitemdetailsreq  ",
            body
          );
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
        //navigate("/");
        //}
      });

    // .catch((error) => {
    //   if (error.response) {
    //     set_IsLoading(false);
    //   }
    //   //  alert("Something went wrong!");
    // });
    return () => {
      clearInterval(interval);
      //   alert('unmount')
    };
  }, [NeedRefresh]);
  const WorkitemFieldChanogeOnStatus = (sourceFieldName, conditionalvalue) => {
    let selectedJsonValue = [];
    let statusActionWorkitemFields = actScreenBussinessRules.filter(
      (obj) => obj.sourceFieldName === sourceFieldName
    );
    if (statusActionWorkitemFields.length != 0) {
      //
      if (JsonValues.state != null) {
        selectedJsonValue = [...JsonValues.state.regGroup];
      } else {
        selectedJsonValue = [...JsonValues];
      }

      selectedJsonValue.map((json) => {
        json.screenFields.map((element) => {
          statusActionWorkitemFields.map((actionField) => {
            if (actionField.targetFieldName == element.mapToField) {
              if (actionField.sourceconditionalValue === conditionalvalue) {
                if (element.value !== "" && actionField.targetValue !== "") {
                  element.value = actionField.targetValue;
                }
                element.isReadOnly =
                  actionField.targetFieldReadonly?.toLowerCase?.() === "true";
                if (
                  element.setColor !== "" &&
                  actionField.targetFieldColor !== "#FFFFFF"
                ) {
                  element.setColor = actionField.targetFieldColor;
                }
                element.required =
                  actionField.targetFieldMandatory?.toLowerCase?.() === "true";
              } else {
                element.isReadOnly = false;
                element.required = false;
              }
            }
          });
        });
      });
      setJsonValues(selectedJsonValue);
      setregGroup(selectedJsonValue);
    }
  };
  const WorkitemFieldOnLoadChange = (businessrulesdata, regGroup) => {
    let selectedJsonValue = [];

    if (businessrulesdata.length != 0) {

      // if(JsonValues.state!=null)
      // {
      //    selectedJsonValue = [...JsonValues.state.regGroup];
      // }
      // else
      // {
      //    selectedJsonValue = [...JsonValues];
      // }
      selectedJsonValue = regGroup;
      businessrulesdata.map((actionField) => {
        selectedJsonValue.map((json) => {
          json.screenFields.map((element) => {

            if (
              actionField.targetFieldName == element.mapToField &&
              element.value === actionField.targetValue &&
              element.value != ""
            ) {

              element.isReadOnly =
                actionField.targetFieldReadonly?.toLowerCase?.() === "true";
              element.setColor = actionField.targetFieldColor;
              element.required =
                actionField.targetFieldMandatory?.toLowerCase?.() === "true";
            }
          });
        });
      });
      // selectedJsonValue.map((json) => {
      //   json.screenFields.map((element) => {

      //     businessrulesdata.map((actionField) => {

      //       if (actionField.targetFieldName == element.mapToField && element.value===actionField.sourceconditionalValue) {
      //                   
      //           element.isReadOnly =  actionField.targetFieldReadonly?.toLowerCase?.() === "true";
      //           element.setColor = actionField.targetFieldColor;
      //           element.required = actionField.targetFieldMandatory?.toLowerCase?.() === "true";

      //        }
      //     });
      //   });

      // });

      setJsonValues(selectedJsonValue);
      setregGroup(selectedJsonValue);
    }
  };
  const statusForStatusChange = (e) => {
    setisComment(false);
    setisReasonshow(false);
    setIsAssignedToRequired(false);
    let statusselected = e.target.value.split("|")[0];
    let statusNameSelected = e.target.value.split("|")[1];
    setSelectedStatusName(statusNameSelected);
    let StatusCommentMandatory = 0;
    let StatusReasonMandatory = 0;

    WorkitemFieldChanogeOnStatus("Status", statusNameSelected);
    setstatusChange(statusselected);
    if (e.target.value !== "Select") {
      let activityselected = e.target.value
        .split("(")[1]
        .replace(")", "")
        .trim();
      setselectedActivityName(activityselected);
      setAssignTo(btnassignVisible);
      getEmployeesForSubprocessActivity(activityselected);
      getstatusReasonForSubprocess(statusselected);
      // if (e.target.value === "19") {
      //   setAssignTo(true);
      //   //getStatusReasons(e.target.value)
      // } else {
      //   setReason(false);
      //   setAssignTo(true);
      // }
    }
    const dashboardOrderings = JSON.parse(
      sessionStorage.getItem("DashboardOrderings")
    );
    dashboardOrderings[0].value.split(";").forEach(val => {
      // if (val.split("~")[0].includes(statusselected)) {
      if (val.split("~")[0].trim() == statusselected) {
        StatusCommentMandatory = val.split("~")[1];
      }
    });
    dashboardOrderings[1].value.split(";").forEach(val => {
      // if (val.split("~")[0].includes(statusselected)) {
      if (val.split("~")[0].trim() == statusselected) {
        StatusReasonMandatory = val.split("~")[1];
      }
    });
    if (StatusCommentMandatory === "1") {
      setisComment(true);
    }
    if (StatusReasonMandatory === "1") {
      setisReasonshow(true);
    }
    setstatusChange(statusselected);
  };
  const documnetTypeOnChange = (e) => {

    let workItemTypeId = e.target.value;
    if (workItemTypeId === "Select") {
      setUploadDocumetId(0);
    } else setUploadDocumetId(workItemTypeId);
  };
  const getEmployeesForSubprocessActivity = activityname => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      ActivityName: activityname
    };

    let loginUrl = getAPIURL(REACT_APP_GET_EMPLOYEESFORSUBPROCESSACTIVITY);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setemployeesForSubprocessActivity(result.data.subprocessempName);
          setworkAllocationMode(result.data.workAllocationMode);
          if (result.data.workAllocationMode === "Manual") {
            setIsAssignedToRequired(true);
          }
        } else if (result.status === 401) {
          //;
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        //if (result.status === 401) {
        ;
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(
            error.toString() + " getEmployeesForSubprocessActivity  ",
            body
          );
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
        //navigate("/");
        //}
      });
  };
  const getstatusReasonForSubprocess = statusid => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      status: statusid + "",
      isDeleted: false
    };
    let reasonURL = getAPIURL(REACT_APP_GET_GETSTATUSREASONSBYSUBPROCESSID);
    let reasonreq = new Request(`${reasonURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body)
    });
    fetch(reasonreq)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setstatusReasonForSubprocess(result.data._listStatusReason);
          if (result.data._listStatusReason.length > 0) {
            setReason(true);
          } else {
            setReason(false);
          }
        } else if (result.status === 401) {
          //;
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          setReason(false);
        }
      })
      .catch(error => {
        //if (result.status === 401) {
        ;
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(
            error.toString() + " getstatusReasonForSubprocess  ",
            body
          );
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
        //navigate("/");
        //}
      });
  };
  const employeeAssignedForStatusChange = e => {
    setstatusAssignedTo(e.target.value);
  };
  const reasonForStatusChange = e => {
    let value = Array.from(e.target.selectedOptions, option => +option.value);
    setstatusReason(value);
    //setstatusReason(e.target.selectedOptions);
  };
  const commentForStatusChange = e => {
    setstatusComment(e.target.value);
  };

  const updateSearchWorkitemColumns = useCallback(
    (keys) => {
      let col = [];
      keys.forEach((item) => {
        if (item != "TotalCount" && item != "CWSOnBaseDocID") {
          col.push({
            title: item,
            field: item,
            width: "100px"
          });
        }
      });
      //col.pop();
      setSearchWorkitemColumns(col);
      ;
    },
    [GridColLen]
  );
  return (
    <>

      <Header
        /*********************/
        pageName={
          processingDashboardParam.isOND
            ? processingDashboardParam.ActivityScreen
            : "ActivityScreen"
        }
        subPageName={
          processingDashboardParam.isOND
            ? processingDashboardParam.workitemOND
            : workitem_Id
        }
        timeCaptureActivityName="ActivityScreen"
        timeCaptureWorkItemId={workitem_Id}
      />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="activities" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      <FormContext.Provider value={{ onchange: handelChange }}>
        <div className="centerSection">
          <form>
            {/* One Scenario */}
            {isLoading ? (
              <div className="pt-4">
                {" "}
                <LoadingSpinner />
              </div>
            ) : (
              <>
                <div className="splitScreen">
                  <div className={divClassName}>
                    <div className="fieldBody">
                      <div className="flex">
                        <div className="d-flex align-items-center paddingTopBottom">
                          <h3 className="">
                            {countDownTimeDisabled ? (
                              <>
                                {countdownTime.countdownMinutes > 0 ||
                                  countdownTime.countdownSeconds > 0 ? (
                                  <h4 className="pb-1">
                                    Time Remaining:{" "}
                                    {countdownTime.countdownMinutes}:
                                    {countdownTime.countdownSeconds}
                                  </h4>
                                ) : (
                                  <h4
                                    className="pb-1 colorFB4E0B"
                                  >
                                    Extra Time Start: {formatTime()}
                                  </h4>
                                )}
                              </>
                            ) : null}
                            <h4 className="pb-1">
                              Working on this workitem from:{" "}
                              {currentTime.toUpperCase()}
                            </h4>
                          </h3>
                          {ImageToggleVisible ? (
                            <>
                              <span
                                className="form-check form-switch mb-1 pb-1 marginLeft11"
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  //ref = {ImageModeValue}
                                  onChange={ImageModeOnChange}
                                  //defaultChecked={ImageModeValue}
                                  checked={ImageModeValue}
                                />
                                <label
                                  className="form-label form-label-sm"
                                  htmlFor="flexSwitchCheckDefault"
                                  id="lblImageMode"
                                >
                                  Image Mode
                                </label>
                              </span>
                              <div
                                title="Click to popout the scanned document"
                                id="divImageLink"
                                onClick={() => {
                                  OpenImageinTab();
                                }}
                                className="screenicon"
                              >
                                <img className="imageAlignment" src={ViewImageIcon}></img>
                              </div>
                            </>
                          ) : null}{" "}
                        </div>
                      </div>
                      {isstaticfieldsColumn ? (
                        <div className="staticfields mb-2">
                          {divOnbaseDocUploadVisible ? (
                            <div
                              className={
                                "fieldSet  staticfields-section " +
                                staticfieldsWidth
                              }
                            >
                              <h4 className="regGroup-title mb-2">
                                Onbase Document
                              </h4>
                              <div className="">
                                <div className="col-12">
                                  <div className="pb-3">
                                    <div className="d-flex">
                                      <label className="form-label width-40percent" id="lblDocumentType">
                                        Document Type{" "}
                                      </label>
                                      <select
                                        className="form-control form-control-xs "
                                        onChange={documnetTypeOnChange}
                                        id="ddlDocumentType"
                                      >
                                        <option>Select</option>
                                        {documentTypeValue.map(type => (
                                          <option value={type.id}>
                                            {type.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <div
                                      className="d-flex paddingTop"
                                    >
                                      <input
                                        className="form-control form-control-xs staticfields-lefinput"
                                        type="file"
                                        id="fileForUpload"
                                        onChange={readUploadFileForUpload}
                                        // disabled={!fileToUpload}
                                        value={selectedFileNameForUpload}
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-primary staticfields-rightbutton"
                                        id="btnUploadFileOnServer"
                                        onClick={() => {
                                          uploadFileOnServerForUpload();
                                        }}
                                        disabled={
                                          !IsUploadOrAppend ||
                                          UploadDocumetId === 0
                                        }
                                      >
                                        {IsUploading
                                          ? "Uploading..."
                                          : "Upload"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {divOnbaseDocAppendVisible ? (
                            <div
                              className={
                                "fieldSet  staticfields-section " +
                                staticfieldsWidth
                              }
                            >
                              <h4 className="regGroup-title mb-2">
                                Append Image to Existing Onbase Document (
                                {OnBaseDocumentID})
                              </h4>
                              <div className="row">
                                <div className="col-12">
                                  <div className="pb-3">
                                    <div
                                      className="d-flex"
                                    >
                                      <input
                                        className="form-control form-control-xs staticfields-lefinput"
                                        type="file"
                                        id="fileReadUpload"
                                        onChange={readUploadFileForUpload}
                                        // disabled={!fileToUpload}
                                        value={selectedFileNameForUpload}
                                      />

                                      <button
                                        type="button"
                                        id="btnUploadFileOnServerForAppend"
                                        className="btn btn-sm btn-primary staticfields-rightbutton ASButtonsStyle"
                                        onClick={() => {
                                          uploadFileOnServerForAppend();
                                        }}
                                        disabled={!IsUploadOrAppend}
                                      >
                                        {IsUploading
                                          ? "Appending..."
                                          : "Append"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {typeConfigs ? (
                            <div
                              className={
                                "fieldSet  staticfields-section " +
                                staticfieldsWidth
                              }
                            >
                              <h4 className="regGroup-title mb-2">Required</h4>
                              <div className="row">
                                <div className="col-12">
                                  <div className="pb-3 d-flex">
                                    <label className="form-label width-30percent ASLabelStyle" id="lblWorkitemType">
                                      Workitem Type{" "}
                                      <span id="" className="mandatory ASMarginRight">
                                        *
                                      </span>
                                    </label>

                                    <select
                                      className="form-control form-control-xs"
                                      id="ddlWorkItemTypeChange"
                                      required={true}
                                      onChange={workItemTypeChange}
                                    >
                                      <option>Select</option>
                                      {typeConfigs.map(workItem => (
                                        <option
                                          value={
                                            workItem.workItemTypeID +
                                            "|" +
                                            workItem.workItemType
                                          }
                                          selected={
                                            workItem.workItemTypeID ==
                                            ddlWorkItemTypeValue
                                          }
                                        >
                                          {workItem.workItemType}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {isDivUploadVisible ? (
                            <div
                              className={
                                "fieldSet  staticfields-section " +
                                staticfieldsWidth
                              }
                            >
                              <h4 className="regGroup-title mb-2">
                                Attachments
                              </h4>

                              <span
                                onClick={showAttachmentDetails}
                                className="float-right"
                                data-tip
                                data-for="attachmentTip"
                              >
                                <TbListDetails />
                                <ReactTooltip
                                  id="attachmentTip"
                                  place="top"
                                  effect="solid"
                                >
                                  Click here to Check attachment details!
                                </ReactTooltip>
                              </span>
                              <div className="row">
                                <div className="col-12">
                                  <div className="pb-3">
                                    <div className="d-flex">
                                      <input
                                        className="form-control form-control-xs staticfields-lefinput"
                                        type="file"
                                        id="fileReadUpload"
                                        title="File to upload should be less than
                                        10MB"
                                        onChange={readUploadFile}
                                        // disabled={!fileToUpload}
                                        value={uploadedFile}
                                      />

                                      <button
                                        type="button"
                                        className="btn btn-sm btn-primary staticfields-rightbutton ASButtonsStyle"
                                        id="btnUploadFileonServer"
                                        onClick={() => {
                                          uploadFileOnServer();
                                        }}
                                        disabled={!IsAttachmentUpload}
                                      >
                                        {AttachmentUploading
                                          ? "Uploading..."
                                          : "Upload"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div className=" mb-2">
                          {divOnbaseDocUploadVisible ? (
                            <div className="fieldSet mb-2  ">
                              <h4 className="regGroup-title mb-2">
                                OnBase Document
                              </h4>
                              <div className="row">
                                <div className="col-12">
                                  <div className="pb-3">
                                    <div className="d-flex">
                                      <label
                                        id="lblDocType"
                                        className="form-label width-30percent lableWidth30percent"
                                      >
                                        Document Type{" "}
                                      </label>
                                      <select
                                        className="form-control form-control-xs"
                                        id="ddlDocType"
                                        onChange={documnetTypeOnChange}
                                      >
                                        <option>Select</option>
                                        {documentTypeValue.map(type => (
                                          <option value={type.id}>
                                            {type.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <div
                                      className="d-flex paddingTop"
                                    >
                                      <input
                                        className="form-control form-control-xs staticfields-lefinput"
                                        type="file"
                                        onChange={readUploadFileForUpload}
                                        id="fileReadUploadFileForUpload"
                                        // disabled={!fileToUpload}
                                        value={selectedFileNameForUpload}
                                      />
                                      <button
                                        type="button"
                                        id="btnUploadFileOnServerForUpload"
                                        className="btn btn-sm btn-primary staticfields-rightbutton"
                                        onClick={() => {
                                          uploadFileOnServerForUpload();
                                        }}
                                        disabled={
                                          !IsUploadOrAppend ||
                                          UploadDocumetId === 0
                                        }
                                      >
                                        {IsUploading
                                          ? "Uploading..."
                                          : "Upload"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {divOnbaseDocAppendVisible ? (
                            <div className="fieldSet mb-2 ">
                              <h4 className="regGroup-title mb-2">
                                {" "}
                                Append Image to Existing OnBase Document (
                                {OnBaseDocumentID})
                              </h4>

                              <div className="row">
                                <div className="col-12">
                                  <div className="pb-3">
                                    <div
                                      className="d-flex paddingTop"
                                    >
                                      <input
                                        className="form-control form-control-xs staticfields-lefinput"
                                        type="file"
                                        onChange={readUploadFileForUpload}
                                        id="fileNameForUpload"
                                        // disabled={!fileToUpload}
                                        value={selectedFileNameForUpload}
                                      />

                                      <button
                                        type="button"
                                        className="btn btn-sm btn-primary staticfields-rightbutton"
                                        id="btnUploadFileonServerforAppend"
                                        onClick={() => {
                                          uploadFileOnServerForAppend();
                                        }}
                                        disabled={!IsUploadOrAppend}
                                      >
                                        {IsUploading
                                          ? "Appending..."
                                          : "Append"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {typeConfigs ? (
                            <div className="fieldSet mb-2">
                              <h4 className="regGroup-title mb-2"> Required</h4>
                              <div className="row">
                                <div className="">
                                  <div className="d-flex pb-3">
                                    <label className="form-label width-30percent" id="lblWorkItemType">
                                      Workitem Type{" "}
                                      <span id="" className="mandatory">
                                        *
                                      </span>
                                    </label>

                                    <select
                                      className="form-control form-control-xs"
                                      id="ddlWorkitemTypeChange"
                                      required={true}
                                      onChange={workItemTypeChange}
                                    >
                                      <option>Select</option>
                                      {typeConfigs.map(workItem => (
                                        <option
                                          value={
                                            workItem.workItemTypeID +
                                            "|" +
                                            workItem.workItemType
                                          }
                                          selected={
                                            workItem.workItemTypeID ==
                                            ddlWorkItemTypeValue
                                          }
                                        >
                                          {workItem.workItemType}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {isDivUploadVisible ? (
                            <div className="fieldSet mb-2 ">
                              <h4 className="regGroup-title mb-2">
                                Attachments
                              </h4>
                              <div className="row">
                                <div className="">
                                  <div className="pb-3">
                                    <span
                                      onClick={showAttachmentDetails}
                                      id="spanShowAttachmentDetails"
                                      className="float-right"
                                      data-tip
                                      data-for="attachmentTip"
                                    >
                                      <TbListDetails />
                                      <ReactTooltip
                                        id="attachmentTip"
                                        place="top"
                                        effect="solid"
                                      >
                                        Click here to Check attachment details!
                                      </ReactTooltip>
                                    </span>

                                    <div className="d-flex">
                                      <input
                                        className="form-control form-control-xs staticfields-lefinput"
                                        id="fileReadUpload"
                                        type="file"
                                        title="File to upload should be less than
                                        10MB"
                                        onChange={readUploadFile}
                                        // disabled={!fileToUpload}
                                        value={uploadedFile}
                                      />

                                      <button
                                        type="button"
                                        id="btnUploadfileonServer"
                                        className="btn btn-sm btn-primary staticfields-rightbutton"
                                        onClick={() => {
                                          uploadFileOnServer();
                                        }}
                                        disabled={!IsAttachmentUpload}
                                      >
                                        {AttachmentUploading
                                          ? "Uploading..."
                                          : "Upload"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      )}
                      {isEmailIntegration ? (
                        <div className="fieldSet mb-2">
                          <h4 className="regGroup-title mb-2">
                            {" "}
                            Email Conversation
                          </h4>
                          <div className="row mb-2">
                            {!EmailLoding ? (
                              emailConversations &&
                                emailConversations?.value?.length > 0 ? (
                                <EmailListBox
                                    emails={emailConversations?.value}
                                    handleItemClick={handleItemClick}
                                    shortView={emailPreviewActive}
                                /> 
                              ) : (
                                <div className="text-center">
                                  <span variant="primary">
                                    <div>No records</div>
                                  </span>
                                </div>
                              )
                            ) : (
                              <EmailListPlaceholder />
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <WorkActivityScreenDetailsPage
                        state={{ regGroup, fieldClassName, tableData, settableData, editTableData, setEditTableData }}
                      // tableData={tableData}
                      // settableData = {settableData}
                      />

                      <div className="fieldSet mb-4">
                        <h4 className="mb-2"></h4>
                        <div className="row">
                          <div className={fieldClassName}>
                            {divStatusVisible ? (
                              <div className="pb-3">
                                <label className="form-label" id="lblStatus">
                                  Status
                                  <span id="" className="mandatory">
                                    *
                                  </span>
                                </label>

                                <select
                                  className="form-control form-control-xs"
                                  id="ddlStatusChange"
                                  onChange={statusForStatusChange}
                                >
                                  <option>Select</option>
                                  {workItemStatus.map(status => (
                                    <option
                                      value={
                                        status.workItemStatusID +
                                        "|" +
                                        status.statusName
                                      }
                                    >
                                      {status.statusName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            ) : null}
                          </div>
                          <div className={fieldClassName}>
                            {assignTo ? (
                              <div>
                                <label className="form-label" id="lblAssignTo">
                                  Assign To
                                  {IsAssignedToRequired &&
                                    employeesForSubprocessActivity.length > 0 ? (
                                    <span
                                      id=""
                                      className="mandatory redColor"
                                    >
                                      *
                                    </span>
                                  ) : null}
                                </label>
                                <select
                                  className="form-control form-control-xs"
                                  id="ddlEmployeeAssignedForStatusChange"
                                  onChange={employeeAssignedForStatusChange}
                                >
                                  <option>Select</option>
                                  {employeesForSubprocessActivity.map(
                                    employee => (
                                      <option
                                        value={employee.employeeVersionID}
                                      >
                                        {employee.nameEmployeeID}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            ) : null}
                          </div>
                          <div className={"width-45percent " + fieldClassName}>
                            {isReason ? (
                              <div>
                                <label className="form-label" id="lblStatusReason">
                                  Status Reason
                                </label>
                                {isReasonshow &&
                                  statusReasonForSubprocess.length > 0 ? (
                                  <span
                                    id=""
                                    className="mandatory redColor"
                                  >
                                    *
                                  </span>
                                ) : null}
                                <select
                                  multiple
                                  className="form-control form-control-xs"
                                  id="ddlReasonForStatusChange"
                                  onChange={reasonForStatusChange}
                                >
                                  {statusReasonForSubprocess.map(reason => (
                                    <option value={reason.statusReasonID}>
                                      {reason.reason}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            ) : null}
                          </div>

                          <div>
                            <div className="row">
                              <div
                                className="col-md-6 statusComment"
                              >
                                Status Comment
                                {isComment ? (
                                  <span
                                    id=""
                                    className="mandatory redColor"
                                  >
                                    *
                                  </span>
                                ) : null}
                                &nbsp;&nbsp;
                                {/* added by Santosh */}
                                {userNotes ? (
                                  <label className="checkbox-inline" id="lblInternalUserNotes">
                                    <input
                                      className="form-check-input"
                                      onChange={checkHandler}
                                      checked={isChecked}
                                      id="chkInternalUserNotes"
                                      type="checkbox"
                                    />
                                    &nbsp;&nbsp;Internal
                                  </label>
                                ) : null}
                              </div>
                            </div>
                            <textarea
                              className="form-control"
                              id="txtStatusComment"
                              placeholder="Type here...."
                              onChange={commentForStatusChange}
                              value={statusComment}
                            ></textarea>
                          </div>
                        </div>
                      </div>                     
                          {auditTrail && !AuditTrailButtonEnable ? (
                            <div className="fieldSet mb-4">
                              <h4 className="mb-2"></h4>
                              <div className="row">
                                <div
                                  className="accordion"
                                  id="accordionFlushExample2"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      onClick={onauditClick}
                                      id="flush-headingThree"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        ref={auditRef}
                                        id="btnWorkitemAuditTrail"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThree"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThree"
                                      >
                                        Workitem Audit Trail
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseThree"
                                      ref={auditmodeRef}
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingThree"
                                      data-bs-parent="#accordionFlushExample2"
                                    >
                                      <div className="">
                                      {isAuditTrailAvailable ? (
                                        <div className="mb-3">
                                          <div className="tableWrap">
                                            <div className="tableWrap-body">
                                              <table className="table table-borderless MatTable" id="tblWorkitemAuditTrail">
                                                <MaterialTable
                                                  title="Workitem Audit Trail"                                                 
                                                  options={{
                                                    search: false,
                                                    overflowY: false,
                                                    showTitle: false,
                                                    padding: "dense",
                                                    cellStyle: {
                                                      whiteSpace: "Normal",
                                                      wordWrap: "break-word",
                                                      textOverflow: "ellipsis",
                                                      //whiteSpace: "nowrap",
                                                      //overflow: "hidden",
                                                    },
                                                    headerStyle: {
                                                      position: "sticky"
                                                      // top: "-5px",
                                                    },
                                                    rowStyle: {
                                                      fontFamily:
                                                        '"Yantramanav", sans-serif'
                                                    },
                                                  }}
                                                  columns={
                                                    listWorkItemHistoryColumns
                                                  }
                                                  icons={tableIcons}
                                                  data={auditTrails}
                                                  detailPanel={[
                                                    {
                                                      //tooltip: "Expand",
                                                      render: (rowData) => (
                                                        <ActivityScreenTrackingDetails
                                                          state={{
                                                            subprcoess: processingDashboardParam.selectedSubprocess,
                                                            workitemid: nextWorkItemId == ""
                                                              ? +processingDashboardParam.workItemID
                                                              : nextWorkItemId,
                                                            workflowid: processingDashboardParam.workflowstateID,
                                                            activityid: +processingDashboardParam.activityId,
                                                            personid: +sessionStorage.getItem("PersonID"),
                                                            trackingid: rowData.workitemtrackingID
                                                          }}
                                                        />
                                                      )
                                                    },
                                                  ]}
                                                  components={{
                                                    Toolbar: props => (
                                                      <div
                                                        className="tableStyle"
                                                      >
                                                        <MTableToolbar
                                                          {...props}
                                                          className="MTableToolbarfont"
                                                        />
                                                      </div>
                                                    )
                                                  }}
                                                />
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                        ) : (
                                          <div className="accordion-body ">
                                            <div className="fieldSet form-label">No audit trails generated yet.</div>
                                          </div>
                                      )}
                                      </div>                                     
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                    </div>
                    <div className="fieldFooter col-4">
                      <div className="row">
                        <div className="col text-start">
                          <button
                            className="btn btn-outline-primary btn-sm ms-3"
                            id="btnsaveDraft"
                            disabled={SaveDisabled}
                            title="Click here to save the updates on this workitem"
                            onClick={handelSave}
                          >
                            Save Draft
                          </button>
                          {!processingDashboardParam.isOND ? (
                            <button
                              className="btn btn-outline-primary btn-sm ms-3"
                              id="btnSubmitContinue"
                              disabled={SubmitAndContinueDisabled}
                              title="Click here to submit this workitem and go to the next WorkItem"
                              onClick={handelSubmitAndContinue}
                            >
                              Submit & Continue
                            </button>
                          ) : null}
                          {btnSubmitAndAddBlankItemVisible ? (
                            <button
                              className="btn btn-outline-primary btn-sm ms-3"
                              id="btnSubmitAndAddBlankItem"
                              disabled={SubmitAndAddBlankItemDisabled}
                              title="Click here to submit this workitem and Add new blank WorkItem"
                              onClick={handelSubmitAndAddBlankWorkitem}
                            >
                              Submit & Add Blank WorkItem
                            </button>
                          ) : null}
                          {btnSubmitVisible ? (
                            <button
                              className="btn btn-outline-primary btn-sm ms-3"
                              id="btnSubmitDashboard"
                              disabled={SubmitDisabled}
                              title="Click here to submit this workitem and go back to dashboard"
                              onClick={handelSubmit}
                            >
                              Submit
                            </button>
                          ) : null}
                          {btnSubmitAndHomeVisible ? (
                            <button
                              className="btn btn-outline-primary btn-sm ms-3"
                              id="btnSubmitandHome"
                              disabled={SubmitandhomeDisabled}
                              title="Click here to submit this workitem and go back to dashboard"
                              onClick={handelSubmitAndHome}
                            >
                              Submit And Home
                            </button>
                          ) : null}
                          {processingDashboardParam.Iscanceled == 1 ? (
                            <button
                              className="btn btn-outline-primary btn-sm ms-3"
                              id="btnCancelWorkItem"
                              title="Click here to delete workitemid"
                              onClick={CancelWorkItemID}
                            // onClick={() => {
                            //   CancelWorkItemID();
                            // }}
                            >
                              Cancel
                            </button>
                          ) : null}
                          {btnSkipVisible ? (
                            !processingDashboardParam.isOND ? (
                              <button
                                // className="btn btn-outline-primary ms-3"
                                // disabled={SkipDisabled}
                                type="button"
                                id="btnSkip"
                                className="btn btn-outline-primary btn-sm ms-2"
                                onClick={SkipWorkItem}
                              >
                                Skip
                              </button>
                            ) : null
                          ) : null}
                          {btnBackToDashboardVisible &&
                            !btnSubmitAndHomeVisible ? (
                            <button
                              className="btn btn-link btn-sm text-primary "
                              id="btnBacktoDashBoard"
                              title="Click here to go back to the Dashboard"
                              onClick={backToDashBoard}
                            >
                              Back to dashboard
                            </button>
                          ) : null}{" "}
                        </div>



                        <div className={buttonEndClass + " text-end"}>
                          <div className="d-grid d-sm-flex justify-content-md-center align-items-center gap-2">
                            {auditTrail && AuditTrailButtonEnable ? (
                              <button
                                type="button"
                                id="btnBreakShow"
                                className="btn btn-outline-primary btn-sm ms-2"
                                onClick={breakShow}
                              >
                                {" "}
                                <b className="icon-settings"></b>Audit Trail
                              </button>
                            ) : null}
                            {/* {isEmailIntegration ? (
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-sm ms-2"
                                onClick={emailDetailsShow}
                              >
                                <b className="icon-mail"></b>Email Details
                              </button>
                            ) : null} */}


                            {search ? (
                              <button
                                className="btn btn-outline-primary btn-sm ms-2"
                                id="btnSearch"
                                //title="Click here to go back to the Dashboard"
                                onClick={openFloatingModal}
                              >
                                Search
                              </button>
                            ) : null}
                            <button
                              type="button"
                              id="btnWorkItemShow"
                              className="btn btn-outline-primary btn-sm ms-2"
                              onClick={workitemShow}
                            >
                              <b className="icon-file-text"></b>Workitem Details
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      iframeactive == false ? "display-none" : "splitRight"
                    }
                  >
                    <Iframe
                      url={IFrameURL}
                      width="100%"
                      height="100%"
                      id="myId"
                      display="initial"
                      position="relative"
                    />
                   </div>
            {/* Added by Sachin fro Email Previewer */}

            <div
              className={
                emailPreviewActive == false
                  ? "display-none"
                  : "splitRight"
              }
            >
              <div
                className="container"
                style={{ backgroundColor: "#fff" }}
              >
                {!openComposeEmail && (
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        className="btn btn-outline-primary btn-sm ms-3"
                        style={{ float: "left" }}
                        title="Click here to Close"
                        onClick={hidePreviwer}
                      >
                        Close
                      </button>

                      <button
                        className="btn btn-outline-primary btn-sm ms-3"
                        style={{ float: "right" }}
                        title="Click here to reply"
                        onClick={(e) =>
                          replyEmail(e, previewedEmail.webLink)
                        }
                      >
                        Reply
                      </button>
                    </div>
                  </div>
                )}
                {openComposeEmail && (
                  <>
                    <div
                      className="row mb-4"
                      style={{ backgroundColor: "#fff" }}
                    >
                      <div className="border-bottom">
                        <h5>{previewedEmail.subject}</h5>
                      </div>
                      <EmailCompose
                        email={previewedEmail}
                        onSend={handleSendEmail}
                        onclose={handleCloseSendEmail}
                      />{" "}
                    </div>
                    <hr></hr>
                  </>
                )}
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="email-container"
                      style={{
                        backgroundColor: "#fff",
                        border: "0px solid #ddd",
                        borderRadius: "0px",
                        padding: "10px",
                      }}
                    >
                      <div
                        className="email-header"
                        style={{
                          borderBottom: "1px solid #ddd",
                          paddingBottom: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        {!openComposeEmail && (
                          <h4>{previewedEmail.subject}</h4>
                        )}

                        <div
                          className="email-meta"
                          style={{
                            color: " #777",
                            fontSize: "14px",
                          }}
                        >
                          <p
                            style={{
                              marginBottom: "0rem",
                              marginTop: "10px",
                              width: "200px",
                            }}
                          >
                            <strong>From:</strong>
                            {previewedEmail.length != 0
                              ? previewedEmail.from.emailAddress.address.toString()
                              : null}
                          </p>
                          <p
                            style={{
                              marginBottom: "0rem",
                              marginTop: "10px",
                              width: "200px",
                            }}
                          >
                            <strong>To:</strong>
                            {previewedEmail.length != 0
                              ? previewedEmail.toRecipients
                                  .map(
                                    (item) => item.emailAddress.address
                                  )
                                  .join(",")
                              : null}
                          </p>
                          <p style={{ marginBottom: "0rem" }}>
                            <strong>Cc:</strong>
                            {previewedEmail.length != 0
                              ? previewedEmail.ccRecipients
                                  .map(
                                    (item) => item.emailAddress.address
                                  )
                                  .join(",")
                              : null}
                          </p>
                        </div>
                      </div>

                      <div
                        className="email-body"
                        dangerouslySetInnerHTML={{
                          __html: previewedEmail?.body?.content,
                        }}
                        style={{ marginTop: "30px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </form>
  </div>
</FormContext.Provider>
<Footer />
      {/** Audit MODAL **/}

      <Modal
        show={breakModal}
        id="breakModal"
        dialogClassName="modal-100w"
        onHide={breakClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {" "}
          <Modal.Title>
            Audit Trail -{" "}
            {nextWorkItemId != ""
              ? nextWorkItemId
              : processingDashboardParam.workItemID}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <ul className="timeBreak-form">
              <div className="tableWrap">
                <div className="tableWrap-body MatTable">
                  <MaterialTable
                    title="Workitem Audit Trail"
                    id="tblworkitemAuditTrail"
                    options={{
                      search: false,
                      showTitle: false,
                      cellStyle: {
                        // width: 100,
                        // minWidth: 100,
                        whiteSpace: "Normal"
                      },
                      headerStyle: {},
                      rowStyle: {
                        //  fontFamily: '"Yantramanav", sans-serif',
                      }
                    }}
                    columns={listWorkItemHistoryColumns}
                    icons={tableIcons}
                    data={auditTrails}
                    components={{
                      Toolbar: props => (
                        <div
                          className="tableStyle"
                        >
                          <MTableToolbar
                            {...props}
                            className="MTableToolbarfont"
                          />
                        </div>
                      )
                    }}
                  />
                </div>
              </div>
            </ul>
          </div>
        </Modal.Body>
      </Modal>

      { floatingModal ? (
      <Draggable handle=".note-header" disabled={isPinned} >
      <ResizableBox 
        width={Popupsize.width} 
        height={Popupsize.height}
        minConstraints={[100, 100]}
        maxConstraints={[1200,1200]}
        className="sticky-note"
        style={{
          border: '1px solid #ccc',
          padding: '10px',
          overflow: 'auto',
          position: 'fixed',
          backgroundColor: 'white',
          top: '50px',
          left: '50px',
          zIndex: 1000,
        }}
        onResize={ResizeModal}
        resizeHandles={['se']}>
        <div className="note-header" style={{cursor: isPinned?'not-allowed':'move', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <span>Activity Screen Search</span>
          <div style={{marginLeft: '50%'}}>
          <button
                type="button"
                className="btn btn-sm btn-outline-primary ms-2"
                onClick={showAdvanceSearch}
              >
                <b className="icon-edit"></b> Advance Search
              </button>
          </div>
          <div>
            <div onClick={togglePin} style={{cursor:'pointer',marginRight: '5px'}}>
              <FontAwesomeIcon icon = {faThumbtack} style={{opacity: isPinned?1:0.25}}/>
              <FontAwesomeIcon icon = {faCloudDownload} onClick = {getPopupDataForExportExcel} style={{marginLeft:'15px'}}/>
              <FontAwesomeIcon icon = {faTimes} onClick = {closeFloatingModal} style={{marginLeft:'15px'}}/>
              {/* <button
                type="button"
                id = "btnExport"
                className="btn btn-sm ms-2"
                onClick={() => {
                  getPopupDataForExportExcel();
                }}
                >
                <b></b>Export
              </button> */}
            </div>
          </div>
          </div>
          <div>
          {/* <div className="col-3" style={{ "padding-top": "9px" }}>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary ms-2"
              onClick={showAdvanceSearch}
            >
              <b className="icon-edit"></b> Advance Search
            </button>
          </div> */}
          {isLoadingWorkitemdetails ? (
            <LoadingSpinner />
          ) : (
            <div className="tableWrap">
               {/* <div className="tableWrap-body MatTable material-table-core"> */}
              <div className="tableWrap-body">
              <table className="table table-borderless MatTable"
                        style={{ zIndex: "1", position: "relative" }}
                      >
              <ThemeProvider theme={theme}>
                <MaterialTableCore
                  tableRef={tableRef}
                  title="Activity Screen Search"
                  localization={{
                    header: {
                      actions: ""
                    }
                  }}
                  actions={[
                    {
                      icon: () => <FcSearch />,
                      tooltip: "View workitem's detail",
                      onClick: (event, rowData) =>
                        viewWorkitemShow(rowData),
                      position: "row"
                    },
                    {
                      icon: () => <FcImageFile />,
                      tooltip: "View document",
                      id : "viewDocumentIcon",
                      onClick: (event, rowData) =>
                        documnetShow(rowData),
                      position: "row"
                    }
                  ]}
                  options={{
                      maxBodyHeight: Popupsize.height,
                      search: false,
                      sorting: true,
                      filtering: true,
                      tableLayout: "fixed",
                      columnResizable: "true",
                      tableWidth: "variable",
                      overflowY: false,
                      showTitle: false,
                      pageSize: rowsperPage,
                      pageSizeOptions:[5],
                      padding:"dense",
                      cellStyle: {
                          whiteSpace: "Normal",
                          wordWrap: "break-word",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden"
                      },
                      headerStyle: {
                          position: "sticky"
                      },
                      rowStyle: {
                          fontFamily: '"Yantramanav", sans-serif',
                      },
                  }}
                  columns={SearchWorkitemColumns}
                  icons={tableIcons}
                  data={getSearchedPopupData}
                  components={{
                      Container: (props) => (
                          <Paper {...props} elevation={0} />
                      ),
                      Toolbar: (props) => (
                        <div
                            style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em",
                            }}
                        >
                            <MTableToolbarCore
                                {...props}
                                style={{
                                    fontSize: "5px",
                                }}
                            />
                        </div>
                      ),
                  }}
                />
                </ThemeProvider>
                </table>
               </div>
               </div>
              // </div>
          )}
        </div>
      </ResizableBox>
      </Draggable>
      ): null}
      <Modal
        show={viewSearchModal}
        dialogClassName="modal-100w"
        onHide={viewSearchCloseAndRest}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Advanced Search Criteria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <ul className="timeBreak-form">
              <div className="tableWrap">
                <div className="tableWrap-body">
                  <table className="table brandTable">
                    <div>
                      <div className="fieldSet pt-1">
                      <div class="col-md-5">
                        <label className="form-label form-check form-switch mb-1 pb-1" id="lblCheckDefinedFields">
                          {" "}
                          Include System Defined Fields
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            onChange={ModeOnChange}
                            checked={IsCustomField == "1" ? true : false}
                          />
                        </label>
                      </div>
                        {/* <h4 className="mb-4">Advanced Search Criteria</h4> */}
                        <div className="row align-items-end">
                          <div className="col-3">
                            <div className="mb-3">
                              <label className="form-label">Current State</label>
                              <select
                                className="form-control form-control-sm"
                                ref={CuurentStateRef}
                                //onChange={getCurrentState}
                              >
                                <option>Select</option>
                                {WorKFlowStates.map(state => (
                                  <option
                                    value={
                                      state.workflowstateid + "/" + state.nextState
                                    }
                                    selected={
                                      state.workflowstateid == CuurentState
                                    }
                                  >
                                    {state.nextState}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="mb-3">
                              <label className="form-label">Current Status</label>
                              <select
                                className="form-control form-control-sm"
                                ref={CuurentStatusRef}
                                //onChange={getCurrentStatus}
                              >
                                <option>Select</option>
                                {WorkFlowStatuses.map(status => (
                                  <option
                                      value={
                                        status.subProcessWorkflowConfigID +
                                        "/" +
                                        status.statusName
                                      }
                                      selected={
                                        status.subProcessWorkflowConfigID == CuurentStatus
                                      }
                                    >
                                    {status.statusName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="mb-3">
                              <label className="form-label">Upload Date & time</label>
                              <DateRangePicker
                                initialSettings={{
                                  autoApply: true,
                                  autoUpdateInput: false,
                                  opens: "right",
                                  timePicker: true,
                                  locale: { format: "M/DD/YY hh:mm A" }
                                }}
                                onApply={handleApplyUpload}
                              >
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  placeholder="Select Date"
                                  value={
                                    uplodadedDateTimeFrom + "-" + uplodadedDateTimeTo
                                  }
                                />
                              </DateRangePicker>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="mb-3">
                              <label className="form-label">
                                Activity Date & time
                              </label>
                              <DateRangePicker
                                initialSettings={{
                                  autoApply: true,
                                  autoUpdateInput: false,
                                  opens: "right",
                                  timePicker: true,
                                  locale: { format: "M/DD/YY hh:mm A" }
                                }}
                                onApply={handleApplyActivity}
                              >
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  placeholder="Select Date"
                                  value={
                                    activityDateTimeFrom + "-" + activityDateTimeTo
                                  }
                                />
                              </DateRangePicker>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="mb-3">
                              <label className="form-label">
                                Search field{" "}
                              </label>
                              <select
                                className="form-control form-control-sm"
                                title="Select a field to apply filter"
                                onChange={getAdvanceField}
                              >
                                <option>Select</option>
                                {WorkFlowWorkItemFields.map(status => (
                                  <option

                                    value={
                                      status.maptoField +
                                      "~" +
                                      status.dataType +
                                      "/" +
                                      status.labelText +
                                      "$" +
                                      status.maptoFieldAndDataType
                                    }

                                    // value={
                                    //   status.maptoField + "/" + status.labelText
                                    // }
                                    
                                  >
                                    {status.labelText}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="mb-3">
                              <label className="form-label">Condition </label>
                              {numericOperator ? (
                                <select
                                id="ddlOperator"
                                  className="form-control form-control-sm"
                                  title="Select a field to apply filter"
                                  onChange={getAdvanceCondition}
                                  value={isDefaultValue ? "Select" : advCondition}
                                >
                                  <option value="Select">Select</option>
                                  <option value="=">=</option>
                                  <option value="<>">{notEqual}</option>
                                  <option value="<">{lessThan}</option>
                                  <option value="<=">{lessThanEqual}</option>
                                  <option value=">">{greaterThen}</option>
                                  <option value=">=">{greaterThenEqual}</option>
                                </select>
                              ) : isDateField ? (
                                <select
                                  className="form-control form-control-sm"
                                  id = "ddlAdvanceCondition"
                                  title="Select a field to apply filter"
                                  onChange={getAdvanceCondition}
                                  value={isDefaultValue ? "Select" : advCondition}
                                >
                                  <option value="Select">Select</option>
                                  <option value="Equals">Equals</option>
                                  <option value="Before">Before</option>
                                  <option value="After">After</option>
                                  <option value="Tomorrow">Tomorrow</option>
                                  <option value="Today">Today</option>
                                  <option value="Yesterday">Yesterday</option>
                                  <option value="NextWeek">NextWeek</option>
                                  <option value="ThisWeek">ThisWeek</option>
                                  <option value="LastWeek">LastWeek</option>
                                  <option value="NextMonth">NextMonth</option>
                                  <option value="ThisMonth">ThisMonth</option>
                                  <option value="LastMonth">LastMonth</option>
                                  <option value="NextQuarter">NextQuarter</option>
                                  <option value="ThisQuarter">ThisQuarter</option>
                                  <option value="LastQuarter">LastQuarter</option>
                                  <option value="NextYear">NextYear</option>
                                  <option value="ThisYear">ThisYear</option>
                                  <option value="LastYear">LastYear</option>
                                  <option value="YearToDate">YearToDate</option>
                                  <option value="IsNull">IsNull</option>
                                  <option value="IsNotNull">IsNotNull</option>
                                </select>
                              ) : (
                                <select
                                  id="ddlWordOpertator"
                                  className="form-control form-control-sm"
                                  title="Select a field to apply filter"
                                  onChange={getAdvanceCondition}
                                  value={isDefaultValue ? "Select" : advCondition}
                                >
                                  <option value="Select">Select</option>
                                  <option value="Equals">Equals</option>
                                  <option value="Begin with">Begin with</option>
                                  <option value="Contains">Contains</option>
                                  <option value="End with">End with</option>
                                  <option value="Does Not Contain">
                                    Does Not Contain
                                  </option>
                                </select>
                              )}
                              {/* {numericOperator ? (
                                <select
                                  className="form-control form-control-sm"
                                  title="Select a field to apply filter"
                                  onChange={getAdvanceCondition}
                                  value={isDefaultValue ? "Select" : advCondition}
                                >
                                  <option value="Select">Select</option>
                                  <option value="=">=</option>
                                  <option value="<>">{notEqual}</option>
                                  <option value="<">{lessThan}</option>
                                  <option value="<=">{lessThanEqual}</option>
                                  <option value=">">{greaterThen}</option>
                                  <option value=">=">{greaterThenEqual}</option>
                                </select>
                              ) : (
                                <select
                                  className="form-control form-control-sm"
                                  title="Select a field to apply filter"
                                  onChange={getAdvanceCondition}
                                  value={isDefaultValue ? "Select" : advCondition}
                                >
                                  <option value="Select">Select</option>
                                  <option value="Equals">Equals</option>
                                  <option value="Begin with">Begin with</option>
                                  <option value="Contains">Contains</option>
                                  <option value="End with">End with</option>
                                  <option value="Does Not Contain">
                                    Does Not Contain
                                  </option>
                                </select>
                              )} */}
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="mb-3">
                            <label className="form-label" id="lblKeyword">Keyword</label>
                                {isDateField ? (
                                  <input
                                    id="txtSearchDate"
                                    type="date"
                                    className="form-control form-control-sm"
                                    disabled={disableDate}
                                    onChange={getAdvanceDateText}
                                    value={advSearchTextDate}
                                  />
                                ) : numericOperator ? (
                                  <input
                                    id="txtSearchNumber"
                                    type="int"
                                    className="form-control form-control-sm"
                                    onChange={getAdvanceText}
                                    value={advSearchText}
                                  />
                                ) : (
                                  <input
                                    id="txtSearchText"
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={getAdvanceText}
                                    value={advSearchText}
                                  />
                                )}
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="mb-3">
                              <div className="form-check form-check-inline ">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox1"
                                  onChange={getArchive}
                                  value={checkArchive}
                                  checked={checkArchive}
                                />
                                <label
                                  className="form-check-label"
                                  for="inlineCheckbox1"
                                >
                                  Include Archived Work Items
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn btn-sm btn-outline-primary w-100 text-uppercase"
                                onClick={addAdvanceSearchConditions}
                              >
                                {" "}
                                Add
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="pt-2 pb-2">
                          <div className="mb-5">
                            <div>
                              <table className="table table-borderless MatTable">
                                {/* <ThemeProvider theme={theme}> */}
                                <MaterialTable
                                  localization={{
                                    pagination: {
                                      //labelDisplayedRows: '{from}-{to} of {count}'
                                    },
                                    toolbar: {
                                      // nRowsSelected: '{0} row(s) selected'
                                    },
                                    header: {
                                      actions: ""
                                    }
                                  }}
                                  options={{
                                    search: false,
                                    // selection: true,
                                    tableLayout: "auto",
                                    overflowY: false,
                                    showTitle: false,
                                    toolbarButtonAlignment: "left",
                                    paging: false,
                                    toolbar: false,
                                    headerStyle: {
                                      height: 30
                                    },
                                    rowStyle: {
                                      fontFamily: '"Yantramanav", sans-serif',
                                      fontSize: "14px"
                                    },
                                    tableLayout: "auto",
                                    headerStyle: {
                                      fontFamily: '"Yantramanav", sans-serif',
                                      fontWeight: "inherit",
                                      fontSize: "14px",

                                      textAlign: "left !Important"
                                    }
                                  }}
                                  editable={{
                                    onRowDelete: oldData =>
                                      new Promise(resolve => {
                                        handleAdvSearchRowDelete(oldData, resolve);
                                      })
                                  }}
                                  columns={advanceColumns}
                                  icons={tableIcons}
                                  data={advSearchDetails}
                                />
                                {/* </ThemeProvider> */}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </table>
                </div>
              </div>
            </ul>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <button
            className="btn btn-outline-ntv mx-2"
            onClick={viewSearchClose}
          >
            Save
          </button>
        </Modal.Footer> */}
        <Modal.Footer>
          <button
          //  className="btn btn-outline-ntv mx-2"
          //  onClick={viewSearchClose}
          className="btn btn-sm btn-primary ms-3"
          onClick={searchWorkitemSubmit} //Added by Trupti
          >
            Apply Search
          </button>
        </Modal.Footer>
      </Modal>
      
      {/** VIEW WORKITEM MODAL **/}
      <Modal
        show={viewWorkitemModal}
        dialogClassName="modal-100w"
        onHide={viewWorkitemClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Workitem Details</h6>
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  {workItemDetailModel.length > 0 ? (
                    <SearchWorkItemDetails
                      state={{ workItemDetailModel, isDynamic: false }}
                    />
                  ) : null}
                </div>
              </ul>
            </div>
            
            {workItemFieldsModel.length > 0 ? (
            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Workitem Fields</h6>
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form">
                <div className="tableWrap">
                    <SearchWorkItemDetails
                      state={{ workItemFieldsModel, isDynamic: true }}
                    />
                </div>
              </ul>
            </div>
             ) : null}
            {regGroup.length > 0 ? (    
            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Workitem Multivalue Fields</h6>
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  <div className="tableWrap-body">
                  {regGroup.map((data, index) => {

                  const isMultiValue = data.screenFields[0].isMultiValue;
                  let groupName = data.groupName;
                  return (
                      <table className="table table-borderless MatTable"
                      >
                      {isMultiValue && (
                          <MaterialTable
                          localization={{
                            pagination: {
                              labelRowsPerPage: "{50, 100, 250,500}",
                            },
                            toolbar: {
                              exportCSVName: "Export as Excel",
                              exportPDFName: "Export as pdf",
                            },
                          }}
                         // title = {groupName}

                          options={{
                            search: false,
                            overflowY: false,
                            showTitle: false,
                            filtering: true,
                            cellStyle: {
                            },
                            headerStyle: {
                              position: "sticky",
                            },
                             selection:false,
                          }}
                          components={{
                            Container: (props) => <Paper {...props} elevation={0} />,
                            Toolbar: (props) => (
                              <div>
                                <MTableToolbar {...props} />
                              </div>
                            ),
                          }}

                          icons={tableIcons}
                          columns={data.screenFields.map(field => ({
                            title: field.labelText,
                            field: field.mapToField,
                          }))}
                          data={workItemMultivalueDetailModel[groupName] || []}
                          />
                      )}
                      </table>
                   );
                  })}
                  </div>
                </div>
              </ul>
            </div>
            ) : null}
            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>WorkItem History</h6>
                    {/* //<h5>Processing Dashboard</h5> */}
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table MatTable">
                      <MaterialTable
                        title="WorkItems History"
                        options={{
                          search: false,
                          overflowY: false,
                          showTitle: false,
                          cellStyle: {
                            // width: 100,
                            // minWidth: 100,
                            whiteSpace: "Normal"
                          },
                          headerStyle: {
                            position: "sticky"
                            // top: "-5px",
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif'
                          }
                        }}
                        columns={listWorkItemHistoryColumns}
                        icons={tableIcons}
                        data={workitemHistory}
                        components={{
                          Container: props => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: props => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em"
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{ fontSize: "5px" }}
                              />
                            </div>
                          )
                        }}
                      />
                    </table>
                  </div>
                </div>
              </ul>
            </div>
            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Assignment Details</h6>
                    {/* //<h5>Processing Dashboard</h5> */}
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table MatTable">
                      <MaterialTable
                        title="Asignment Details"
                        options={{
                          search: false,
                          overflowY: false,
                          showTitle: false,
                          cellStyle: {
                            // width: 100,
                            // minWidth: 100,
                            whiteSpace: "Normal"
                          },
                          headerStyle: {
                            position: "sticky"
                            // top: "-5px",
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif'
                          }
                        }}
                        columns={asignmentdetailsColumns}
                        icons={tableIcons}
                        data={workitemDetails}
                        components={{
                          Container: props => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: props => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em"
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px"
                                }}
                              />
                            </div>
                          )
                        }}
                      />
                    </table>
                  </div>
                </div>
              </ul>
            </div>
            {/*Added By Durgansh */}
            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Attachment Details</h6>
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table MatTable">
                      <MaterialTable
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                          },
                          header: {
                            actions: ""
                          }
                        }}
                        actions={[
                          {
                            icon: () => <DeleteIcon />,
                            tooltip: "Delete",
                            onClick: (event, rowData) =>
                              handleRowDeleteAttachment(rowData),
                            position: "row"
                          }
                        ]}
                        options={{
                          search: false,
                          // selection: true,
                          tableLayout: "auto",
                          debounceInterval: 700,
                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "left",
                          paging: false,
                          toolbar: false,
                          headerStyle: {
                            height: 30
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontSize: "14px"
                          },
                          tableLayout: "auto",
                          headerStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontWeight: "inherit",
                            fontSize: "14px",

                            textAlign: "left !Important"
                          }
                        }}
                        columns={UploadAttachmentColumns}
                        icons={tableIcons}
                        data={AttachmentDetails}
                      />
                    </table>
                  </div>
                </div>
              </ul>
            </div>
            {/*Added By Durgansh */}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={workitemModal}
        id="workItemModal"
        dialogClassName="modal-100w"
        onHide={workitemClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Workitem Details -{" "}
            {nextWorkItemId != ""
              ? nextWorkItemId
              : processingDashboardParam.workItemID}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <div className="dashBoxs">
              <div className="dashBoxs-header maxHeight" >
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Asignment Details</h6>
                    {/* //<h5>Processing Dashboard</h5> */}
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  <div className="tableWrap-body MatTable material-table-core">
                    <MaterialTableCore
                      id="tblassignmentDetails"
                      title="Asignment Details"
                      options={{
                        search: false,
                        showTitle: false,
                        cellStyle: {
                          // width: 100,
                          // minWidth: 100,
                        },
                        headerStyle: {},
                        rowStyle: {
                          //  fontFamily: '"Yantramanav", sans-serif',
                        }
                      }}
                      columns={asignmentdetailsColumns}
                      icons={tableIcons}
                      data={assignments}
                    />
                  </div>
                </div>
              </ul>
            </div>
            <div className="dashBoxs">
              <div className="dashBoxs-header maxHeight" >
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Checklist Comment Details</h6>
                    {/* //<h5>Processing Dashboard</h5> */}
                  </div>
                </div>
              </div>
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  <div className="tableWrap-body MatTable">
                    <MaterialTable
                      title="checklist Comment"
                      id="tblChecklistComment"
                      options={{
                        search: false,
                        showTitle: false,
                        cellStyle: {
                          // width: 100,
                          // minWidth: 100,
                        },
                        headerStyle: {},
                        rowStyle: {
                          //  fontFamily: '"Yantramanav", sans-serif',
                        }
                      }}
                      columns={checklistdetailsColumns}
                      icons={tableIcons}
                      data={checkComments === null ? [] : checkComments}
                    />
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-ntv mx-2" id="btnWorkitemClose" onClick={workitemClose}>
            Cancel
          </button>
          {/* <button className="btn btn-primary">Start Timer</button> */}
        </Modal.Footer>
      </Modal>
      <Modal
        show={emailDetailsModal}
        dialogClassName="modal-100w"
        onHide={emailDetailsClose}
        id="emailDetailsModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Email Details -{" "}
            {nextWorkItemId != ""
              ? nextWorkItemId
              : processingDashboardParam.workItemID}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <div className="dashBoxs">
              <ul className="timeBreak-form">
                <div className="tableWrap">
                  <div className="tableWrap-body MatTable">
                    <MaterialTable
                      title="Emails"
                      id="tblEmails"
                      options={{
                        search: false,
                        showTitle: false,
                        cellStyle: {
                          // width: 100,
                          // minWidth: 100,
                          whiteSpace: "Normal",
                        },
                        headerStyle: {},
                        rowStyle: {
                          //  fontFamily: '"Yantramanav", sans-serif',
                        },
                      }}
                      columns={listEmailDetailsColumns}
                      icons={tableIcons}
                      data={emailConversations.value}
                    />
                  </div>
                </div>
              </ul>
            </div>
          </div>
          <EmailListBox emails={emailConversations?.value} />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-ntv mx-2"
            id="btnEmailDetailsClose"
            onClick={emailDetailsClose}
          >
            Cancel
          </button>
          {/* <button className="btn btn-primary">Start Timer</button> */}
        </Modal.Footer>
      </Modal>
      <Modal
        show={AttchmentModal}
        dialogClassName="modal-100w"
        id="attachmentModal"
        onHide={AttachmentClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Attachment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <ul className="timeBreak-form">
              <div className="tableWrap">
                <div className="tableWrap-body">
                  <table className="table brandTable">
                    <div>
                      <div className="fieldSet pt-1">
                        <div className="pt-2 pb-2">
                          <div className="mb-5">
                            <div>
                              <table className="table MatTable" id="tblAttachment">
                                <MaterialTable
                                  localization={{
                                    pagination: {
                                      //labelDisplayedRows: '{from}-{to} of {count}'
                                    },
                                    toolbar: {
                                      // nRowsSelected: '{0} row(s) selected'
                                    },
                                    header: {
                                      actions: ""
                                    }
                                  }}
                                  actions={[
                                    {
                                      icon: () => <DeleteIcon />,
                                      id: "deleteIcon",
                                      tooltip: "Delete",
                                      onClick: (event, rowData) =>
                                        handleRowDelete(rowData),
                                      position: "row"
                                    }
                                  ]}
                                  options={{
                                    search: false,
                                    // selection: true,
                                    tableLayout: "auto",
                                    debounceInterval: 700,
                                    overflowY: false,
                                    showTitle: false,
                                    toolbarButtonAlignment: "left",
                                    paging: false,
                                    toolbar: false,
                                    headerStyle: {
                                      height: 30
                                    },
                                    rowStyle: {
                                      fontFamily: '"Yantramanav", sans-serif',
                                      fontSize: "14px"
                                    },
                                    tableLayout: "auto",
                                    headerStyle: {
                                      fontFamily: '"Yantramanav", sans-serif',
                                      fontWeight: "inherit",
                                      fontSize: "14px",

                                      textAlign: "left !Important"
                                    }
                                  }}
                                  columns={columnsdtls}
                                  icons={tableIcons}
                                  data={AttachmentDetails}
                                />
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </table>
                </div>
              </div>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Process;
